import { AudioPlayer } from './AudioPlayer'

export class AudioManager {

    constructor() {

        this.completedAudio = new AudioPlayer()
        this.stepAudio = new AudioPlayer()

    }

    /**
     * Set the completed web-form audio so the browser can preload it.
     * @param {?string} url
     */
    setCompletedWebFormAudio(url) {

        if (url === undefined || url === null) {
            return
        }

        this.completedAudio.loadAudio(url)

    }

    /**
     * Play the web-form step's audio
     * @param {?string} url
     */
    autoplayStepAudio(url) {
        this.stepAudio.autoplay(url)
    }

    /**
     * Stop playing any step audio if applicable, and play the completed web-form audio.
     *
     * Note: This is called from the livewire layer.
     */
    playCompletedWebFormAudio() {

        this.stepAudio.stop()
        this.completedAudio.play()

    }

}
