export class StepHistoryStore {

    constructor() {

        /**
         * @type {*[{string}]}
         */
        this.stepProgress = []

    }

    /**
     * @param {string} offset
     * @returns {void}
     */
    storeAnsweredStepOffset(offset) {
        this.stepProgress.push(offset)
    }

    /**
     * @param {string} offset
     * @returns {boolean}
     */
    hasAnsweredStep(offset) {
        return this.stepProgress.includes(offset)
    }

}
