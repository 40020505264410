export default function(Alpine) {

    Alpine.directive('qv-mask', (element, { value, expression }, { effect, evaluateLater, cleanup }) => {

        let templateFunction = () => expression
        let lastInputValue = ''

        queueMicrotask(() => {

            if ([ 'function', 'dynamic' ].includes(value)) {

                // This is an x-qv-mask:function directive.

                const evaluator = evaluateLater(expression)

                effect(() => {

                    templateFunction = input => {

                        let result

                        /*
                         * We need to prevent "auto-evaluation" of functions like
                         * x-on expressions do so that we can use them as mask functions.
                         */
                        Alpine.dontAutoEvaluateFunctions(() => {

                            evaluator(value => {
                                result = typeof value === 'function' ? value(input) : value
                            }, { scope: {
                                // These are "magics" we'll make available to the x-qv-mask:function:
                                $input: input,
                                $money: formatMoney.bind({ el: element }),
                            } })

                        })

                        return result

                    }

                    /*
                     * Run on initialize which serves a dual purpose:
                     * - Initializing the mask on the input if it has an initial value.
                     * - Running the template function to set up reactivity, so that
                     *   when a dependency inside it changes, the input re-masks.
                     */
                    processInputValue(element, false)

                })

            } else {
                processInputValue(element, false)
            }

            // Override x-model's initial value...
            if (element._x_model) { element._x_model.set(element.value) }

        })

        const controller = new AbortController()

        cleanup(() => {
            controller.abort()
        })

        element.addEventListener('input', () => processInputValue(element), {
            signal: controller.signal,

            /*
             * Setting this as a capture phase listener to ensure it runs
             * before wire:model or x-model added as a latent binding...
             */
            capture: true,
        })

        /*
         * Don't "restoreCursorPosition" on "blur", because Safari
         * will re-focus the input and cause a focus trap.
         */
        element.addEventListener('blur', () => processInputValue(element, false), { signal: controller.signal })

        function processInputValue(element, shouldRestoreCursor = true) {

            const input = element.value
            const template = templateFunction(input)

            // If a template value is `falsy`, then don't process the input value
            if (!template || template === 'false') { return false }

            // If they hit backspace, don't process input.
            if (lastInputValue.length - element.value.length === 1) {
                return lastInputValue = element.value
            }

            const setInput = () => {
                lastInputValue = element.value = formatInput(template, input)
            }

            if (shouldRestoreCursor) {

                /*
                 * When an input element's value is set, it moves the cursor to the end
                 * therefore we need to track, estimate, and restore the cursor after
                 * a change was made.
                 */
                restoreCursorPosition(element, template, () => {
                    setInput()
                })

            } else {
                setInput()
            }

        }

    }).before('model')

}

export function restoreCursorPosition(element, template, callback) {

    const cursorPosition = element.selectionStart
    const unformattedValue = element.value

    callback()

    const beforeLeftOfCursorBeforeFormatting = unformattedValue.slice(0, cursorPosition)

    const newPosition = formatInput(
        template, beforeLeftOfCursorBeforeFormatting,
    ).length

    element.setSelectionRange(newPosition, newPosition)

}

const regexes = {
    9: /[0-9]/,
    a: /[a-zA-Z]/,
    '*': /[a-zA-Z0-9]/,
}

export function formatInput(template, input) {

    let output = ''
    let imark = 0
    let tmark = 0

    while (tmark < template.length && imark < input.length) {

        const char = template[ tmark ]
        const ichar = input[ imark ]

        if (char in regexes) {

            if (regexes[ char ].test(ichar)) {

                output += ichar
                tmark++

            }

            imark++

        } else {

            output += char
            tmark++

            if (char === input[ imark ]) { imark++ }

        }

    }

    return output

}

export function formatMoney(input, delimiter = '.', thousands, precision = 2) {

    if (input === '-') { return '-' }

    if (/^\D+$/.test(input)) { return '9' }

    if (thousands === null || thousands === undefined) {
        thousands = delimiter === ',' ? '.' : ','
    }

    const addThousands = (input, thousands) => {

        let output = ''
        let counter = 0

        for (let index = input.length - 1; index >= 0; index--) {

            if (input[ index ] === thousands) { continue }

            if (counter === 3) {

                output = input[ index ] + thousands + output
                counter = 0

            } else {
                output = input[ index ] + output
            }

            counter++

        }

        return output

    }

    const minus = input.startsWith('-') ? '-' : ''
    const strippedInput = input.replaceAll(new RegExp(`[^0-9\\${ delimiter }]`, 'g'), '')

    let template = Array.from({ length: strippedInput.split(delimiter)[ 0 ].length }).fill('9').join('')

    template = `${ minus }${ addThousands(template, thousands) }`

    if (precision > 0 && input.includes(delimiter)) { template += `${ delimiter }${ '9'.repeat(precision) }` }

    queueMicrotask(() => {

        if (this.el.value.endsWith(delimiter)) { return }

        if (this.el.value[ this.el.selectionStart - 1 ] === delimiter) {
            this.el.setSelectionRange(this.el.selectionStart - 1, this.el.selectionStart - 1)
        }

    })

    return template

}
