import { HookToFunctionMap } from './DomainHooks'

export class HookRunner {

    hooks = window.__HOOKS ?? []

    run(action, ...parameters) {

        // Find the related local hook function for the action and run it before the CRM hook is run
        const hookFunction = HookToFunctionMap[ action ]

        if (hookFunction) {

            try {
                hookFunction(...parameters)
            } catch (error) {
                console.error(error)
            }

        }

        for (const { callback } of this.hooks.filter(({ hook }) => hook === action)) {

            try {
                callback(...parameters)
            } catch (error) {
                console.error(error)
            }

        }

    }

}
