import { Alpine, Livewire } from './../dist/livewire/livewire.esm'
import { Helpers } from './Helpers.js'

const helpers = new Helpers()

export class Navigation {

    init($router, formPath, thankYouPath, hasInlineWebForm, hasGroups) {

        this.$router = $router
        this.formPath = formPath || ''
        this.thankYouPath = thankYouPath || ''
        this.hasInlineWebForm = hasInlineWebForm ?? false
        this.hasGroups = hasGroups ?? true
        Alpine.watch(() => this.$router.path, (newPath, oldPath) => Alpine.nextTick(() => handleRoute(newPath, oldPath, this.$router.params.step, this.formPath)))
        window.addEventListener('popstate', () => handleStep(this.$router.params.step))

        /**
         * Smooth scrolling can get cancelled if the content length has a large change.
         * This is a workaround to ensure the page is scrolled to the top if the
         * scroll was silently cancelled.
         *
         * This is duplicated from how our Vue implementation works.
         */
        this.$router.afterEach(() => {

            if (!this.hasInlineWebForm) {
                this.scrollToTop()
            }

        })

    }

    goTo(path, replace) {

        const method = replace ? 'replace' : 'push'

        this.$router[ method ](path)

        if (!this.hasInlineWebForm) {
            this.scrollToTop()
        }

    }

    scrollToTop() {
        window.scrollTo({ top: -1, left: 0, behavior: 'smooth' })
    }

    startWebForm() {

        const components = Livewire.getByName('pages.landing-web-form-chat')

        if (helpers.isFilled(components)) {

            components[ 0 ].step = 1
            components[ 0 ].$parent.step = 1
            components[ 0 ].set('step', 1)

            if (this.hasGroups) {
                this.goTo(this.formPath, true)
            } else {
                this.goTo(this.thankYouPath)
            }

            return true

        }

        return false

    }

}

function handleRoute(newPath, oldPath, step, formPath) {

    /**
     * If the user reaches the loading or thank-you page and tries to go back
     * we'll redirect them to the start of the web-form.
     */
    if (oldPath.endsWith('/thank-you') || (oldPath.endsWith('/loading') && !newPath.endsWith('/thank-you'))) {

        window.location = formPath

        return

    }

    /**
     * If the user reaches the loading or thank-you page, we update call the main component
     * to handle the active page change.
     */
    if (newPath.endsWith('/loading') || newPath.endsWith('/thank-you')) {

        const main = Livewire.getByName('main')[ 0 ]

        main?.handleRoute(newPath)

        return

    }

    /**
     * This logic is needed for the inline webform with redirect to webform.
     * When reaching step 2, we'll update only the frontend step state (no network request).
     */
    if (parseInt(step) === 2) {

        const LandingWebFormChat = Livewire.getByName('pages.landing-web-form-chat')

        /**
         * When comming back from the landing page, on slow connections, the visitor could reach the step 2 before the location is changed,
         * and this component would be missing from the DOM.
         */
        if (helpers.isFilled(LandingWebFormChat)) {
            LandingWebFormChat[ 0 ].step = 2
        }

    }

}

function handleStep(step) {

    const webForms = Livewire.getByName('pages.web-form')

    for (const webForm of webForms) {
        webForm?.set('step', step)
    }

}
