export class AudioPlayer {

    /**
     * Create an audio manager.
     */
    constructor() {

        this.audio = null
        this.hasPlayed = false

    }

    /**
     * Load an audio file.
     * @param {string} audioUrl - The URL of the audio file.
     */
    autoplay(audioUrl) {

        if (this.audio !== null) {
            this.stop()
        }

        if (audioUrl === undefined || audioUrl === null) {

            this.audio = null

            return

        }

        this.loadAudio(audioUrl)
        this.registerListeners()
        this.play()

    }

    /**
     * Load an audio file.
     * @param {string} audioUrl - The URL of the audio file.
     */
    loadAudio(audioUrl) {

        /** @type {HTMLAudioElement} */
        this.audio = new Audio(audioUrl)
        this.hasPlayed = false
        this.audio.load()

    }

    /**
     * Play the audio.
     */
    play() {

        if (this.audio === null) {
            return
        }

        if (this.hasPlayed) {
            return
        }

        if (this.isPlaying()) {
            return
        }

        const promise = this.audio.play()

        promise
            .then(
                () => {

                    this.destroyListeners()
                    this.hasPlayed = true

                },
            ).catch(() => {
                console.log('Audio playback was prevented by the browser')
            })

    }

    /**
     * Pause the audio.
     */
    pause() {
        this.audio.pause()
    }

    /**
     * Stop the audio.
     */
    stop() {

        if (this.audio === null) {
            return
        }

        this.audio.pause()
        this.audio.currentTime = 0

    }

    /**
     * Check if the audio is currently playing.
     * @returns {boolean} - True if the audio is playing, false otherwise.
     */
    isPlaying() {
        return this.audio !== null && !this.audio.paused
    }

    registerListeners() {

        document.addEventListener('click', () => this.play())
        document.addEventListener('keydown', () => this.play())
        document.addEventListener('touchmove', () => this.play())

    }

    destroyListeners() {

        document.removeEventListener('click', () => this.play())
        document.removeEventListener('keydown', () => this.play())
        document.removeEventListener('touchmove', () => this.play())

    }

}
