const __create = Object.create
const __defProp = Object.defineProperty
const __getOwnPropertyDesc = Object.getOwnPropertyDescriptor
const __getOwnPropertyNames = Object.getOwnPropertyNames
const __getProtoOf = Object.getPrototypeOf
const __hasOwnProperty = Object.prototype.hasOwnProperty

const __commonJS = (callback, module_) => function __require() {
    return module_ || (0, callback[ __getOwnPropertyNames(callback)[ 0 ] ])((module_ = { exports: {} }).exports, module_), module_.exports
}

const __copyProperties = (to, from, except, desc) => {

    if (from && typeof from === 'object' || typeof from === 'function') {
        for (const key of __getOwnPropertyNames(from)) { if (!__hasOwnProperty.call(to, key) && key !== except) { __defProp(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc(from, key)) || desc.enumerable }) } }
    }

    return to

}

const __toESM = (module_, isNodeMode, target) => (target = module_ != null ? __create(__getProtoOf(module_)) : {}, __copyProperties(isNodeMode || !module_ || !module_.__esModule ? __defProp(target, 'default', { value: module_, enumerable: true }) : target, module_))

// Node_modules/alpinejs/dist/module.cjs.js
const require_module_cjs = __commonJS({
    'node_modules/alpinejs/dist/module.cjs.js'(exports, module) {

        const __create2 = Object.create
        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __getProtoOf2 = Object.getPrototypeOf
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __commonJS2 = (callback, module_) => function __require() {
            return module_ || (0, callback[ __getOwnPropertyNames2(callback)[ 0 ] ])((module_ = { exports: {} }).exports, module_), module_.exports
        }

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toESM2 = (module_, isNodeMode, target) => (target = module_ != null ? __create2(__getProtoOf2(module_)) : {}, __copyProperties2(isNodeMode || !module_ || !module_.__esModule ? __defProp2(target, 'default', { value: module_, enumerable: true }) : target, module_))
        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)

        const require_shared_cjs = __commonJS2({
            'node_modules/@vue/shared/dist/shared.cjs.js'(exports2) {

                'use strict'

                Object.defineProperty(exports2, '__esModule', { value: true })

                function makeMap(string_, expectsLowerCase) {

                    const map = /* @__PURE__ */ Object.create(null)
                    const list = string_.split(',')

                    for (let index = 0; index < list.length; index++) {
                        map[ list[ index ] ] = true
                    }

                    return expectsLowerCase ? value => !!map[ value.toLowerCase() ] : value => !!map[ value ]

                }

                const PatchFlagNames = {
                    [ 1 ]: 'TEXT',
                    [ 2 ]: 'CLASS',
                    [ 4 ]: 'STYLE',
                    [ 8 ]: 'PROPS',
                    [ 16 ]: 'FULL_PROPS',
                    [ 32 ]: 'HYDRATE_EVENTS',
                    [ 64 ]: 'STABLE_FRAGMENT',
                    [ 128 ]: 'KEYED_FRAGMENT',
                    [ 256 ]: 'UNKEYED_FRAGMENT',
                    [ 512 ]: 'NEED_PATCH',
                    [ 1024 ]: 'DYNAMIC_SLOTS',
                    [ 2048 ]: 'DEV_ROOT_FRAGMENT',
                    [ -1 ]: 'HOISTED',
                    [ -2 ]: 'BAIL',
                }

                const slotFlagsText = {
                    [ 1 ]: 'STABLE',
                    [ 2 ]: 'DYNAMIC',
                    [ 3 ]: 'FORWARDED',
                }

                const GLOBALS_WHITE_LISTED = 'Infinity,undefined,NaN,isFinite,isNaN,parseFloat,parseInt,decodeURI,decodeURIComponent,encodeURI,encodeURIComponent,Math,Number,Date,Array,Object,Boolean,String,RegExp,Map,Set,JSON,Intl,BigInt'
                const isGloballyWhitelisted = /* @__PURE__ */ makeMap(GLOBALS_WHITE_LISTED)
                const range = 2

                function generateCodeFrame(source, start22 = 0, end = source.length) {

                    let lines = source.split(/(\r?\n)/)

                    const newlineSequences = lines.filter((_, index) => index % 2 === 1)

                    lines = lines.filter((_, index) => index % 2 === 0)

                    let count = 0

                    const res = []

                    for (let index = 0; index < lines.length; index++) {

                        count += lines[ index ].length + (newlineSequences[ index ] && newlineSequences[ index ].length || 0)

                        if (count >= start22) {

                            for (let index_ = index - range; index_ <= index + range || end > count; index_++) {

                                if (index_ < 0 || index_ >= lines.length) { continue }

                                const line = index_ + 1

                                res.push(`${ line }${ ' '.repeat(Math.max(3 - String(line).length, 0)) }|  ${ lines[ index_ ] }`)

                                const lineLength = lines[ index_ ].length
                                const newLineSeqLength = newlineSequences[ index_ ] && newlineSequences[ index_ ].length || 0

                                if (index_ === index) {

                                    const pad = start22 - (count - (lineLength + newLineSeqLength))
                                    const length = Math.max(1, end > count ? lineLength - pad : end - start22)

                                    res.push(`   |  ${ ' '.repeat(pad) }${ '^'.repeat(length) }`)

                                } else if (index_ > index) {

                                    if (end > count) {

                                        const length = Math.max(Math.min(end - count, lineLength), 1)

                                        res.push(`   |  ${ '^'.repeat(length) }`)

                                    }

                                    count += lineLength + newLineSeqLength

                                }

                            }

                            break

                        }

                    }

                    return res.join('\n')

                }

                const specialBooleanAttributes = 'itemscope,allowfullscreen,formnovalidate,ismap,nomodule,novalidate,readonly'
                const isSpecialBooleanAttribute = /* @__PURE__ */ makeMap(specialBooleanAttributes)
                const isBooleanAttribute2 = /* @__PURE__ */ makeMap(`${ specialBooleanAttributes },async,autofocus,autoplay,controls,default,defer,disabled,hidden,loop,open,required,reversed,scoped,seamless,checked,muted,multiple,selected`)
                const unsafeAttributeCharRE = /[>/="'\u0009\u000a\u000c\u0020]/
                const attributeValidationCache = {}

                function isSSRSafeAttributeName(name) {

                    if (attributeValidationCache.hasOwnProperty(name)) {
                        return attributeValidationCache[ name ]
                    }

                    const isUnsafe = unsafeAttributeCharRE.test(name)

                    if (isUnsafe) {
                        console.error(`unsafe attribute name: ${ name }`)
                    }

                    return attributeValidationCache[ name ] = !isUnsafe

                }

                const propsToAttributeMap = {
                    acceptCharset: 'accept-charset',
                    className: 'class',
                    htmlFor: 'for',
                    httpEquiv: 'http-equiv',
                }

                const isNoUnitNumericStyleProperty = /* @__PURE__ */ makeMap('animation-iteration-count,border-image-outset,border-image-slice,border-image-width,box-flex,box-flex-group,box-ordinal-group,column-count,columns,flex,flex-grow,flex-positive,flex-shrink,flex-negative,flex-order,grid-row,grid-row-end,grid-row-span,grid-row-start,grid-column,grid-column-end,grid-column-span,grid-column-start,font-weight,line-clamp,line-height,opacity,order,orphans,tab-size,widows,z-index,zoom,fill-opacity,flood-opacity,stop-opacity,stroke-dasharray,stroke-dashoffset,stroke-miterlimit,stroke-opacity,stroke-width')
                const isKnownAttribute = /* @__PURE__ */ makeMap('accept,accept-charset,accesskey,action,align,allow,alt,async,autocapitalize,autocomplete,autofocus,autoplay,background,bgcolor,border,buffered,capture,challenge,charset,checked,cite,class,code,codebase,color,cols,colspan,content,contenteditable,contextmenu,controls,coords,crossorigin,csp,data,datetime,decoding,default,defer,dir,dirname,disabled,download,draggable,dropzone,enctype,enterkeyhint,for,form,formaction,formenctype,formmethod,formnovalidate,formtarget,headers,height,hidden,high,href,hreflang,http-equiv,icon,id,importance,integrity,ismap,itemprop,keytype,kind,label,lang,language,loading,list,loop,low,manifest,max,maxlength,minlength,media,min,multiple,muted,name,novalidate,open,optimum,pattern,ping,placeholder,poster,preload,radiogroup,readonly,referrerpolicy,rel,required,reversed,rows,rowspan,sandbox,scope,scoped,selected,shape,size,sizes,slot,span,spellcheck,src,srcdoc,srclang,srcset,start,step,style,summary,tabindex,target,title,translate,type,usemap,value,width,wrap')

                function normalizeStyle(value) {

                    if (isArray2(value)) {

                        const res = {}

                        for (let index = 0; index < value.length; index++) {

                            const item = value[ index ]
                            const normalized = normalizeStyle(isString(item) ? parseStringStyle(item) : item)

                            if (normalized) {

                                for (const key in normalized) {
                                    res[ key ] = normalized[ key ]
                                }

                            }

                        }

                        return res

                    }

                    if (isObject2(value)) {
                        return value
                    }

                }

                const listDelimiterRE = /;(?![^(]*\))/g
                const propertyDelimiterRE = /:(.+)/

                function parseStringStyle(cssText) {

                    const returnValue = {}

                    for (const item of cssText.split(listDelimiterRE)) {

                        if (item) {

                            const temporary = item.split(propertyDelimiterRE)

                            temporary.length > 1 && (returnValue[ temporary[ 0 ].trim() ] = temporary[ 1 ].trim())

                        }

                    }

                    return returnValue

                }

                function stringifyStyle(styles) {

                    let returnValue = ''

                    if (!styles) {
                        return returnValue
                    }

                    for (const key in styles) {

                        const value = styles[ key ]
                        const normalizedKey = key.startsWith('--') ? key : hyphenate(key)

                        if (isString(value) || typeof value === 'number' && isNoUnitNumericStyleProperty(normalizedKey)) {
                            returnValue += `${ normalizedKey }:${ value };`
                        }

                    }

                    return returnValue

                }

                function normalizeClass(value) {

                    let res = ''

                    if (isString(value)) {
                        res = value
                    } else if (isArray2(value)) {

                        for (let index = 0; index < value.length; index++) {

                            const normalized = normalizeClass(value[ index ])

                            if (normalized) {
                                res += `${ normalized } `
                            }

                        }

                    } else if (isObject2(value)) {

                        for (const name in value) {

                            if (value[ name ]) {
                                res += `${ name } `
                            }

                        }

                    }

                    return res.trim()

                }

                const HTML_TAGS = 'html,body,base,head,link,meta,style,title,address,article,aside,footer,header,h1,h2,h3,h4,h5,h6,hgroup,nav,section,div,dd,dl,dt,figcaption,figure,picture,hr,img,li,main,ol,p,pre,ul,a,b,abbr,bdi,bdo,br,cite,code,data,dfn,em,i,kbd,mark,q,rp,rt,rtc,ruby,s,samp,small,span,strong,sub,sup,time,u,var,wbr,area,audio,map,track,video,embed,object,param,source,canvas,script,noscript,del,ins,caption,col,colgroup,table,thead,tbody,td,th,tr,button,datalist,fieldset,form,input,label,legend,meter,optgroup,option,output,progress,select,textarea,details,dialog,menu,summary,template,blockquote,iframe,tfoot'
                const SVG_TAGS = 'svg,animate,animateMotion,animateTransform,circle,clipPath,color-profile,defs,desc,discard,ellipse,feBlend,feColorMatrix,feComponentTransfer,feComposite,feConvolveMatrix,feDiffuseLighting,feDisplacementMap,feDistanceLight,feDropShadow,feFlood,feFuncA,feFuncB,feFuncG,feFuncR,feGaussianBlur,feImage,feMerge,feMergeNode,feMorphology,feOffset,fePointLight,feSpecularLighting,feSpotLight,feTile,feTurbulence,filter,foreignObject,g,hatch,hatchpath,image,line,linearGradient,marker,mask,mesh,meshgradient,meshpatch,meshrow,metadata,mpath,path,pattern,polygon,polyline,radialGradient,rect,set,solidcolor,stop,switch,symbol,text,textPath,title,tspan,unknown,use,view'
                const VOID_TAGS = 'area,base,br,col,embed,hr,img,input,link,meta,param,source,track,wbr'
                const isHTMLTag = /* @__PURE__ */ makeMap(HTML_TAGS)
                const isSVGTag = /* @__PURE__ */ makeMap(SVG_TAGS)
                const isVoidTag = /* @__PURE__ */ makeMap(VOID_TAGS)
                const escapeRE = /["'&<>]/

                function escapeHtml(string) {

                    const string_ = `${ string }`
                    const match = escapeRE.exec(string_)

                    if (!match) {
                        return string_
                    }

                    let html = ''
                    let escaped
                    let index
                    let lastIndex = 0

                    for (index = match.index; index < string_.length; index++) {

                        switch (string_.charCodeAt(index)) {

                        case 34:
                            escaped = '&quot;'

                            break

                        case 38:
                            escaped = '&amp;'

                            break

                        case 39:
                            escaped = '&#39;'

                            break

                        case 60:
                            escaped = '&lt;'

                            break

                        case 62:
                            escaped = '&gt;'

                            break

                        default:
                            continue

                        }

                        if (lastIndex !== index) {
                            html += string_.substring(lastIndex, index)
                        }

                        lastIndex = index + 1
                        html += escaped

                    }

                    return lastIndex !== index ? html + string_.substring(lastIndex, index) : html

                }

                const commentStripRE = /^-?>|<!--|-->|--!>|<!-$/g

                function escapeHtmlComment(source) {
                    return source.replace(commentStripRE, '')
                }

                function looseCompareArrays(a, b) {

                    if (a.length !== b.length) { return false }

                    let equal = true

                    for (let index = 0; equal && index < a.length; index++) {
                        equal = looseEqual(a[ index ], b[ index ])
                    }

                    return equal

                }

                function looseEqual(a, b) {

                    if (a === b) { return true }

                    let aValidType = isDate(a)
                    let bValidType = isDate(b)

                    if (aValidType || bValidType) {
                        return aValidType && bValidType ? a.getTime() === b.getTime() : false
                    }

                    aValidType = isArray2(a)
                    bValidType = isArray2(b)

                    if (aValidType || bValidType) {
                        return aValidType && bValidType ? looseCompareArrays(a, b) : false
                    }

                    aValidType = isObject2(a)
                    bValidType = isObject2(b)

                    if (aValidType || bValidType) {

                        if (!aValidType || !bValidType) {
                            return false
                        }

                        const aKeysCount = Object.keys(a).length
                        const bKeysCount = Object.keys(b).length

                        if (aKeysCount !== bKeysCount) {
                            return false
                        }

                        for (const key in a) {

                            const aHasKey = a.hasOwnProperty(key)
                            const bHasKey = b.hasOwnProperty(key)

                            if (aHasKey && !bHasKey || !aHasKey && bHasKey || !looseEqual(a[ key ], b[ key ])) {
                                return false
                            }

                        }

                    }

                    return String(a) === String(b)

                }

                function looseIndexOf(array, value) {
                    return array.findIndex(item => looseEqual(item, value))
                }

                const toDisplayString = value => value == null ? '' : (isObject2(value) ? JSON.stringify(value, replacer, 2) : String(value))

                var replacer = (_key, value) => {

                    if (isMap(value)) {

                        return {
                            [ `Map(${ value.size })` ]: [ ...value.entries() ].reduce((entries, [ key, value2 ]) => {

                                entries[ `${ key } =>` ] = value2

                                return entries

                            }, {}),
                        }

                    }

                    if (isSet(value)) {

                        return {
                            [ `Set(${ value.size })` ]: [ ...value.values() ],
                        }

                    }

                    if (isObject2(value) && !isArray2(value) && !isPlainObject(value)) {
                        return String(value)
                    }

                    return value

                }

                const babelParserDefaultPlugins = [
                    'bigInt',
                    'optionalChaining',
                    'nullishCoalescingOperator',
                ]

                const EMPTY_OBJ = Object.freeze({})
                const EMPTY_ARR = Object.freeze([])

                const NOOP = () => {
                }

                const NO = () => false
                const onRE = /^on[^a-z]/
                const isOn = key => onRE.test(key)
                const isModelListener = key => key.startsWith('onUpdate:')
                const extend = Object.assign

                const remove = (array, element) => {

                    const index = array.indexOf(element)

                    if (index > -1) {
                        array.splice(index, 1)
                    }

                }

                const hasOwnProperty = Object.prototype.hasOwnProperty
                const hasOwn = (value, key) => hasOwnProperty.call(value, key)

                var isArray2 = Array.isArray

                var isMap = value => toTypeString(value) === '[object Map]'

                var isSet = value => toTypeString(value) === '[object Set]'

                var isDate = value => value instanceof Date

                const isFunction2 = value => typeof value === 'function'

                var isString = value => typeof value === 'string'

                const isSymbol = value => typeof value === 'symbol'

                var isObject2 = value => value !== null && typeof value === 'object'

                const isPromise = value => isObject2(value) && isFunction2(value.then) && isFunction2(value.catch)
                const objectToString = Object.prototype.toString

                var toTypeString = value => objectToString.call(value)

                const toRawType = value => toTypeString(value).slice(8, -1)

                var isPlainObject = value => toTypeString(value) === '[object Object]'

                const isIntegerKey = key => isString(key) && key !== 'NaN' && key[ 0 ] !== '-' && `${ parseInt(key, 10) }` === key
                const isReservedProperty = /* @__PURE__ */ makeMap(',key,ref,onVnodeBeforeMount,onVnodeMounted,onVnodeBeforeUpdate,onVnodeUpdated,onVnodeBeforeUnmount,onVnodeUnmounted')

                const cacheStringFunction = function_ => {

                    const cache = /* @__PURE__ */ Object.create(null)

                    return string_ => {

                        const hit = cache[ string_ ]

                        return hit || (cache[ string_ ] = function_(string_))

                    }

                }

                const camelizeRE = /-(\w)/g
                const camelize = cacheStringFunction(string_ => string_.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : ''))
                const hyphenateRE = /\B([A-Z])/g

                var hyphenate = cacheStringFunction(string_ => string_.replace(hyphenateRE, '-$1').toLowerCase())

                const capitalize = cacheStringFunction(string_ => string_.charAt(0).toUpperCase() + string_.slice(1))
                const toHandlerKey = cacheStringFunction(string_ => string_ ? `on${ capitalize(string_) }` : '')
                const hasChanged = (value, oldValue) => value !== oldValue && (value === value || oldValue === oldValue)

                const invokeArrayFns = (fns, argument) => {

                    for (let index = 0; index < fns.length; index++) {
                        fns[ index ](argument)
                    }

                }

                const def = (object, key, value) => {

                    Object.defineProperty(object, key, {
                        configurable: true,
                        enumerable: false,
                        value,
                    })

                }

                const toNumber = value => {

                    const n = parseFloat(value)

                    return isNaN(n) ? value : n

                }

                let _globalThis

                const getGlobalThis = () => _globalThis || (_globalThis = typeof globalThis !== 'undefined' ? globalThis : (typeof self !== 'undefined' ? self : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : {}))

                exports2.EMPTY_ARR = EMPTY_ARR
                exports2.EMPTY_OBJ = EMPTY_OBJ
                exports2.NO = NO
                exports2.NOOP = NOOP
                exports2.PatchFlagNames = PatchFlagNames
                exports2.babelParserDefaultPlugins = babelParserDefaultPlugins
                exports2.camelize = camelize
                exports2.capitalize = capitalize
                exports2.def = def
                exports2.escapeHtml = escapeHtml
                exports2.escapeHtmlComment = escapeHtmlComment
                exports2.extend = extend
                exports2.generateCodeFrame = generateCodeFrame
                exports2.getGlobalThis = getGlobalThis
                exports2.hasChanged = hasChanged
                exports2.hasOwn = hasOwn
                exports2.hyphenate = hyphenate
                exports2.invokeArrayFns = invokeArrayFns
                exports2.isArray = isArray2
                exports2.isBooleanAttr = isBooleanAttribute2
                exports2.isDate = isDate
                exports2.isFunction = isFunction2
                exports2.isGloballyWhitelisted = isGloballyWhitelisted
                exports2.isHTMLTag = isHTMLTag
                exports2.isIntegerKey = isIntegerKey
                exports2.isKnownAttr = isKnownAttribute
                exports2.isMap = isMap
                exports2.isModelListener = isModelListener
                exports2.isNoUnitNumericStyleProp = isNoUnitNumericStyleProperty
                exports2.isObject = isObject2
                exports2.isOn = isOn
                exports2.isPlainObject = isPlainObject
                exports2.isPromise = isPromise
                exports2.isReservedProp = isReservedProperty
                exports2.isSSRSafeAttrName = isSSRSafeAttributeName
                exports2.isSVGTag = isSVGTag
                exports2.isSet = isSet
                exports2.isSpecialBooleanAttr = isSpecialBooleanAttribute
                exports2.isString = isString
                exports2.isSymbol = isSymbol
                exports2.isVoidTag = isVoidTag
                exports2.looseEqual = looseEqual
                exports2.looseIndexOf = looseIndexOf
                exports2.makeMap = makeMap
                exports2.normalizeClass = normalizeClass
                exports2.normalizeStyle = normalizeStyle
                exports2.objectToString = objectToString
                exports2.parseStringStyle = parseStringStyle
                exports2.propsToAttrMap = propsToAttributeMap
                exports2.remove = remove
                exports2.slotFlagsText = slotFlagsText
                exports2.stringifyStyle = stringifyStyle
                exports2.toDisplayString = toDisplayString
                exports2.toHandlerKey = toHandlerKey
                exports2.toNumber = toNumber
                exports2.toRawType = toRawType
                exports2.toTypeString = toTypeString

            },
        })

        const require_shared = __commonJS2({
            'node_modules/@vue/shared/index.js'(exports2, module2) {

                'use strict'

                if (false) {
                    module2.exports = null
                } else {
                    module2.exports = require_shared_cjs()
                }

            },
        })

        const require_reactivity_cjs = __commonJS2({
            'node_modules/@vue/reactivity/dist/reactivity.cjs.js'(exports2) {

                'use strict'

                Object.defineProperty(exports2, '__esModule', { value: true })

                const shared = require_shared()
                const targetMap = /* @__PURE__ */ new WeakMap()
                const effectStack = []

                let activeEffect

                const ITERATE_KEY = Symbol('iterate')
                const MAP_KEY_ITERATE_KEY = Symbol('Map key iterate')

                function isEffect(function_) {
                    return function_ && function_._isEffect === true
                }

                function effect3(function_, options = shared.EMPTY_OBJ) {

                    if (isEffect(function_)) {
                        function_ = function_.raw
                    }

                    const effect4 = createReactiveEffect(function_, options)

                    if (!options.lazy) {
                        effect4()
                    }

                    return effect4

                }

                function stop2(effect4) {

                    if (effect4.active) {

                        cleanup(effect4)

                        if (effect4.options.onStop) {
                            effect4.options.onStop()
                        }

                        effect4.active = false

                    }

                }

                let uid = 0

                function createReactiveEffect(function_, options) {

                    const effect4 = function reactiveEffect() {

                        if (!effect4.active) {
                            return function_()
                        }

                        if (!effectStack.includes(effect4)) {

                            cleanup(effect4)

                            try {

                                enableTracking()
                                effectStack.push(effect4)
                                activeEffect = effect4

                                return function_()

                            } finally {

                                effectStack.pop()
                                resetTracking()
                                activeEffect = effectStack[ effectStack.length - 1 ]

                            }

                        }

                    }

                    effect4.id = uid++
                    effect4.allowRecurse = !!options.allowRecurse
                    effect4._isEffect = true
                    effect4.active = true
                    effect4.raw = function_
                    effect4.deps = []
                    effect4.options = options

                    return effect4

                }

                function cleanup(effect4) {

                    const { deps } = effect4

                    if (deps.length) {

                        for (let index = 0; index < deps.length; index++) {
                            deps[ index ].delete(effect4)
                        }

                        deps.length = 0

                    }

                }

                let shouldTrack = true

                const trackStack = []

                function pauseTracking() {

                    trackStack.push(shouldTrack)
                    shouldTrack = false

                }

                function enableTracking() {

                    trackStack.push(shouldTrack)
                    shouldTrack = true

                }

                function resetTracking() {

                    const last = trackStack.pop()

                    shouldTrack = last === void 0 ? true : last

                }

                function track2(target, type, key) {

                    if (!shouldTrack || activeEffect === void 0) {
                        return
                    }

                    let depsMap = targetMap.get(target)

                    if (!depsMap) {
                        targetMap.set(target, depsMap = /* @__PURE__ */ new Map())
                    }

                    let dep = depsMap.get(key)

                    if (!dep) {
                        depsMap.set(key, dep = /* @__PURE__ */ new Set())
                    }

                    if (!dep.has(activeEffect)) {

                        dep.add(activeEffect)
                        activeEffect.deps.push(dep)

                        if (activeEffect.options.onTrack) {

                            activeEffect.options.onTrack({
                                effect: activeEffect,
                                target,
                                type,
                                key,
                            })

                        }

                    }

                }

                function trigger2(target, type, key, newValue, oldValue, oldTarget) {

                    const depsMap = targetMap.get(target)

                    if (!depsMap) {
                        return
                    }

                    const effects = /* @__PURE__ */ new Set()

                    const add2 = effectsToAdd => {

                        if (effectsToAdd) {

                            for (const effect4 of effectsToAdd) {

                                if (effect4 !== activeEffect || effect4.allowRecurse) {
                                    effects.add(effect4)
                                }

                            }

                        }

                    }

                    if (type === 'clear') {
                        depsMap.forEach(add2)
                    } else if (key === 'length' && shared.isArray(target)) {

                        for (const [ key2, dep ] of depsMap.entries()) {

                            if (key2 === 'length' || key2 >= newValue) {
                                add2(dep)
                            }

                        }

                    } else {

                        if (key !== void 0) {
                            add2(depsMap.get(key))
                        }

                        switch (type) {

                        case 'add':
                            if (!shared.isArray(target)) {

                                add2(depsMap.get(ITERATE_KEY))

                                if (shared.isMap(target)) {
                                    add2(depsMap.get(MAP_KEY_ITERATE_KEY))
                                }

                            } else if (shared.isIntegerKey(key)) {
                                add2(depsMap.get('length'))
                            }

                            break

                        case 'delete':
                            if (!shared.isArray(target)) {

                                add2(depsMap.get(ITERATE_KEY))

                                if (shared.isMap(target)) {
                                    add2(depsMap.get(MAP_KEY_ITERATE_KEY))
                                }

                            }

                            break

                        case 'set':
                            if (shared.isMap(target)) {
                                add2(depsMap.get(ITERATE_KEY))
                            }

                            break

                        }

                    }

                    const run = effect4 => {

                        if (effect4.options.onTrigger) {

                            effect4.options.onTrigger({
                                effect: effect4,
                                target,
                                key,
                                type,
                                newValue,
                                oldValue,
                                oldTarget,
                            })

                        }

                        if (effect4.options.scheduler) {
                            effect4.options.scheduler(effect4)
                        } else {
                            effect4()
                        }

                    }

                    effects.forEach(run)

                }

                const isNonTrackableKeys = /* @__PURE__ */ shared.makeMap('__proto__,__v_isRef,__isVue')
                const builtInSymbols = new Set(Object.getOwnPropertyNames(Symbol).map(key => Symbol[ key ]).filter(shared.isSymbol))
                const get2 = /* @__PURE__ */ createGetter()
                const shallowGet = /* @__PURE__ */ createGetter(false, true)
                const readonlyGet = /* @__PURE__ */ createGetter(true)
                const shallowReadonlyGet = /* @__PURE__ */ createGetter(true, true)
                const arrayInstrumentations = /* @__PURE__ */ createArrayInstrumentations()

                function createArrayInstrumentations() {

                    const instrumentations = {}

                    for (const key of [ 'includes', 'indexOf', 'lastIndexOf' ]) {

                        instrumentations[ key ] = function(...arguments_) {

                            const array = toRaw2(this)

                            for (let index = 0, l = this.length; index < l; index++) {
                                track2(array, 'get', `${ index }`)
                            }

                            const res = array[ key ](...arguments_)

                            if (res === -1 || res === false) {
                                return array[ key ](...arguments_.map(toRaw2))
                            }

                            return res

                        }

                    }

                    for (const key of [ 'push', 'pop', 'shift', 'unshift', 'splice' ]) {

                        instrumentations[ key ] = function(...arguments_) {

                            pauseTracking()

                            const res = toRaw2(this)[ key ].apply(this, arguments_)

                            resetTracking()

                            return res

                        }

                    }

                    return instrumentations

                }

                function createGetter(isReadonly2 = false, shallow = false) {

                    return function get3(target, key, receiver) {

                        if (key === '__v_isReactive') {
                            return !isReadonly2
                        }

                        if (key === '__v_isReadonly') {
                            return isReadonly2
                        }

                        if (key === '__v_raw' && receiver === (isReadonly2 ? (shallow ? shallowReadonlyMap : readonlyMap) : (shallow ? shallowReactiveMap : reactiveMap)).get(target)) {
                            return target
                        }

                        const targetIsArray = shared.isArray(target)

                        if (!isReadonly2 && targetIsArray && shared.hasOwn(arrayInstrumentations, key)) {
                            return Reflect.get(arrayInstrumentations, key, receiver)
                        }

                        const res = Reflect.get(target, key, receiver)

                        if (shared.isSymbol(key) ? builtInSymbols.has(key) : isNonTrackableKeys(key)) {
                            return res
                        }

                        if (!isReadonly2) {
                            track2(target, 'get', key)
                        }

                        if (shallow) {
                            return res
                        }

                        if (isReference(res)) {

                            const shouldUnwrap = !targetIsArray || !shared.isIntegerKey(key)

                            return shouldUnwrap ? res.value : res

                        }

                        if (shared.isObject(res)) {
                            return isReadonly2 ? readonly(res) : reactive3(res)
                        }

                        return res

                    }

                }

                const set2 = /* @__PURE__ */ createSetter()
                const shallowSet = /* @__PURE__ */ createSetter(true)

                function createSetter(shallow = false) {

                    return function set3(target, key, value, receiver) {

                        let oldValue = target[ key ]

                        if (!shallow) {

                            value = toRaw2(value)
                            oldValue = toRaw2(oldValue)

                            if (!shared.isArray(target) && isReference(oldValue) && !isReference(value)) {

                                oldValue.value = value

                                return true

                            }

                        }

                        const hadKey = shared.isArray(target) && shared.isIntegerKey(key) ? Number(key) < target.length : shared.hasOwn(target, key)
                        const result = Reflect.set(target, key, value, receiver)

                        if (target === toRaw2(receiver)) {

                            if (!hadKey) {
                                trigger2(target, 'add', key, value)
                            } else if (shared.hasChanged(value, oldValue)) {
                                trigger2(target, 'set', key, value, oldValue)
                            }

                        }

                        return result

                    }

                }

                function deleteProperty(target, key) {

                    const hadKey = shared.hasOwn(target, key)
                    const oldValue = target[ key ]
                    const result = Reflect.deleteProperty(target, key)

                    if (result && hadKey) {
                        trigger2(target, 'delete', key, void 0, oldValue)
                    }

                    return result

                }

                function has(target, key) {

                    const result = Reflect.has(target, key)

                    if (!shared.isSymbol(key) || !builtInSymbols.has(key)) {
                        track2(target, 'has', key)
                    }

                    return result

                }

                function ownKeys(target) {

                    track2(target, 'iterate', shared.isArray(target) ? 'length' : ITERATE_KEY)

                    return Reflect.ownKeys(target)

                }

                const mutableHandlers = {
                    get: get2,
                    set: set2,
                    deleteProperty,
                    has,
                    ownKeys,
                }

                const readonlyHandlers = {
                    get: readonlyGet,
                    set(target, key) {

                        {
                            console.warn(`Set operation on key "${ String(key) }" failed: target is readonly.`, target)
                        }

                        return true

                    },
                    deleteProperty(target, key) {

                        {
                            console.warn(`Delete operation on key "${ String(key) }" failed: target is readonly.`, target)
                        }

                        return true

                    },
                }

                const shallowReactiveHandlers = /* @__PURE__ */ shared.extend({}, mutableHandlers, {
                    get: shallowGet,
                    set: shallowSet,
                })

                const shallowReadonlyHandlers = /* @__PURE__ */ shared.extend({}, readonlyHandlers, {
                    get: shallowReadonlyGet,
                })

                const toReactive = value => shared.isObject(value) ? reactive3(value) : value
                const toReadonly = value => shared.isObject(value) ? readonly(value) : value
                const toShallow = value => value
                const getProto = v => Reflect.getPrototypeOf(v)

                function get$1(target, key, isReadonly2 = false, isShallow = false) {

                    target = target.__v_raw

                    const rawTarget = toRaw2(target)
                    const rawKey = toRaw2(key)

                    if (key !== rawKey) {
                        !isReadonly2 && track2(rawTarget, 'get', key)
                    }

                    !isReadonly2 && track2(rawTarget, 'get', rawKey)

                    const { has: has2 } = getProto(rawTarget)
                    const wrap = isShallow ? toShallow : (isReadonly2 ? toReadonly : toReactive)

                    if (has2.call(rawTarget, key)) {
                        return wrap(target.get(key))
                    }

                    if (has2.call(rawTarget, rawKey)) {
                        return wrap(target.get(rawKey))
                    }

                    if (target !== rawTarget) {
                        target.get(key)
                    }

                }

                function has$1(key, isReadonly2 = false) {

                    const target = this.__v_raw
                    const rawTarget = toRaw2(target)
                    const rawKey = toRaw2(key)

                    if (key !== rawKey) {
                        !isReadonly2 && track2(rawTarget, 'has', key)
                    }

                    !isReadonly2 && track2(rawTarget, 'has', rawKey)

                    return key === rawKey ? target.has(key) : target.has(key) || target.has(rawKey)

                }

                function size(target, isReadonly2 = false) {

                    target = target.__v_raw
                    !isReadonly2 && track2(toRaw2(target), 'iterate', ITERATE_KEY)

                    return Reflect.get(target, 'size', target)

                }

                function add(value) {

                    value = toRaw2(value)

                    const target = toRaw2(this)
                    const proto = getProto(target)
                    const hadKey = proto.has.call(target, value)

                    if (!hadKey) {

                        target.add(value)
                        trigger2(target, 'add', value, value)

                    }

                    return this

                }

                function set$1(key, value) {

                    value = toRaw2(value)

                    const target = toRaw2(this)
                    const { has: has2, get: get3 } = getProto(target)

                    let hadKey = has2.call(target, key)

                    if (!hadKey) {

                        key = toRaw2(key)
                        hadKey = has2.call(target, key)

                    } else {
                        checkIdentityKeys(target, has2, key)
                    }

                    const oldValue = get3.call(target, key)

                    target.set(key, value)

                    if (!hadKey) {
                        trigger2(target, 'add', key, value)
                    } else if (shared.hasChanged(value, oldValue)) {
                        trigger2(target, 'set', key, value, oldValue)
                    }

                    return this

                }

                function deleteEntry(key) {

                    const target = toRaw2(this)
                    const { has: has2, get: get3 } = getProto(target)

                    let hadKey = has2.call(target, key)

                    if (!hadKey) {

                        key = toRaw2(key)
                        hadKey = has2.call(target, key)

                    } else {
                        checkIdentityKeys(target, has2, key)
                    }

                    const oldValue = get3 ? get3.call(target, key) : void 0
                    const result = target.delete(key)

                    if (hadKey) {
                        trigger2(target, 'delete', key, void 0, oldValue)
                    }

                    return result

                }

                function clear() {

                    const target = toRaw2(this)
                    const hadItems = target.size !== 0
                    const oldTarget = shared.isMap(target) ? new Map(target) : new Set(target)
                    const result = target.clear()

                    if (hadItems) {
                        trigger2(target, 'clear', void 0, void 0, oldTarget)
                    }

                    return result

                }

                function createForEach(isReadonly2, isShallow) {

                    return function forEach(callback, thisArgument) {

                        const observed = this
                        const target = observed.__v_raw
                        const rawTarget = toRaw2(target)
                        const wrap = isShallow ? toShallow : (isReadonly2 ? toReadonly : toReactive)

                        !isReadonly2 && track2(rawTarget, 'iterate', ITERATE_KEY)

                        return target.forEach((value, key) => callback.call(thisArgument, wrap(value), wrap(key), observed))

                    }

                }

                function createIterableMethod(method, isReadonly2, isShallow) {

                    return function(...arguments_) {

                        const target = this.__v_raw
                        const rawTarget = toRaw2(target)
                        const targetIsMap = shared.isMap(rawTarget)
                        const isPair = method === 'entries' || method === Symbol.iterator && targetIsMap
                        const isKeyOnly = method === 'keys' && targetIsMap
                        const innerIterator = target[ method ](...arguments_)
                        const wrap = isShallow ? toShallow : (isReadonly2 ? toReadonly : toReactive)

                        !isReadonly2 && track2(rawTarget, 'iterate', isKeyOnly ? MAP_KEY_ITERATE_KEY : ITERATE_KEY)

                        return {
                            next() {

                                const { value, done } = innerIterator.next()

                                return done
                                    ? { value, done }
                                    : {
                                        value: isPair ? [ wrap(value[ 0 ]), wrap(value[ 1 ]) ] : wrap(value),
                                        done,
                                    }

                            },
                            [ Symbol.iterator ]() {
                                return this
                            },
                        }

                    }

                }

                function createReadonlyMethod(type) {

                    return function(...arguments_) {

                        {

                            const key = arguments_[ 0 ] ? `on key "${ arguments_[ 0 ] }" ` : ''

                            console.warn(`${ shared.capitalize(type) } operation ${ key }failed: target is readonly.`, toRaw2(this))

                        }

                        return type === 'delete' ? false : this

                    }

                }

                function createInstrumentations() {

                    const mutableInstrumentations2 = {
                        get(key) {
                            return get$1(this, key)
                        },
                        get size() {
                            return size(this)
                        },
                        has: has$1,
                        add,
                        set: set$1,
                        delete: deleteEntry,
                        clear,
                        forEach: createForEach(false, false),
                    }

                    const shallowInstrumentations2 = {
                        get(key) {
                            return get$1(this, key, false, true)
                        },
                        get size() {
                            return size(this)
                        },
                        has: has$1,
                        add,
                        set: set$1,
                        delete: deleteEntry,
                        clear,
                        forEach: createForEach(false, true),
                    }

                    const readonlyInstrumentations2 = {
                        get(key) {
                            return get$1(this, key, true)
                        },
                        get size() {
                            return size(this, true)
                        },
                        has(key) {
                            return has$1.call(this, key, true)
                        },
                        add: createReadonlyMethod('add'),
                        set: createReadonlyMethod('set'),
                        delete: createReadonlyMethod('delete'),
                        clear: createReadonlyMethod('clear'),
                        forEach: createForEach(true, false),
                    }

                    const shallowReadonlyInstrumentations2 = {
                        get(key) {
                            return get$1(this, key, true, true)
                        },
                        get size() {
                            return size(this, true)
                        },
                        has(key) {
                            return has$1.call(this, key, true)
                        },
                        add: createReadonlyMethod('add'),
                        set: createReadonlyMethod('set'),
                        delete: createReadonlyMethod('delete'),
                        clear: createReadonlyMethod('clear'),
                        forEach: createForEach(true, true),
                    }

                    const iteratorMethods = [ 'keys', 'values', 'entries', Symbol.iterator ]

                    for (const method of iteratorMethods) {

                        mutableInstrumentations2[ method ] = createIterableMethod(method, false, false)
                        readonlyInstrumentations2[ method ] = createIterableMethod(method, true, false)
                        shallowInstrumentations2[ method ] = createIterableMethod(method, false, true)
                        shallowReadonlyInstrumentations2[ method ] = createIterableMethod(method, true, true)

                    }

                    return [
                        mutableInstrumentations2,
                        readonlyInstrumentations2,
                        shallowInstrumentations2,
                        shallowReadonlyInstrumentations2,
                    ]

                }

                const [ mutableInstrumentations, readonlyInstrumentations, shallowInstrumentations, shallowReadonlyInstrumentations ] = /* @__PURE__ */ createInstrumentations()

                function createInstrumentationGetter(isReadonly2, shallow) {

                    const instrumentations = shallow ? (isReadonly2 ? shallowReadonlyInstrumentations : shallowInstrumentations) : (isReadonly2 ? readonlyInstrumentations : mutableInstrumentations)

                    return (target, key, receiver) => {

                        if (key === '__v_isReactive') {
                            return !isReadonly2
                        }

                        if (key === '__v_isReadonly') {
                            return isReadonly2
                        }

                        if (key === '__v_raw') {
                            return target
                        }

                        return Reflect.get(shared.hasOwn(instrumentations, key) && key in target ? instrumentations : target, key, receiver)

                    }

                }

                const mutableCollectionHandlers = {
                    get: /* @__PURE__ */ createInstrumentationGetter(false, false),
                }

                const shallowCollectionHandlers = {
                    get: /* @__PURE__ */ createInstrumentationGetter(false, true),
                }

                const readonlyCollectionHandlers = {
                    get: /* @__PURE__ */ createInstrumentationGetter(true, false),
                }

                const shallowReadonlyCollectionHandlers = {
                    get: /* @__PURE__ */ createInstrumentationGetter(true, true),
                }

                function checkIdentityKeys(target, has2, key) {

                    const rawKey = toRaw2(key)

                    if (rawKey !== key && has2.call(target, rawKey)) {

                        const type = shared.toRawType(target)

                        console.warn(`Reactive ${ type } contains both the raw and reactive versions of the same object${ type === 'Map' ? ' as keys' : '' }, which can lead to inconsistencies. Avoid differentiating between the raw and reactive versions of an object and only use the reactive version if possible.`)

                    }

                }

                var reactiveMap = /* @__PURE__ */ new WeakMap()

                var shallowReactiveMap = /* @__PURE__ */ new WeakMap()

                var readonlyMap = /* @__PURE__ */ new WeakMap()

                var shallowReadonlyMap = /* @__PURE__ */ new WeakMap()

                function targetTypeMap(rawType) {

                    switch (rawType) {

                    case 'Object':

                    case 'Array':
                        return 1

                    case 'Map':

                    case 'Set':

                    case 'WeakMap':

                    case 'WeakSet':
                        return 2

                    default:
                        return 0

                    }

                }

                function getTargetType(value) {
                    return value.__v_skip || !Object.isExtensible(value) ? 0 : targetTypeMap(shared.toRawType(value))
                }

                function reactive3(target) {

                    if (target && target.__v_isReadonly) {
                        return target
                    }

                    return createReactiveObject(target, false, mutableHandlers, mutableCollectionHandlers, reactiveMap)

                }

                function shallowReactive(target) {
                    return createReactiveObject(target, false, shallowReactiveHandlers, shallowCollectionHandlers, shallowReactiveMap)
                }

                function readonly(target) {
                    return createReactiveObject(target, true, readonlyHandlers, readonlyCollectionHandlers, readonlyMap)
                }

                function shallowReadonly(target) {
                    return createReactiveObject(target, true, shallowReadonlyHandlers, shallowReadonlyCollectionHandlers, shallowReadonlyMap)
                }

                function createReactiveObject(target, isReadonly2, baseHandlers, collectionHandlers, proxyMap) {

                    if (!shared.isObject(target)) {

                        {
                            console.warn(`value cannot be made reactive: ${ String(target) }`)
                        }

                        return target

                    }

                    if (target.__v_raw && !(isReadonly2 && target.__v_isReactive)) {
                        return target
                    }

                    const existingProxy = proxyMap.get(target)

                    if (existingProxy) {
                        return existingProxy
                    }

                    const targetType = getTargetType(target)

                    if (targetType === 0) {
                        return target
                    }

                    const proxy = new Proxy(target, targetType === 2 ? collectionHandlers : baseHandlers)

                    proxyMap.set(target, proxy)

                    return proxy

                }

                function isReactive2(value) {

                    if (isReadonly(value)) {
                        return isReactive2(value.__v_raw)
                    }

                    return !!(value && value.__v_isReactive)

                }

                function isReadonly(value) {
                    return !!(value && value.__v_isReadonly)
                }

                function isProxy(value) {
                    return isReactive2(value) || isReadonly(value)
                }

                function toRaw2(observed) {
                    return observed && toRaw2(observed.__v_raw) || observed
                }

                function markRaw(value) {

                    shared.def(value, '__v_skip', true)

                    return value

                }

                const convert = value => shared.isObject(value) ? reactive3(value) : value

                function isReference(r) {
                    return Boolean(r && r.__v_isRef === true)
                }

                function reference(value) {
                    return createReference(value)
                }

                function shallowReference(value) {
                    return createReference(value, true)
                }

                const ReferenceImpl = class {

                    constructor(value, _shallow = false) {

                        this._shallow = _shallow
                        this.__v_isRef = true
                        this._rawValue = _shallow ? value : toRaw2(value)
                        this._value = _shallow ? value : convert(value)

                    }

                    get value() {

                        track2(toRaw2(this), 'get', 'value')

                        return this._value

                    }

                    set value(newValue) {

                        newValue = this._shallow ? newValue : toRaw2(newValue)

                        if (shared.hasChanged(newValue, this._rawValue)) {

                            this._rawValue = newValue
                            this._value = this._shallow ? newValue : convert(newValue)
                            trigger2(toRaw2(this), 'set', 'value', newValue)

                        }

                    }

                }

                function createReference(rawValue, shallow = false) {

                    if (isReference(rawValue)) {
                        return rawValue
                    }

                    return new ReferenceImpl(rawValue, shallow)

                }

                function triggerReference(reference2) {
                    trigger2(toRaw2(reference2), 'set', 'value', reference2.value)
                }

                function unref(reference2) {
                    return isReference(reference2) ? reference2.value : reference2
                }

                const shallowUnwrapHandlers = {
                    get: (target, key, receiver) => unref(Reflect.get(target, key, receiver)),
                    set: (target, key, value, receiver) => {

                        const oldValue = target[ key ]

                        if (isReference(oldValue) && !isReference(value)) {

                            oldValue.value = value

                            return true

                        }

                        return Reflect.set(target, key, value, receiver)

                    },
                }

                function proxyReferences(objectWithReferences) {
                    return isReactive2(objectWithReferences) ? objectWithReferences : new Proxy(objectWithReferences, shallowUnwrapHandlers)
                }

                const CustomReferenceImpl = class {

                    constructor(factory) {

                        this.__v_isRef = true

                        const { get: get3, set: set3 } = factory(() => track2(this, 'get', 'value'), () => trigger2(this, 'set', 'value'))

                        this._get = get3
                        this._set = set3

                    }

                    get value() {
                        return this._get()
                    }

                    set value(newValue) {
                        this._set(newValue)
                    }

                }

                function customReference(factory) {
                    return new CustomReferenceImpl(factory)
                }

                function toReferences(object) {

                    if (!isProxy(object)) {
                        console.warn('toRefs() expects a reactive object but received a plain one.')
                    }

                    const returnValue = shared.isArray(object) ? new Array(object.length) : {}

                    for (const key in object) {
                        returnValue[ key ] = toReference(object, key)
                    }

                    return returnValue

                }

                const ObjectReferenceImpl = class {

                    constructor(_object, _key) {

                        this._object = _object
                        this._key = _key
                        this.__v_isRef = true

                    }

                    get value() {
                        return this._object[ this._key ]
                    }

                    set value(newValue) {
                        this._object[ this._key ] = newValue
                    }

                }

                function toReference(object, key) {
                    return isReference(object[ key ]) ? object[ key ] : new ObjectReferenceImpl(object, key)
                }

                const ComputedReferenceImpl = class {

                    constructor(getter, _setter, isReadonly2) {

                        this._setter = _setter
                        this._dirty = true
                        this.__v_isRef = true

                        this.effect = effect3(getter, {
                            lazy: true,
                            scheduler: () => {

                                if (!this._dirty) {

                                    this._dirty = true
                                    trigger2(toRaw2(this), 'set', 'value')

                                }

                            },
                        })

                        this.__v_isReadonly = isReadonly2

                    }

                    get value() {

                        const self2 = toRaw2(this)

                        if (self2._dirty) {

                            self2._value = this.effect()
                            self2._dirty = false

                        }

                        track2(self2, 'get', 'value')

                        return self2._value

                    }

                    set value(newValue) {
                        this._setter(newValue)
                    }

                }

                function computed(getterOrOptions) {

                    let getter
                    let setter

                    if (shared.isFunction(getterOrOptions)) {

                        getter = getterOrOptions

                        setter = () => {
                            console.warn('Write operation failed: computed value is readonly')
                        }

                    } else {

                        getter = getterOrOptions.get
                        setter = getterOrOptions.set

                    }

                    return new ComputedReferenceImpl(getter, setter, shared.isFunction(getterOrOptions) || !getterOrOptions.set)

                }

                exports2.ITERATE_KEY = ITERATE_KEY
                exports2.computed = computed
                exports2.customRef = customReference
                exports2.effect = effect3
                exports2.enableTracking = enableTracking
                exports2.isProxy = isProxy
                exports2.isReactive = isReactive2
                exports2.isReadonly = isReadonly
                exports2.isRef = isReference
                exports2.markRaw = markRaw
                exports2.pauseTracking = pauseTracking
                exports2.proxyRefs = proxyReferences
                exports2.reactive = reactive3
                exports2.readonly = readonly
                exports2.ref = reference
                exports2.resetTracking = resetTracking
                exports2.shallowReactive = shallowReactive
                exports2.shallowReadonly = shallowReadonly
                exports2.shallowRef = shallowReference
                exports2.stop = stop2
                exports2.toRaw = toRaw2
                exports2.toRef = toReference
                exports2.toRefs = toReferences
                exports2.track = track2
                exports2.trigger = trigger2
                exports2.triggerRef = triggerReference
                exports2.unref = unref

            },
        })

        const require_reactivity = __commonJS2({
            'node_modules/@vue/reactivity/index.js'(exports2, module2) {

                'use strict'

                if (false) {
                    module2.exports = null
                } else {
                    module2.exports = require_reactivity_cjs()
                }

            },
        })

        const module_exports = {}

        __export(module_exports, {
            Alpine: () => source_default,
            default: () => module_default,
        })

        module.exports = __toCommonJS(module_exports)

        let flushPending = false
        let flushing = false

        const queue = []

        let lastFlushedIndex = -1

        function scheduler(callback) {
            queueJob(callback)
        }

        function queueJob(job) {

            if (!queue.includes(job)) { queue.push(job) }

            queueFlush()

        }

        function dequeueJob(job) {

            const index = queue.indexOf(job)

            if (index !== -1 && index > lastFlushedIndex) { queue.splice(index, 1) }

        }

        function queueFlush() {

            if (!flushing && !flushPending) {

                flushPending = true
                queueMicrotask(flushJobs)

            }

        }

        function flushJobs() {

            flushPending = false
            flushing = true

            for (let index = 0; index < queue.length; index++) {

                queue[ index ]()
                lastFlushedIndex = index

            }

            queue.length = 0
            lastFlushedIndex = -1
            flushing = false

        }

        let reactive
        let effect
        let release
        let raw
        let shouldSchedule = true

        function disableEffectScheduling(callback) {

            shouldSchedule = false
            callback()
            shouldSchedule = true

        }

        function setReactivityEngine(engine) {

            reactive = engine.reactive
            release = engine.release

            effect = callback => engine.effect(callback, { scheduler: task => {

                if (shouldSchedule) {
                    scheduler(task)
                } else {
                    task()
                }

            } })

            raw = engine.raw

        }

        function overrideEffect(override) {
            effect = override
        }

        function elementBoundEffect(element) {

            let cleanup = () => {
            }

            const wrappedEffect = callback => {

                const effectReference = effect(callback)

                if (!element._x_effects) {

                    element._x_effects = /* @__PURE__ */ new Set()

                    element._x_runEffects = () => {
                        for (const index of element._x_effects) { index() }
                    }

                }

                element._x_effects.add(effectReference)

                cleanup = () => {

                    if (effectReference === void 0) { return }

                    element._x_effects.delete(effectReference)
                    release(effectReference)

                }

                return effectReference

            }

            return [
                wrappedEffect, () => {
                    cleanup()
                },
            ]

        }

        function watch(getter, callback) {

            let firstTime = true
            let oldValue

            const effectReference = effect(() => {

                const value = getter()

                JSON.stringify(value)

                if (!firstTime) {

                    queueMicrotask(() => {

                        callback(value, oldValue)
                        oldValue = value

                    })

                } else {
                    oldValue = value
                }

                firstTime = false

            })

            return () => release(effectReference)

        }

        const onAttributeAddeds = []
        const onElementRemoveds = []
        const onElementAddeds = []

        function onElementAdded(callback) {
            onElementAddeds.push(callback)
        }

        function onElementRemoved(element, callback) {

            if (typeof callback === 'function') {

                if (!element._x_cleanups) { element._x_cleanups = [] }

                element._x_cleanups.push(callback)

            } else {

                callback = element
                onElementRemoveds.push(callback)

            }

        }

        function onAttributesAdded(callback) {
            onAttributeAddeds.push(callback)
        }

        function onAttributeRemoved(element, name, callback) {

            if (!element._x_attributeCleanups) { element._x_attributeCleanups = {} }

            if (!element._x_attributeCleanups[ name ]) { element._x_attributeCleanups[ name ] = [] }

            element._x_attributeCleanups[ name ].push(callback)

        }

        function cleanupAttributes(element, names) {

            if (!element._x_attributeCleanups) { return }

            for (const [ name, value ] of Object.entries(element._x_attributeCleanups)) {

                if (names === void 0 || names.includes(name)) {

                    for (const index of value) { index() }

                    delete element._x_attributeCleanups[ name ]

                }

            }

        }

        function cleanupElement(element) {

            if (element._x_cleanups) {
                while (element._x_cleanups.length) { element._x_cleanups.pop()() }
            }

        }

        const observer = new MutationObserver(onMutate)

        let currentlyObserving = false

        function startObservingMutations() {

            observer.observe(document, { subtree: true, childList: true, attributes: true, attributeOldValue: true })
            currentlyObserving = true

        }

        function stopObservingMutations() {

            flushObserver()
            observer.disconnect()
            currentlyObserving = false

        }

        const queuedMutations = []

        function flushObserver() {

            const records = observer.takeRecords()

            queuedMutations.push(() => records.length > 0 && onMutate(records))

            const queueLengthWhenTriggered = queuedMutations.length

            queueMicrotask(() => {

                if (queuedMutations.length === queueLengthWhenTriggered) {
                    while (queuedMutations.length > 0) { queuedMutations.shift()() }
                }

            })

        }

        function mutateDom(callback) {

            if (!currentlyObserving) { return callback() }

            stopObservingMutations()

            const result = callback()

            startObservingMutations()

            return result

        }

        let isCollecting = false
        let deferredMutations = []

        function deferMutations() {
            isCollecting = true
        }

        function flushAndStopDeferringMutations() {

            isCollecting = false
            onMutate(deferredMutations)
            deferredMutations = []

        }

        function onMutate(mutations) {

            if (isCollecting) {

                deferredMutations = deferredMutations.concat(mutations)

                return

            }

            let addedNodes = /* @__PURE__ */ new Set()
            let removedNodes = /* @__PURE__ */ new Set()
            let addedAttributes = /* @__PURE__ */ new Map()
            let removedAttributes = /* @__PURE__ */ new Map()

            for (let index = 0; index < mutations.length; index++) {

                if (mutations[ index ].target._x_ignoreMutationObserver) { continue }

                if (mutations[ index ].type === 'childList') {

                    for (const node of mutations[ index ].addedNodes) { node.nodeType === 1 && addedNodes.add(node) }

                    for (const node of mutations[ index ].removedNodes) { node.nodeType === 1 && removedNodes.add(node) }

                }

                if (mutations[ index ].type === 'attributes') {

                    const element = mutations[ index ].target
                    const name = mutations[ index ].attributeName
                    const oldValue = mutations[ index ].oldValue

                    const add = () => {

                        if (!addedAttributes.has(element)) { addedAttributes.set(element, []) }

                        addedAttributes.get(element).push({ name, value: element.getAttribute(name) })

                    }

                    const remove = () => {

                        if (!removedAttributes.has(element)) { removedAttributes.set(element, []) }

                        removedAttributes.get(element).push(name)

                    }

                    if (element.hasAttribute(name) && oldValue === null) {
                        add()
                    } else if (element.hasAttribute(name)) {

                        remove()
                        add()

                    } else {
                        remove()
                    }

                }

            }

            for (const [ element, attributes ] of removedAttributes.entries()) {
                cleanupAttributes(element, attributes)
            }

            for (const [ element, attributes ] of addedAttributes.entries()) {
                for (const index of onAttributeAddeds) { index(element, attributes) }
            }

            for (const node of removedNodes) {

                if (addedNodes.has(node)) { continue }

                for (const index of onElementRemoveds) { index(node) }

            }

            for (const node of addedNodes) {

                node._x_ignoreSelf = true
                node._x_ignore = true

            }

            for (const node of addedNodes) {

                if (removedNodes.has(node)) { continue }

                if (!node.isConnected) { continue }

                delete node._x_ignoreSelf
                delete node._x_ignore

                for (const index of onElementAddeds) { index(node) }

                node._x_ignore = true
                node._x_ignoreSelf = true

            }

            for (const node of addedNodes) {

                delete node._x_ignoreSelf
                delete node._x_ignore

            }

            addedNodes = null
            removedNodes = null
            addedAttributes = null
            removedAttributes = null

        }

        function scope(node) {
            return mergeProxies(closestDataStack(node))
        }

        function addScopeToNode(node, data2, referenceNode) {

            node._x_dataStack = [ data2, ...closestDataStack(referenceNode || node) ]

            return () => {
                node._x_dataStack = node._x_dataStack.filter(index => index !== data2)
            }

        }

        function closestDataStack(node) {

            if (node._x_dataStack) { return node._x_dataStack }

            if (typeof ShadowRoot === 'function' && node instanceof ShadowRoot) {
                return closestDataStack(node.host)
            }

            if (!node.parentNode) {
                return []
            }

            return closestDataStack(node.parentNode)

        }

        function mergeProxies(objects) {
            return new Proxy({ objects }, mergeProxyTrap)
        }

        var mergeProxyTrap = {
            ownKeys({ objects }) {
                return Array.from(new Set(objects.flatMap(index => Object.keys(index))))
            },
            has({ objects }, name) {

                if (name == Symbol.unscopables) { return false }

                return objects.some(object => Object.prototype.hasOwnProperty.call(object, name) || Reflect.has(object, name))

            },
            get({ objects }, name, thisProxy) {

                if (name == 'toJSON') { return collapseProxies }

                return Reflect.get(objects.find(object => Reflect.has(object, name)) || {}, name, thisProxy)

            },
            set({ objects }, name, value, thisProxy) {

                const target = objects.find(object => Object.prototype.hasOwnProperty.call(object, name)) || objects[ objects.length - 1 ]
                const descriptor = Object.getOwnPropertyDescriptor(target, name)

                if ((descriptor == null ? void 0 : descriptor.set) && (descriptor == null ? void 0 : descriptor.get)) { return descriptor.set.call(thisProxy, value) || true }

                return Reflect.set(target, name, value)

            },
        }

        function collapseProxies() {

            const keys = Reflect.ownKeys(this)

            return keys.reduce((accumulator, key) => {

                accumulator[ key ] = Reflect.get(this, key)

                return accumulator

            }, {})

        }

        function initInterceptors(data2) {

            const isObject2 = value => typeof value === 'object' && !Array.isArray(value) && value !== null

            const recurse = (object, basePath = '') => {

                for (const [ key, { value, enumerable } ] of Object.entries(Object.getOwnPropertyDescriptors(object))) {

                    if (enumerable === false || value === void 0) { continue }

                    if (typeof value === 'object' && value !== null && value.__v_skip) { continue }

                    const path = basePath === '' ? key : `${ basePath }.${ key }`

                    if (typeof value === 'object' && value !== null && value._x_interceptor) {
                        object[ key ] = value.initialize(data2, path, key)
                    } else {

                        if (isObject2(value) && value !== object && !(value instanceof Element)) {
                            recurse(value, path)
                        }

                    }

                }

            }

            return recurse(data2)

        }

        function interceptor(callback, mutateObject = () => {
        }) {

            const object = {
                initialValue: void 0,
                _x_interceptor: true,
                initialize(data2, path, key) {
                    return callback(this.initialValue, () => get(data2, path), value => set(data2, path, value), path, key)
                },
            }

            mutateObject(object)

            return initialValue => {

                if (typeof initialValue === 'object' && initialValue !== null && initialValue._x_interceptor) {

                    const initialize = object.initialize.bind(object)

                    object.initialize = (data2, path, key) => {

                        const innerValue = initialValue.initialize(data2, path, key)

                        object.initialValue = innerValue

                        return initialize(data2, path, key)

                    }

                } else {
                    object.initialValue = initialValue
                }

                return object

            }

        }

        function get(object, path) {
            return path.split('.').reduce((carry, segment) => carry[ segment ], object)
        }

        function set(object, path, value) {

            if (typeof path === 'string') { path = path.split('.') }

            if (path.length === 1) { object[ path[ 0 ] ] = value } else if (path.length === 0) { throw error } else {

                if (object[ path[ 0 ] ]) { return set(object[ path[ 0 ] ], path.slice(1), value) }

                object[ path[ 0 ] ] = {}

                return set(object[ path[ 0 ] ], path.slice(1), value)

            }

        }

        const magics = {}

        function magic(name, callback) {
            magics[ name ] = callback
        }

        function injectMagics(object, element) {

            for (const [ name, callback ] of Object.entries(magics)) {

                let memoizedUtilities = null

                function getUtilities() {

                    if (memoizedUtilities) {
                        return memoizedUtilities
                    }

                    const [ utilities, cleanup ] = getElementBoundUtilities(element)

                    memoizedUtilities = { interceptor, ...utilities }
                    onElementRemoved(element, cleanup)

                    return memoizedUtilities

                }

                Object.defineProperty(object, `$${ name }`, {
                    get() {
                        return callback(element, getUtilities())
                    },
                    enumerable: false,
                })

            }

            return object

        }

        function tryCatch(element, expression, callback, ...arguments_) {

            try {
                return callback(...arguments_)
            } catch (e) {
                handleError(e, element, expression)
            }

        }

        function handleError(error2, element, expression = void 0) {

            error2 = Object.assign(error2 != null ? error2 : { message: 'No error message given.' }, { el: element, expression })

            console.warn(`Alpine Expression Error: ${ error2.message }

${ expression ? `Expression: "${ expression }"\n\n` : '' }`, element)

            setTimeout(() => {
                throw error2
            }, 0)

        }

        let shouldAutoEvaluateFunctions = true

        function dontAutoEvaluateFunctions(callback) {

            const cache = shouldAutoEvaluateFunctions

            shouldAutoEvaluateFunctions = false

            const result = callback()

            shouldAutoEvaluateFunctions = cache

            return result

        }

        function evaluate(element, expression, extras = {}) {

            let result

            evaluateLater(element, expression)(value => result = value, extras)

            return result

        }

        function evaluateLater(...arguments_) {
            return theEvaluatorFunction(...arguments_)
        }

        var theEvaluatorFunction = normalEvaluator

        function setEvaluator(newEvaluator) {
            theEvaluatorFunction = newEvaluator
        }

        function normalEvaluator(element, expression) {

            const overriddenMagics = {}

            injectMagics(overriddenMagics, element)

            const dataStack = [ overriddenMagics, ...closestDataStack(element) ]
            const evaluator = typeof expression === 'function' ? generateEvaluatorFromFunction(dataStack, expression) : generateEvaluatorFromString(dataStack, expression, element)

            return tryCatch.bind(null, element, expression, evaluator)

        }

        function generateEvaluatorFromFunction(dataStack, function_) {

            return (receiver = () => {
            }, { scope: scope2 = {}, params: parameters = [] } = {}) => {

                const result = function_.apply(mergeProxies([ scope2, ...dataStack ]), parameters)

                runIfTypeOfFunction(receiver, result)

            }

        }

        const evaluatorMemo = {}

        function generateFunctionFromString(expression, element) {

            if (evaluatorMemo[ expression ]) {
                return evaluatorMemo[ expression ]
            }

            const AsyncFunction = Object.getPrototypeOf(async function() {
            }).constructor

            const rightSideSafeExpression = /^[\n\s]*if.*\(.*\)/.test(expression.trim()) || /^(let|const)\s/.test(expression.trim()) ? `(async()=>{ ${ expression } })()` : expression

            const safeAsyncFunction = () => {

                try {

                    const function2 = new AsyncFunction([ '__self', 'scope' ], `with (scope) { __self.result = ${ rightSideSafeExpression } }; __self.finished = true; return __self.result;`)

                    Object.defineProperty(function2, 'name', {
                        value: `[Alpine] ${ expression }`,
                    })

                    return function2

                } catch (error2) {

                    handleError(error2, element, expression)

                    return Promise.resolve()

                }

            }

            const function_ = safeAsyncFunction()

            evaluatorMemo[ expression ] = function_

            return function_

        }

        function generateEvaluatorFromString(dataStack, expression, element) {

            const function_ = generateFunctionFromString(expression, element)

            return (receiver = () => {
            }, { scope: scope2 = {}, params: parameters = [] } = {}) => {

                function_.result = void 0
                function_.finished = false

                const completeScope = mergeProxies([ scope2, ...dataStack ])

                if (typeof function_ === 'function') {

                    const promise = function_(function_, completeScope).catch(error2 => handleError(error2, element, expression))

                    if (function_.finished) {

                        runIfTypeOfFunction(receiver, function_.result, completeScope, parameters, element)
                        function_.result = void 0

                    } else {

                        promise.then(result => {
                            runIfTypeOfFunction(receiver, result, completeScope, parameters, element)
                        }).catch(error2 => handleError(error2, element, expression)).finally(() => function_.result = void 0)

                    }

                }

            }

        }

        function runIfTypeOfFunction(receiver, value, scope2, parameters, element) {

            if (shouldAutoEvaluateFunctions && typeof value === 'function') {

                const result = value.apply(scope2, parameters)

                if (result instanceof Promise) {
                    result.then(index => runIfTypeOfFunction(receiver, index, scope2, parameters)).catch(error2 => handleError(error2, element, value))
                } else {
                    receiver(result)
                }

            } else if (typeof value === 'object' && value instanceof Promise) {
                value.then(index => receiver(index))
            } else {
                receiver(value)
            }

        }

        let prefixAsString = 'x-'

        function prefix(subject = '') {
            return prefixAsString + subject
        }

        function setPrefix(newPrefix) {
            prefixAsString = newPrefix
        }

        const directiveHandlers = {}

        function directive2(name, callback) {

            directiveHandlers[ name ] = callback

            return {
                before(directive22) {

                    if (!directiveHandlers[ directive22 ]) {

                        console.warn(String.raw`Cannot find directive \`${ directive22 }\`. \`${ name }\` will use the default order of execution`)

                        return

                    }

                    const pos = directiveOrder.indexOf(directive22)

                    directiveOrder.splice(pos >= 0 ? pos : directiveOrder.indexOf('DEFAULT'), 0, name)

                },
            }

        }

        function directiveExists(name) {
            return Object.keys(directiveHandlers).includes(name)
        }

        function directives(element, attributes, originalAttributeOverride) {

            attributes = Array.from(attributes)

            if (element._x_virtualDirectives) {

                let vAttributes = Object.entries(element._x_virtualDirectives).map(([ name, value ]) => ({ name, value }))

                const staticAttributes = attributesOnly(vAttributes)

                vAttributes = vAttributes.map(attribute => {

                    if (staticAttributes.find(attribute_ => attribute_.name === attribute.name)) {

                        return {
                            name: `x-bind:${ attribute.name }`,
                            value: `"${ attribute.value }"`,
                        }

                    }

                    return attribute

                })

                attributes = attributes.concat(vAttributes)

            }

            const transformedAttributeMap = {}
            const directives2 = attributes.map(toTransformedAttributes((newName, oldName) => transformedAttributeMap[ newName ] = oldName)).filter(outNonAlpineAttributes).map(toParsedDirectives(transformedAttributeMap, originalAttributeOverride)).sort(byPriority)

            return directives2.map(directive22 => getDirectiveHandler(element, directive22))

        }

        function attributesOnly(attributes) {
            return Array.from(attributes).map(toTransformedAttributes()).filter(attribute => !outNonAlpineAttributes(attribute))
        }

        let isDeferringHandlers = false

        const directiveHandlerStacks = /* @__PURE__ */ new Map()

        let currentHandlerStackKey = Symbol()

        function deferHandlingDirectives(callback) {

            isDeferringHandlers = true

            const key = Symbol()

            currentHandlerStackKey = key
            directiveHandlerStacks.set(key, [])

            const flushHandlers = () => {

                while (directiveHandlerStacks.get(key).length) { directiveHandlerStacks.get(key).shift()() }

                directiveHandlerStacks.delete(key)

            }

            const stopDeferring = () => {

                isDeferringHandlers = false
                flushHandlers()

            }

            callback(flushHandlers)
            stopDeferring()

        }

        function getElementBoundUtilities(element) {

            const cleanups2 = []
            const cleanup = callback => cleanups2.push(callback)
            const [ effect3, cleanupEffect ] = elementBoundEffect(element)

            cleanups2.push(cleanupEffect)

            const utilities = {
                Alpine: alpine_default,
                effect: effect3,
                cleanup,
                evaluateLater: evaluateLater.bind(evaluateLater, element),
                evaluate: evaluate.bind(evaluate, element),
            }

            const doCleanup = () => cleanups2.forEach(index => index())

            return [ utilities, doCleanup ]

        }

        function getDirectiveHandler(element, directive22) {

            const noop = () => {
            }

            let handler4 = directiveHandlers[ directive22.type ] || noop

            const [ utilities, cleanup ] = getElementBoundUtilities(element)

            onAttributeRemoved(element, directive22.original, cleanup)

            const fullHandler = () => {

                if (element._x_ignore || element._x_ignoreSelf) { return }

                handler4.inline && handler4.inline(element, directive22, utilities)
                handler4 = handler4.bind(handler4, element, directive22, utilities)
                isDeferringHandlers ? directiveHandlerStacks.get(currentHandlerStackKey).push(handler4) : handler4()

            }

            fullHandler.runCleanups = cleanup

            return fullHandler

        }

        const startingWith = (subject, replacement) => ({ name, value }) => {

            if (name.startsWith(subject)) { name = name.replace(subject, replacement) }

            return { name, value }

        }

        const into = index => index

        function toTransformedAttributes(callback = () => {
        }) {

            return ({ name, value }) => {

                const { name: newName, value: newValue } = attributeTransformers.reduce((carry, transform) => transform(carry), { name, value })

                if (newName !== name) { callback(newName, name) }

                return { name: newName, value: newValue }

            }

        }

        var attributeTransformers = []

        function mapAttributes(callback) {
            attributeTransformers.push(callback)
        }

        function outNonAlpineAttributes({ name }) {
            return alpineAttributeRegex().test(name)
        }

        var alpineAttributeRegex = () => new RegExp(`^${ prefixAsString }([^:^.]+)\\b`)

        function toParsedDirectives(transformedAttributeMap, originalAttributeOverride) {

            return ({ name, value }) => {

                const typeMatch = name.match(alpineAttributeRegex())
                const valueMatch = name.match(/:([a-zA-Z0-9\-_:]+)/)
                const modifiers = name.match(/\.[^.\]]+(?=[^\]]*$)/g) || []
                const original = originalAttributeOverride || transformedAttributeMap[ name ] || name

                return {
                    type: typeMatch ? typeMatch[ 1 ] : null,
                    value: valueMatch ? valueMatch[ 1 ] : null,
                    modifiers: modifiers.map(index => index.replace('.', '')),
                    expression: value,
                    original,
                }

            }

        }

        const DEFAULT = 'DEFAULT'

        var directiveOrder = [
            'ignore',
            'ref',
            'data',
            'id',
            'anchor',
            'bind',
            'init',
            'for',
            'model',
            'modelable',
            'transition',
            'show',
            'if',
            DEFAULT,
            'teleport',
        ]

        function byPriority(a, b) {

            const typeA = directiveOrder.indexOf(a.type) === -1 ? DEFAULT : a.type
            const typeB = directiveOrder.indexOf(b.type) === -1 ? DEFAULT : b.type

            return directiveOrder.indexOf(typeA) - directiveOrder.indexOf(typeB)

        }

        function dispatch3(element, name, detail = {}) {

            element.dispatchEvent(new CustomEvent(name, {
                detail,
                bubbles: true,
                composed: true,
                cancelable: true,
            }))

        }

        function walk(element, callback) {

            if (typeof ShadowRoot === 'function' && element instanceof ShadowRoot) {

                for (const element2 of Array.from(element.children)) { walk(element2, callback) }

                return

            }

            let skip = false

            callback(element, () => skip = true)

            if (skip) { return }

            let node = element.firstElementChild

            while (node) {

                walk(node, callback, false)
                node = node.nextElementSibling

            }

        }

        function warn(message, ...arguments_) {
            console.warn(`Alpine Warning: ${ message }`, ...arguments_)
        }

        let started = false

        function start2() {

            if (started) { warn('Alpine has already been initialized on this page. Calling Alpine.start() more than once can cause problems.') }

            started = true

            if (!document.body) { warn('Unable to initialize. Trying to load Alpine before `<body>` is available. Did you forget to add `defer` in Alpine\'s `<script>` tag?') }

            dispatch3(document, 'alpine:init')
            dispatch3(document, 'alpine:initializing')
            startObservingMutations()
            onElementAdded(element => initTree(element, walk))
            onElementRemoved(element => destroyTree(element))

            onAttributesAdded((element, attributes) => {
                for (const handle of directives(element, attributes)) { handle() }
            })

            const outNestedComponents = element => !closestRoot(element.parentElement, true)

            for (const element of Array.from(document.querySelectorAll(allSelectors().join(','))).filter(outNestedComponents)) {
                initTree(element)
            }

            dispatch3(document, 'alpine:initialized')

            setTimeout(() => {
                warnAboutMissingPlugins()
            })

        }

        const rootSelectorCallbacks = []
        const initSelectorCallbacks = []

        function rootSelectors() {
            return rootSelectorCallbacks.map(function_ => function_())
        }

        function allSelectors() {
            return rootSelectorCallbacks.concat(initSelectorCallbacks).map(function_ => function_())
        }

        function addRootSelector(selectorCallback) {
            rootSelectorCallbacks.push(selectorCallback)
        }

        function addInitSelector(selectorCallback) {
            initSelectorCallbacks.push(selectorCallback)
        }

        function closestRoot(element, includeInitSelectors = false) {

            return findClosest(element, element => {

                const selectors = includeInitSelectors ? allSelectors() : rootSelectors()

                if (selectors.some(selector => element.matches(selector))) { return true }

            })

        }

        function findClosest(element, callback) {

            if (!element) { return }

            if (callback(element)) { return element }

            if (element._x_teleportBack) { element = element._x_teleportBack }

            if (!element.parentElement) { return }

            return findClosest(element.parentElement, callback)

        }

        function isRoot(element) {
            return rootSelectors().some(selector => element.matches(selector))
        }

        const initInterceptors2 = []

        function interceptInit(callback) {
            initInterceptors2.push(callback)
        }

        function initTree(element, walker = walk, intercept = () => {
        }) {

            deferHandlingDirectives(() => {

                walker(element, (element2, skip) => {

                    intercept(element2, skip)

                    for (const index of initInterceptors2) { index(element2, skip) }

                    for (const handle of directives(element2, element2.attributes)) { handle() }

                    element2._x_ignore && skip()

                })

            })

        }

        function destroyTree(root, walker = walk) {

            walker(root, element => {

                cleanupAttributes(element)
                cleanupElement(element)

            })

        }

        function warnAboutMissingPlugins() {

            const pluginDirectives = [
                [ 'ui', 'dialog', [ '[x-dialog], [x-popover]' ] ],
                [ 'anchor', 'anchor', [ '[x-anchor]' ] ],
                [ 'sort', 'sort', [ '[x-sort]' ] ],
            ]

            for (const [ plugin2, directive22, selectors ] of pluginDirectives) {

                if (directiveExists(directive22)) { continue }

                selectors.some(selector => {

                    if (document.querySelector(selector)) {

                        warn(`found "${ selector }", but missing ${ plugin2 } plugin`)

                        return true

                    }

                })

            }

        }

        const tickStack = []

        let isHolding = false

        function nextTick(callback = () => {
        }) {

            queueMicrotask(() => {

                isHolding || setTimeout(() => {
                    releaseNextTicks()
                })

            })

            return new Promise(res => {

                tickStack.push(() => {

                    callback()
                    res()

                })

            })

        }

        function releaseNextTicks() {

            isHolding = false

            while (tickStack.length) { tickStack.shift()() }

        }

        function holdNextTicks() {
            isHolding = true
        }

        function setClasses(element, value) {

            if (Array.isArray(value)) {
                return setClassesFromString(element, value.join(' '))
            }

            if (typeof value === 'object' && value !== null) {
                return setClassesFromObject(element, value)
            }

            if (typeof value === 'function') {
                return setClasses(element, value())
            }

            return setClassesFromString(element, value)

        }

        function setClassesFromString(element, classString) {

            const split = classString2 => classString2.split(' ').filter(Boolean)
            const missingClasses = classString2 => classString2.split(' ').filter(index => !element.classList.contains(index)).filter(Boolean)

            const addClassesAndReturnUndo = classes => {

                element.classList.add(...classes)

                return () => {
                    element.classList.remove(...classes)
                }

            }

            classString = classString === true ? classString = '' : classString || ''

            return addClassesAndReturnUndo(missingClasses(classString))

        }

        function setClassesFromObject(element, classObject) {

            const split = classString => classString.split(' ').filter(Boolean)
            const forAdd = Object.entries(classObject).flatMap(([ classString, bool ]) => bool ? split(classString) : false).filter(Boolean)
            const forRemove = Object.entries(classObject).flatMap(([ classString, bool ]) => !bool ? split(classString) : false).filter(Boolean)
            const added = []
            const removed = []

            for (const index of forRemove) {

                if (element.classList.contains(index)) {

                    element.classList.remove(index)
                    removed.push(index)

                }

            }

            for (const index of forAdd) {

                if (!element.classList.contains(index)) {

                    element.classList.add(index)
                    added.push(index)

                }

            }

            return () => {

                for (const index of removed) { element.classList.add(index) }

                for (const index of added) { element.classList.remove(index) }

            }

        }

        function setStyles(element, value) {

            if (typeof value === 'object' && value !== null) {
                return setStylesFromObject(element, value)
            }

            return setStylesFromString(element, value)

        }

        function setStylesFromObject(element, value) {

            const previousStyles = {}

            for (let [ key, value2 ] of Object.entries(value)) {

                previousStyles[ key ] = element.style[ key ]

                if (!key.startsWith('--')) {
                    key = kebabCase(key)
                }

                element.style.setProperty(key, value2)

            }

            setTimeout(() => {

                if (element.style.length === 0) {
                    element.removeAttribute('style')
                }

            })

            return () => {
                setStyles(element, previousStyles)
            }

        }

        function setStylesFromString(element, value) {

            const cache = element.getAttribute('style', value)

            element.setAttribute('style', value)

            return () => {
                element.setAttribute('style', cache || '')
            }

        }

        function kebabCase(subject) {
            return subject.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        }

        function once(callback, fallback2 = () => {
        }) {

            let called = false

            return function() {

                if (!called) {

                    called = true
                    callback.apply(this, arguments)

                } else {
                    fallback2.apply(this, arguments)
                }

            }

        }

        directive2('transition', (element, { value, modifiers, expression }, { evaluate: evaluate2 }) => {

            if (typeof expression === 'function') { expression = evaluate2(expression) }

            if (expression === false) { return }

            if (!expression || typeof expression === 'boolean') {
                registerTransitionsFromHelper(element, modifiers, value)
            } else {
                registerTransitionsFromClassString(element, expression, value)
            }

        })

        function registerTransitionsFromClassString(element, classString, stage) {

            registerTransitionObject(element, setClasses, '')

            const directiveStorageMap = {
                enter: classes => {
                    element._x_transition.enter.during = classes
                },
                'enter-start': classes => {
                    element._x_transition.enter.start = classes
                },
                'enter-end': classes => {
                    element._x_transition.enter.end = classes
                },
                leave: classes => {
                    element._x_transition.leave.during = classes
                },
                'leave-start': classes => {
                    element._x_transition.leave.start = classes
                },
                'leave-end': classes => {
                    element._x_transition.leave.end = classes
                },
            }

            directiveStorageMap[ stage ](classString)

        }

        function registerTransitionsFromHelper(element, modifiers, stage) {

            registerTransitionObject(element, setStyles)

            const doesntSpecify = !modifiers.includes('in') && !modifiers.includes('out') && !stage
            const transitioningIn = doesntSpecify || modifiers.includes('in') || [ 'enter' ].includes(stage)
            const transitioningOut = doesntSpecify || modifiers.includes('out') || [ 'leave' ].includes(stage)

            if (modifiers.includes('in') && !doesntSpecify) {
                modifiers = modifiers.filter((index_, index) => index < modifiers.indexOf('out'))
            }

            if (modifiers.includes('out') && !doesntSpecify) {
                modifiers = modifiers.filter((index_, index) => index > modifiers.indexOf('out'))
            }

            const wantsAll = !modifiers.includes('opacity') && !modifiers.includes('scale')
            const wantsOpacity = wantsAll || modifiers.includes('opacity')
            const wantsScale = wantsAll || modifiers.includes('scale')
            const opacityValue = wantsOpacity ? 0 : 1
            const scaleValue = wantsScale ? modifierValue(modifiers, 'scale', 95) / 100 : 1
            const delay = modifierValue(modifiers, 'delay', 0) / 1e3
            const origin = modifierValue(modifiers, 'origin', 'center')
            const property = 'opacity, transform'
            const durationIn = modifierValue(modifiers, 'duration', 150) / 1e3
            const durationOut = modifierValue(modifiers, 'duration', 75) / 1e3
            const easing = 'cubic-bezier(0.4, 0.0, 0.2, 1)'

            if (transitioningIn) {

                element._x_transition.enter.during = {
                    transformOrigin: origin,
                    transitionDelay: `${ delay }s`,
                    transitionProperty: property,
                    transitionDuration: `${ durationIn }s`,
                    transitionTimingFunction: easing,
                }

                element._x_transition.enter.start = {
                    opacity: opacityValue,
                    transform: `scale(${ scaleValue })`,
                }

                element._x_transition.enter.end = {
                    opacity: 1,
                    transform: 'scale(1)',
                }

            }

            if (transitioningOut) {

                element._x_transition.leave.during = {
                    transformOrigin: origin,
                    transitionDelay: `${ delay }s`,
                    transitionProperty: property,
                    transitionDuration: `${ durationOut }s`,
                    transitionTimingFunction: easing,
                }

                element._x_transition.leave.start = {
                    opacity: 1,
                    transform: 'scale(1)',
                }

                element._x_transition.leave.end = {
                    opacity: opacityValue,
                    transform: `scale(${ scaleValue })`,
                }

            }

        }

        function registerTransitionObject(element, setFunction, defaultValue = {}) {

            if (!element._x_transition) {

                element._x_transition = {
                    enter: { during: defaultValue, start: defaultValue, end: defaultValue },
                    leave: { during: defaultValue, start: defaultValue, end: defaultValue },
                    in(before = () => {
                    }, after = () => {
                    }) {

                        transition(element, setFunction, {
                            during: this.enter.during,
                            start: this.enter.start,
                            end: this.enter.end,
                        }, before, after)

                    },
                    out(before = () => {
                    }, after = () => {
                    }) {

                        transition(element, setFunction, {
                            during: this.leave.during,
                            start: this.leave.start,
                            end: this.leave.end,
                        }, before, after)

                    },
                }

            }

        }

        window.Element.prototype._x_toggleAndCascadeWithTransitions = function(element, value, show, hide) {

            const nextTick2 = document.visibilityState === 'visible' ? requestAnimationFrame : setTimeout
            const clickAwayCompatibleShow = () => nextTick2(show)

            if (value) {

                if (element._x_transition && (element._x_transition.enter || element._x_transition.leave)) {
                    element._x_transition.enter && (Object.entries(element._x_transition.enter.during).length || Object.entries(element._x_transition.enter.start).length || Object.entries(element._x_transition.enter.end).length) ? element._x_transition.in(show) : clickAwayCompatibleShow()
                } else {
                    element._x_transition ? element._x_transition.in(show) : clickAwayCompatibleShow()
                }

                return

            }

            element._x_hidePromise = element._x_transition
                ? new Promise((resolve, reject) => {

                    element._x_transition.out(() => {
                    }, () => resolve(hide))

                    element._x_transitioning && element._x_transitioning.beforeCancel(() => reject({ isFromCancelledTransition: true }))

                })
                : Promise.resolve(hide)

            queueMicrotask(() => {

                const closest = closestHide(element)

                if (closest) {

                    if (!closest._x_hideChildren) { closest._x_hideChildren = [] }

                    closest._x_hideChildren.push(element)

                } else {

                    nextTick2(() => {

                        const hideAfterChildren = element2 => {

                            const carry = Promise.all([
                                element2._x_hidePromise,
                                ...(element2._x_hideChildren || []).map(hideAfterChildren),
                            ]).then(([ index ]) => index == null ? void 0 : index())

                            delete element2._x_hidePromise
                            delete element2._x_hideChildren

                            return carry

                        }

                        hideAfterChildren(element).catch(e => {
                            if (!e.isFromCancelledTransition) { throw e }
                        })

                    })

                }

            })

        }

        function closestHide(element) {

            const parent = element.parentNode

            if (!parent) { return }

            return parent._x_hidePromise ? parent : closestHide(parent)

        }

        function transition(element, setFunction, { during, start: start22, end } = {}, before = () => {
        }, after = () => {
        }) {

            if (element._x_transitioning) { element._x_transitioning.cancel() }

            if (Object.keys(during).length === 0 && Object.keys(start22).length === 0 && Object.keys(end).length === 0) {

                before()
                after()

                return

            }

            let undoStart, undoDuring, undoEnd

            performTransition(element, {
                start() {
                    undoStart = setFunction(element, start22)
                },
                during() {
                    undoDuring = setFunction(element, during)
                },
                before,
                end() {

                    undoStart()
                    undoEnd = setFunction(element, end)

                },
                after,
                cleanup() {

                    undoDuring()
                    undoEnd()

                },
            })

        }

        function performTransition(element, stages) {

            let interrupted, reachedBefore, reachedEnd

            const finish = once(() => {

                mutateDom(() => {

                    interrupted = true

                    if (!reachedBefore) { stages.before() }

                    if (!reachedEnd) {

                        stages.end()
                        releaseNextTicks()

                    }

                    stages.after()

                    if (element.isConnected) { stages.cleanup() }

                    delete element._x_transitioning

                })

            })

            element._x_transitioning = {
                beforeCancels: [],
                beforeCancel(callback) {
                    this.beforeCancels.push(callback)
                },
                cancel: once(function() {

                    while (this.beforeCancels.length) {
                        this.beforeCancels.shift()()
                    }

                    finish()

                }),
                finish,
            }

            mutateDom(() => {

                stages.start()
                stages.during()

            })

            holdNextTicks()

            requestAnimationFrame(() => {

                if (interrupted) { return }

                let duration = Number(getComputedStyle(element).transitionDuration.replace(/,.*/, '').replace('s', '')) * 1e3

                const delay = Number(getComputedStyle(element).transitionDelay.replace(/,.*/, '').replace('s', '')) * 1e3

                if (duration === 0) { duration = Number(getComputedStyle(element).animationDuration.replace('s', '')) * 1e3 }

                mutateDom(() => {
                    stages.before()
                })

                reachedBefore = true

                requestAnimationFrame(() => {

                    if (interrupted) { return }

                    mutateDom(() => {
                        stages.end()
                    })

                    releaseNextTicks()
                    setTimeout(element._x_transitioning.finish, duration + delay)
                    reachedEnd = true

                })

            })

        }

        function modifierValue(modifiers, key, fallback2) {

            if (modifiers.indexOf(key) === -1) { return fallback2 }

            const rawValue = modifiers[ modifiers.indexOf(key) + 1 ]

            if (!rawValue) { return fallback2 }

            if (key === 'scale') {
                if (isNaN(rawValue)) { return fallback2 }
            }

            if (key === 'duration' || key === 'delay') {

                const match = rawValue.match(/([0-9]+)ms/)

                if (match) { return match[ 1 ] }

            }

            if (key === 'origin') {

                if ([ 'top', 'right', 'left', 'center', 'bottom' ].includes(modifiers[ modifiers.indexOf(key) + 2 ])) {
                    return [ rawValue, modifiers[ modifiers.indexOf(key) + 2 ] ].join(' ')
                }

            }

            return rawValue

        }

        let isCloning = false

        function skipDuringClone(callback, fallback2 = () => {
        }) {
            return (...arguments_) => isCloning ? fallback2(...arguments_) : callback(...arguments_)
        }

        function onlyDuringClone(callback) {
            return (...arguments_) => isCloning && callback(...arguments_)
        }

        const interceptors = []

        function interceptClone(callback) {
            interceptors.push(callback)
        }

        function cloneNode(from, to) {

            for (const index of interceptors) { index(from, to) }

            isCloning = true

            dontRegisterReactiveSideEffects(() => {

                initTree(to, (element, callback) => {

                    callback(element, () => {
                    })

                })

            })

            isCloning = false

        }

        let isCloningLegacy = false

        function clone(oldElement, newElement) {

            if (!newElement._x_dataStack) { newElement._x_dataStack = oldElement._x_dataStack }

            isCloning = true
            isCloningLegacy = true

            dontRegisterReactiveSideEffects(() => {
                cloneTree(newElement)
            })

            isCloning = false
            isCloningLegacy = false

        }

        function cloneTree(element) {

            let hasRunThroughFirstElement = false

            const shallowWalker = (element2, callback) => {

                walk(element2, (element3, skip) => {

                    if (hasRunThroughFirstElement && isRoot(element3)) { return skip() }

                    hasRunThroughFirstElement = true
                    callback(element3, skip)

                })

            }

            initTree(element, shallowWalker)

        }

        function dontRegisterReactiveSideEffects(callback) {

            const cache = effect

            overrideEffect((callback2, element) => {

                const storedEffect = cache(callback2)

                release(storedEffect)

                return () => {
                }

            })

            callback()
            overrideEffect(cache)

        }

        function bind(element, name, value, modifiers = []) {

            if (!element._x_bindings) { element._x_bindings = reactive({}) }

            element._x_bindings[ name ] = value
            name = modifiers.includes('camel') ? camelCase(name) : name

            switch (name) {

            case 'value':
                bindInputValue(element, value)

                break

            case 'style':
                bindStyles(element, value)

                break

            case 'class':
                bindClasses(element, value)

                break

            case 'selected':

            case 'checked':
                bindAttributeAndProperty(element, name, value)

                break

            default:
                bindAttribute(element, name, value)

                break

            }

        }

        function bindInputValue(element, value) {

            if (element.type === 'radio') {

                if (element.attributes.value === void 0) {
                    element.value = value
                }

                if (window.fromModel) {

                    if (typeof value === 'boolean') {
                        element.checked = safeParseBoolean(element.value) === value
                    } else {
                        element.checked = checkedAttributeLooseCompare(element.value, value)
                    }

                }

            } else if (element.type === 'checkbox') {

                if (Number.isInteger(value)) {
                    element.value = value
                } else if (!Array.isArray(value) && typeof value !== 'boolean' && ![ null, void 0 ].includes(value)) {
                    element.value = String(value)
                } else {

                    if (Array.isArray(value)) {
                        element.checked = value.some(value_ => checkedAttributeLooseCompare(value_, element.value))
                    } else {
                        element.checked = !!value
                    }

                }

            } else if (element.tagName === 'SELECT') {
                updateSelect(element, value)
            } else {

                if (element.value === value) { return }

                element.value = value === void 0 ? '' : value

            }

        }

        function bindClasses(element, value) {

            if (element._x_undoAddedClasses) { element._x_undoAddedClasses() }

            element._x_undoAddedClasses = setClasses(element, value)

        }

        function bindStyles(element, value) {

            if (element._x_undoAddedStyles) { element._x_undoAddedStyles() }

            element._x_undoAddedStyles = setStyles(element, value)

        }

        function bindAttributeAndProperty(element, name, value) {

            bindAttribute(element, name, value)
            setPropertyIfChanged(element, name, value)

        }

        function bindAttribute(element, name, value) {

            if ([ null, void 0, false ].includes(value) && attributeShouldntBePreservedIfFalsy(name)) {
                element.removeAttribute(name)
            } else {

                if (isBooleanAttribute(name)) { value = name }

                setIfChanged(element, name, value)

            }

        }

        function setIfChanged(element, attributeName, value) {

            if (element.getAttribute(attributeName) != value) {
                element.setAttribute(attributeName, value)
            }

        }

        function setPropertyIfChanged(element, propertyName, value) {

            if (element[ propertyName ] !== value) {
                element[ propertyName ] = value
            }

        }

        function updateSelect(element, value) {

            const arrayWrappedValue = [].concat(value).map(value2 => `${ value2 }`)

            for (const option of Array.from(element.options)) {
                option.selected = arrayWrappedValue.includes(option.value)
            }

        }

        function camelCase(subject) {
            return subject.toLowerCase().replace(/-(\w)/g, (match, char) => char.toUpperCase())
        }

        function checkedAttributeLooseCompare(valueA, valueB) {
            return valueA == valueB
        }

        function safeParseBoolean(rawValue) {

            if ([ 1, '1', 'true', 'on', 'yes', true ].includes(rawValue)) {
                return true
            }

            if ([ 0, '0', 'false', 'off', 'no', false ].includes(rawValue)) {
                return false
            }

            return rawValue ? Boolean(rawValue) : null

        }

        function isBooleanAttribute(attributeName) {

            const booleanAttributes = [
                'disabled',
                'checked',
                'required',
                'readonly',
                'open',
                'selected',
                'autofocus',
                'itemscope',
                'multiple',
                'novalidate',
                'allowfullscreen',
                'allowpaymentrequest',
                'formnovalidate',
                'autoplay',
                'controls',
                'loop',
                'muted',
                'playsinline',
                'default',
                'ismap',
                'reversed',
                'async',
                'defer',
                'nomodule',
            ]

            return booleanAttributes.includes(attributeName)

        }

        function attributeShouldntBePreservedIfFalsy(name) {
            return ![ 'aria-pressed', 'aria-checked', 'aria-expanded', 'aria-selected' ].includes(name)
        }

        function getBinding(element, name, fallback2) {

            if (element._x_bindings && element._x_bindings[ name ] !== void 0) { return element._x_bindings[ name ] }

            return getAttributeBinding(element, name, fallback2)

        }

        function extractProperty(element, name, fallback2, extract = true) {

            if (element._x_bindings && element._x_bindings[ name ] !== void 0) { return element._x_bindings[ name ] }

            if (element._x_inlineBindings && element._x_inlineBindings[ name ] !== void 0) {

                const binding = element._x_inlineBindings[ name ]

                binding.extract = extract

                return dontAutoEvaluateFunctions(() => evaluate(element, binding.expression))

            }

            return getAttributeBinding(element, name, fallback2)

        }

        function getAttributeBinding(element, name, fallback2) {

            const attribute = element.getAttribute(name)

            if (attribute === null) { return typeof fallback2 === 'function' ? fallback2() : fallback2 }

            if (attribute === '') { return true }

            if (isBooleanAttribute(name)) {
                return !![ name, 'true' ].includes(attribute)
            }

            return attribute

        }

        function debounce2(function_, wait) {

            let timeout

            return function() {

                const context = this, arguments_ = arguments

                const later = function() {

                    timeout = null
                    function_.apply(context, arguments_)

                }

                clearTimeout(timeout)
                timeout = setTimeout(later, wait)

            }

        }

        function throttle(function_, limit) {

            let inThrottle

            return function() {

                const context = this, arguments_ = arguments

                if (!inThrottle) {

                    function_.apply(context, arguments_)
                    inThrottle = true
                    setTimeout(() => inThrottle = false, limit)

                }

            }

        }

        function entangle({ get: outerGet, set: outerSet }, { get: innerGet, set: innerSet }) {

            let firstRun = true
            let outerHash
            let innerHash

            const reference = effect(() => {

                const outer = outerGet()
                const inner = innerGet()

                if (firstRun) {

                    innerSet(cloneIfObject2(outer))
                    firstRun = false

                } else {

                    const outerHashLatest = JSON.stringify(outer)
                    const innerHashLatest = JSON.stringify(inner)

                    if (outerHashLatest !== outerHash) {
                        innerSet(cloneIfObject2(outer))
                    } else if (outerHashLatest !== innerHashLatest) {
                        outerSet(cloneIfObject2(inner))
                    } else {
                    }

                }

                outerHash = JSON.stringify(outerGet())
                innerHash = JSON.stringify(innerGet())

            })

            return () => {
                release(reference)
            }

        }

        function cloneIfObject2(value) {
            return typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value
        }

        function plugin(callback) {

            const callbacks = Array.isArray(callback) ? callback : [ callback ]

            for (const index of callbacks) { index(alpine_default) }

        }

        let stores = {}
        let isReactive = false

        function store(name, value) {

            if (!isReactive) {

                stores = reactive(stores)
                isReactive = true

            }

            if (value === void 0) {
                return stores[ name ]
            }

            stores[ name ] = value

            if (typeof value === 'object' && value !== null && value.hasOwnProperty('init') && typeof value.init === 'function') {
                stores[ name ].init()
            }

            initInterceptors(stores[ name ])

        }

        function getStores() {
            return stores
        }

        const binds = {}

        function bind2(name, bindings) {

            const getBindings = typeof bindings !== 'function' ? () => bindings : bindings

            if (name instanceof Element) {
                return applyBindingsObject(name, getBindings())
            }

            binds[ name ] = getBindings

            return () => {
            }

        }

        function injectBindingProviders(object) {

            for (const [ name, callback ] of Object.entries(binds)) {

                Object.defineProperty(object, name, {
                    get() {
                        return (...arguments_) => callback(...arguments_)
                    },
                })

            }

            return object

        }

        function applyBindingsObject(element, object, original) {

            const cleanupRunners = []

            while (cleanupRunners.length) { cleanupRunners.pop()() }

            let attributes = Object.entries(object).map(([ name, value ]) => ({ name, value }))

            const staticAttributes = attributesOnly(attributes)

            attributes = attributes.map(attribute => {

                if (staticAttributes.find(attribute_ => attribute_.name === attribute.name)) {

                    return {
                        name: `x-bind:${ attribute.name }`,
                        value: `"${ attribute.value }"`,
                    }

                }

                return attribute

            })

            directives(element, attributes, original).map(handle => {

                cleanupRunners.push(handle.runCleanups)
                handle()

            })

            return () => {
                while (cleanupRunners.length) { cleanupRunners.pop()() }
            }

        }

        const datas = {}

        function data(name, callback) {
            datas[ name ] = callback
        }

        function injectDataProviders(object, context) {

            for (const [ name, callback ] of Object.entries(datas)) {

                Object.defineProperty(object, name, {
                    get() {
                        return (...arguments_) => callback.bind(context)(...arguments_)
                    },
                    enumerable: false,
                })

            }

            return object

        }

        const Alpine19 = {
            get reactive() {
                return reactive
            },
            get release() {
                return release
            },
            get effect() {
                return effect
            },
            get raw() {
                return raw
            },
            version: '3.14.1',
            flushAndStopDeferringMutations,
            dontAutoEvaluateFunctions,
            disableEffectScheduling,
            startObservingMutations,
            stopObservingMutations,
            setReactivityEngine,
            onAttributeRemoved,
            onAttributesAdded,
            closestDataStack,
            skipDuringClone,
            onlyDuringClone,
            addRootSelector,
            addInitSelector,
            interceptClone,
            addScopeToNode,
            deferMutations,
            mapAttributes,
            evaluateLater,
            interceptInit,
            setEvaluator,
            mergeProxies,
            extractProp: extractProperty,
            findClosest,
            onElRemoved: onElementRemoved,
            closestRoot,
            destroyTree,
            interceptor,
            transition,
            setStyles,
            mutateDom,
            directive: directive2,
            entangle,
            throttle,
            debounce: debounce2,
            evaluate,
            initTree,
            nextTick,
            prefixed: prefix,
            prefix: setPrefix,
            plugin,
            magic,
            store,
            start: start2,
            clone,
            cloneNode,
            bound: getBinding,
            $data: scope,
            watch,
            walk,
            data,
            bind: bind2,
        }

        var alpine_default = Alpine19

        const import_reactivity10 = __toESM2(require_reactivity())

        magic('nextTick', () => nextTick)
        magic('dispatch', element => dispatch3.bind(dispatch3, element))

        magic('watch', (element, { evaluateLater: evaluateLater2, cleanup }) => (key, callback) => {

            const evaluate2 = evaluateLater2(key)

            const getter = () => {

                let value

                evaluate2(index => value = index)

                return value

            }

            const unwatch = watch(getter, callback)

            cleanup(unwatch)

        })

        magic('store', getStores)
        magic('data', element => scope(element))
        magic('root', element => closestRoot(element))

        magic('refs', element => {

            if (element._x_refs_proxy) { return element._x_refs_proxy }

            element._x_refs_proxy = mergeProxies(getArrayOfReferenceObject(element))

            return element._x_refs_proxy

        })

        function getArrayOfReferenceObject(element) {

            const referenceObjects = []

            findClosest(element, index => {
                if (index._x_refs) { referenceObjects.push(index._x_refs) }
            })

            return referenceObjects

        }

        const globalIdMemo = {}

        function findAndIncrementId(name) {

            if (!globalIdMemo[ name ]) { globalIdMemo[ name ] = 0 }

            return ++globalIdMemo[ name ]

        }

        function closestIdRoot(element, name) {

            return findClosest(element, element => {
                if (element._x_ids && element._x_ids[ name ]) { return true }
            })

        }

        function setIdRoot(element, name) {

            if (!element._x_ids) { element._x_ids = {} }

            if (!element._x_ids[ name ]) { element._x_ids[ name ] = findAndIncrementId(name) }

        }

        magic('id', (element, { cleanup }) => (name, key = null) => {

            const cacheKey = `${ name }${ key ? `-${ key }` : '' }`

            return cacheIdByNameOnElement(element, cacheKey, cleanup, () => {

                const root = closestIdRoot(element, name)
                const id = root ? root._x_ids[ name ] : findAndIncrementId(name)

                return key ? `${ name }-${ id }-${ key }` : `${ name }-${ id }`

            })

        })

        interceptClone((from, to) => {

            if (from._x_id) {
                to._x_id = from._x_id
            }

        })

        function cacheIdByNameOnElement(element, cacheKey, cleanup, callback) {

            if (!element._x_id) { element._x_id = {} }

            if (element._x_id[ cacheKey ]) { return element._x_id[ cacheKey ] }

            const output = callback()

            element._x_id[ cacheKey ] = output

            cleanup(() => {
                delete element._x_id[ cacheKey ]
            })

            return output

        }

        magic('el', element => element)
        warnMissingPluginMagic('Focus', 'focus', 'focus')
        warnMissingPluginMagic('Persist', 'persist', 'persist')

        function warnMissingPluginMagic(name, magicName, slug) {
            magic(magicName, element => warn(`You can't use [$${ magicName }] without first installing the "${ name }" plugin here: https://alpinejs.dev/plugins/${ slug }`, element))
        }

        directive2('modelable', (element, { expression }, { effect: effect3, evaluateLater: evaluateLater2, cleanup }) => {

            const function_ = evaluateLater2(expression)

            const innerGet = () => {

                let result

                function_(index => result = index)

                return result

            }

            const evaluateInnerSet = evaluateLater2(`${ expression } = __placeholder`)

            const innerSet = value => evaluateInnerSet(() => {
            }, { scope: { __placeholder: value } })

            const initialValue = innerGet()

            innerSet(initialValue)

            queueMicrotask(() => {

                if (!element._x_model) { return }

                element._x_removeModelListeners.default()

                const outerGet = element._x_model.get
                const outerSet = element._x_model.set

                const releaseEntanglement = entangle({
                    get() {
                        return outerGet()
                    },
                    set(value) {
                        outerSet(value)
                    },
                }, {
                    get() {
                        return innerGet()
                    },
                    set(value) {
                        innerSet(value)
                    },
                })

                cleanup(releaseEntanglement)

            })

        })

        directive2('teleport', (element, { modifiers, expression }, { cleanup }) => {

            if (element.tagName.toLowerCase() !== 'template') { warn('x-teleport can only be used on a <template> tag', element) }

            const target = getTarget(expression)
            const clone2 = element.content.cloneNode(true).firstElementChild

            element._x_teleport = clone2
            clone2._x_teleportBack = element
            element.setAttribute('data-teleport-template', true)
            clone2.setAttribute('data-teleport-target', true)

            if (element._x_forwardEvents) {

                for (const eventName of element._x_forwardEvents) {

                    clone2.addEventListener(eventName, e => {

                        e.stopPropagation()
                        element.dispatchEvent(new e.constructor(e.type, e))

                    })

                }

            }

            addScopeToNode(clone2, {}, element)

            const placeInDom = (clone3, target2, modifiers2) => {

                if (modifiers2.includes('prepend')) {
                    target2.parentNode.insertBefore(clone3, target2)
                } else if (modifiers2.includes('append')) {
                    target2.parentNode.insertBefore(clone3, target2.nextSibling)
                } else {
                    target2.appendChild(clone3)
                }

            }

            mutateDom(() => {

                placeInDom(clone2, target, modifiers)

                skipDuringClone(() => {

                    initTree(clone2)
                    clone2._x_ignore = true

                })()

            })

            element._x_teleportPutBack = () => {

                const target2 = getTarget(expression)

                mutateDom(() => {
                    placeInDom(element._x_teleport, target2, modifiers)
                })

            }

            cleanup(() => clone2.remove())

        })

        const teleportContainerDuringClone = document.createElement('div')

        function getTarget(expression) {

            const target = skipDuringClone(() => document.querySelector(expression), () => teleportContainerDuringClone)()

            if (!target) { warn(`Cannot find x-teleport element for selector: "${ expression }"`) }

            return target

        }

        const handler = () => {
        }

        handler.inline = (element, { modifiers }, { cleanup }) => {

            modifiers.includes('self') ? element._x_ignoreSelf = true : element._x_ignore = true

            cleanup(() => {
                modifiers.includes('self') ? delete element._x_ignoreSelf : delete element._x_ignore
            })

        }

        directive2('ignore', handler)

        directive2('effect', skipDuringClone((element, { expression }, { effect: effect3 }) => {
            effect3(evaluateLater(element, expression))
        }))

        function on3(element, event, modifiers, callback) {

            let listenerTarget = element
            let handler4 = e => callback(e)

            const options = {}
            const wrapHandler = (callback2, wrapper) => e => wrapper(callback2, e)

            if (modifiers.includes('dot')) { event = dotSyntax(event) }

            if (modifiers.includes('camel')) { event = camelCase2(event) }

            if (modifiers.includes('passive')) { options.passive = true }

            if (modifiers.includes('capture')) { options.capture = true }

            if (modifiers.includes('window')) { listenerTarget = window }

            if (modifiers.includes('document')) { listenerTarget = document }

            if (modifiers.includes('debounce')) {

                const nextModifier = modifiers[ modifiers.indexOf('debounce') + 1 ] || 'invalid-wait'
                const wait = isNumeric(nextModifier.split('ms')[ 0 ]) ? Number(nextModifier.split('ms')[ 0 ]) : 250

                handler4 = debounce2(handler4, wait)

            }

            if (modifiers.includes('throttle')) {

                const nextModifier = modifiers[ modifiers.indexOf('throttle') + 1 ] || 'invalid-wait'
                const wait = isNumeric(nextModifier.split('ms')[ 0 ]) ? Number(nextModifier.split('ms')[ 0 ]) : 250

                handler4 = throttle(handler4, wait)

            }

            if (modifiers.includes('prevent')) {

                handler4 = wrapHandler(handler4, (next, e) => {

                    e.preventDefault()
                    next(e)

                })

            }

            if (modifiers.includes('stop')) {

                handler4 = wrapHandler(handler4, (next, e) => {

                    e.stopPropagation()
                    next(e)

                })

            }

            if (modifiers.includes('once')) {

                handler4 = wrapHandler(handler4, (next, e) => {

                    next(e)
                    listenerTarget.removeEventListener(event, handler4, options)

                })

            }

            if (modifiers.includes('away') || modifiers.includes('outside')) {

                listenerTarget = document

                handler4 = wrapHandler(handler4, (next, e) => {

                    if (element.contains(e.target)) { return }

                    if (e.target.isConnected === false) { return }

                    if (element.offsetWidth < 1 && element.offsetHeight < 1) { return }

                    if (element._x_isShown === false) { return }

                    next(e)

                })

            }

            if (modifiers.includes('self')) {

                handler4 = wrapHandler(handler4, (next, e) => {
                    e.target === element && next(e)
                })

            }

            if (isKeyEvent(event) || isClickEvent(event)) {

                handler4 = wrapHandler(handler4, (next, e) => {

                    if (isListeningForASpecificKeyThatHasntBeenPressed(e, modifiers)) {
                        return
                    }

                    next(e)

                })

            }

            listenerTarget.addEventListener(event, handler4, options)

            return () => {
                listenerTarget.removeEventListener(event, handler4, options)
            }

        }

        function dotSyntax(subject) {
            return subject.replace(/-/g, '.')
        }

        function camelCase2(subject) {
            return subject.toLowerCase().replace(/-(\w)/g, (match, char) => char.toUpperCase())
        }

        function isNumeric(subject) {
            return !Array.isArray(subject) && !isNaN(subject)
        }

        function kebabCase2(subject) {

            if ([ ' ', '_' ].includes(subject)) { return subject }

            return subject.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[_\s]/, '-').toLowerCase()

        }

        function isKeyEvent(event) {
            return [ 'keydown', 'keyup' ].includes(event)
        }

        function isClickEvent(event) {
            return [ 'contextmenu', 'click', 'mouse' ].some(index => event.includes(index))
        }

        function isListeningForASpecificKeyThatHasntBeenPressed(e, modifiers) {

            let keyModifiers = modifiers.filter(index => ![ 'window', 'document', 'prevent', 'stop', 'once', 'capture', 'self', 'away', 'outside', 'passive' ].includes(index))

            if (keyModifiers.includes('debounce')) {

                const debounceIndex = keyModifiers.indexOf('debounce')

                keyModifiers.splice(debounceIndex, isNumeric((keyModifiers[ debounceIndex + 1 ] || 'invalid-wait').split('ms')[ 0 ]) ? 2 : 1)

            }

            if (keyModifiers.includes('throttle')) {

                const debounceIndex = keyModifiers.indexOf('throttle')

                keyModifiers.splice(debounceIndex, isNumeric((keyModifiers[ debounceIndex + 1 ] || 'invalid-wait').split('ms')[ 0 ]) ? 2 : 1)

            }

            if (keyModifiers.length === 0) { return false }

            if (keyModifiers.length === 1 && keyToModifiers(e.key).includes(keyModifiers[ 0 ])) { return false }

            const systemKeyModifiers = [ 'ctrl', 'shift', 'alt', 'meta', 'cmd', 'super' ]
            const selectedSystemKeyModifiers = systemKeyModifiers.filter(modifier => keyModifiers.includes(modifier))

            keyModifiers = keyModifiers.filter(index => !selectedSystemKeyModifiers.includes(index))

            if (selectedSystemKeyModifiers.length > 0) {

                const activelyPressedKeyModifiers = selectedSystemKeyModifiers.filter(modifier => {

                    if (modifier === 'cmd' || modifier === 'super') { modifier = 'meta' }

                    return e[ `${ modifier }Key` ]

                })

                if (activelyPressedKeyModifiers.length === selectedSystemKeyModifiers.length) {

                    if (isClickEvent(e.type)) { return false }

                    if (keyToModifiers(e.key).includes(keyModifiers[ 0 ])) { return false }

                }

            }

            return true

        }

        function keyToModifiers(key) {

            if (!key) { return [] }

            key = kebabCase2(key)

            const modifierToKeyMap = {
                ctrl: 'control',
                slash: '/',
                space: ' ',
                spacebar: ' ',
                cmd: 'meta',
                esc: 'escape',
                up: 'arrow-up',
                down: 'arrow-down',
                left: 'arrow-left',
                right: 'arrow-right',
                period: '.',
                comma: ',',
                equal: '=',
                minus: '-',
                underscore: '_',
            }

            modifierToKeyMap[ key ] = key

            return Object.keys(modifierToKeyMap).map(modifier => {
                if (modifierToKeyMap[ modifier ] === key) { return modifier }
            }).filter(modifier => modifier)

        }

        directive2('model', (element, { modifiers, expression }, { effect: effect3, cleanup }) => {

            let scopeTarget = element

            if (modifiers.includes('parent')) {
                scopeTarget = element.parentNode
            }

            const evaluateGet = evaluateLater(scopeTarget, expression)

            let evaluateSet

            if (typeof expression === 'string') {
                evaluateSet = evaluateLater(scopeTarget, `${ expression } = __placeholder`)
            } else if (typeof expression === 'function' && typeof expression() === 'string') {
                evaluateSet = evaluateLater(scopeTarget, `${ expression() } = __placeholder`)
            } else {

                evaluateSet = () => {
                }

            }

            const getValue = () => {

                let result

                evaluateGet(value => result = value)

                return isGetterSetter(result) ? result.get() : result

            }

            const setValue = value => {

                let result

                evaluateGet(value2 => result = value2)

                if (isGetterSetter(result)) {
                    result.set(value)
                } else {

                    evaluateSet(() => {
                    }, {
                        scope: { __placeholder: value },
                    })

                }

            }

            if (typeof expression === 'string' && element.type === 'radio') {

                mutateDom(() => {
                    if (!element.hasAttribute('name')) { element.setAttribute('name', expression) }
                })

            }

            const event = element.tagName.toLowerCase() === 'select' || [ 'checkbox', 'radio' ].includes(element.type) || modifiers.includes('lazy') ? 'change' : 'input'

            const removeListener = isCloning
                ? () => {
                }
                : on3(element, event, modifiers, e => {
                    setValue(getInputValue(element, modifiers, e, getValue()))
                })

            if (modifiers.includes('fill')) {

                if ([ void 0, null, '' ].includes(getValue()) || element.type === 'checkbox' && Array.isArray(getValue()) || element.tagName.toLowerCase() === 'select' && element.multiple) {
                    setValue(getInputValue(element, modifiers, { target: element }, getValue()))
                }

            }

            if (!element._x_removeModelListeners) { element._x_removeModelListeners = {} }

            element._x_removeModelListeners.default = removeListener
            cleanup(() => element._x_removeModelListeners.default())

            if (element.form) {

                const removeResetListener = on3(element.form, 'reset', [], e => {
                    nextTick(() => element._x_model && element._x_model.set(getInputValue(element, modifiers, { target: element }, getValue())))
                })

                cleanup(() => removeResetListener())

            }

            element._x_model = {
                get() {
                    return getValue()
                },
                set(value) {
                    setValue(value)
                },
            }

            element._x_forceModelUpdate = value => {

                if (value === void 0 && typeof expression === 'string' && expression.match(/\./)) { value = '' }

                window.fromModel = true
                mutateDom(() => bind(element, 'value', value))
                delete window.fromModel

            }

            effect3(() => {

                const value = getValue()

                if (modifiers.includes('unintrusive') && document.activeElement.isSameNode(element)) { return }

                element._x_forceModelUpdate(value)

            })

        })

        function getInputValue(element, modifiers, event, currentValue) {

            return mutateDom(() => {

                if (event instanceof CustomEvent && event.detail !== void 0) { return event.detail !== null && event.detail !== void 0 ? event.detail : event.target.value }

                if (element.type === 'checkbox') {

                    if (Array.isArray(currentValue)) {

                        let newValue = null

                        if (modifiers.includes('number')) {
                            newValue = safeParseNumber(event.target.value)
                        } else if (modifiers.includes('boolean')) {
                            newValue = safeParseBoolean(event.target.value)
                        } else {
                            newValue = event.target.value
                        }

                        return event.target.checked ? (currentValue.includes(newValue) ? currentValue : currentValue.concat([ newValue ])) : currentValue.filter(element2 => !checkedAttributeLooseCompare2(element2, newValue))

                    }

                    return event.target.checked

                }

                if (element.tagName.toLowerCase() === 'select' && element.multiple) {

                    if (modifiers.includes('number')) {

                        return Array.from(event.target.selectedOptions).map(option => {

                            const rawValue = option.value || option.text

                            return safeParseNumber(rawValue)

                        })

                    }

                    if (modifiers.includes('boolean')) {

                        return Array.from(event.target.selectedOptions).map(option => {

                            const rawValue = option.value || option.text

                            return safeParseBoolean(rawValue)

                        })

                    }

                    return Array.from(event.target.selectedOptions).map(option => option.value || option.text)

                }

                let newValue

                if (element.type === 'radio') {

                    if (event.target.checked) {
                        newValue = event.target.value
                    } else {
                        newValue = currentValue
                    }

                } else {
                    newValue = event.target.value
                }

                if (modifiers.includes('number')) {
                    return safeParseNumber(newValue)
                }

                if (modifiers.includes('boolean')) {
                    return safeParseBoolean(newValue)
                }

                if (modifiers.includes('trim')) {
                    return newValue.trim()
                }

                return newValue

            })

        }

        function safeParseNumber(rawValue) {

            const number = rawValue ? parseFloat(rawValue) : null

            return isNumeric2(number) ? number : rawValue

        }

        function checkedAttributeLooseCompare2(valueA, valueB) {
            return valueA == valueB
        }

        function isNumeric2(subject) {
            return !Array.isArray(subject) && !isNaN(subject)
        }

        function isGetterSetter(value) {
            return value !== null && typeof value === 'object' && typeof value.get === 'function' && typeof value.set === 'function'
        }

        directive2('cloak', element => queueMicrotask(() => mutateDom(() => element.removeAttribute(prefix('cloak')))))
        addInitSelector(() => `[${ prefix('init') }]`)

        directive2('init', skipDuringClone((element, { expression }, { evaluate: evaluate2 }) => {

            if (typeof expression === 'string') {
                return !!expression.trim() && evaluate2(expression, {}, false)
            }

            return evaluate2(expression, {}, false)

        }))

        directive2('text', (element, { expression }, { effect: effect3, evaluateLater: evaluateLater2 }) => {

            const evaluate2 = evaluateLater2(expression)

            effect3(() => {

                evaluate2(value => {

                    mutateDom(() => {
                        element.textContent = value
                    })

                })

            })

        })

        directive2('html', (element, { expression }, { effect: effect3, evaluateLater: evaluateLater2 }) => {

            const evaluate2 = evaluateLater2(expression)

            effect3(() => {

                evaluate2(value => {

                    mutateDom(() => {

                        element.innerHTML = value
                        element._x_ignoreSelf = true
                        initTree(element)
                        delete element._x_ignoreSelf

                    })

                })

            })

        })

        mapAttributes(startingWith(':', into(prefix('bind:'))))

        const handler2 = (element, { value, modifiers, expression, original }, { effect: effect3, cleanup }) => {

            if (!value) {

                const bindingProviders = {}

                injectBindingProviders(bindingProviders)

                const getBindings = evaluateLater(element, expression)

                getBindings(bindings => {
                    applyBindingsObject(element, bindings, original)
                }, { scope: bindingProviders })

                return

            }

            if (value === 'key') { return storeKeyForXFor(element, expression) }

            if (element._x_inlineBindings && element._x_inlineBindings[ value ] && element._x_inlineBindings[ value ].extract) {
                return
            }

            const evaluate2 = evaluateLater(element, expression)

            effect3(() => evaluate2(result => {

                if (result === void 0 && typeof expression === 'string' && expression.match(/\./)) {
                    result = ''
                }

                mutateDom(() => bind(element, value, result, modifiers))

            }))

            cleanup(() => {

                element._x_undoAddedClasses && element._x_undoAddedClasses()
                element._x_undoAddedStyles && element._x_undoAddedStyles()

            })

        }

        handler2.inline = (element, { value, modifiers, expression }) => {

            if (!value) { return }

            if (!element._x_inlineBindings) { element._x_inlineBindings = {} }

            element._x_inlineBindings[ value ] = { expression, extract: false }

        }

        directive2('bind', handler2)

        function storeKeyForXFor(element, expression) {
            element._x_keyExpression = expression
        }

        addRootSelector(() => `[${ prefix('data') }]`)

        directive2('data', (element, { expression }, { cleanup }) => {

            if (shouldSkipRegisteringDataDuringClone(element)) { return }

            expression = expression === '' ? '{}' : expression

            const magicContext = {}

            injectMagics(magicContext, element)

            const dataProviderContext = {}

            injectDataProviders(dataProviderContext, magicContext)

            let data2 = evaluate(element, expression, { scope: dataProviderContext })

            if (data2 === void 0 || data2 === true) { data2 = {} }

            injectMagics(data2, element)

            const reactiveData = reactive(data2)

            initInterceptors(reactiveData)

            const undo = addScopeToNode(element, reactiveData)

            reactiveData.init && evaluate(element, reactiveData.init)

            cleanup(() => {

                reactiveData.destroy && evaluate(element, reactiveData.destroy)
                undo()

            })

        })

        interceptClone((from, to) => {

            if (from._x_dataStack) {

                to._x_dataStack = from._x_dataStack
                to.setAttribute('data-has-alpine-state', true)

            }

        })

        function shouldSkipRegisteringDataDuringClone(element) {

            if (!isCloning) { return false }

            if (isCloningLegacy) { return true }

            return element.hasAttribute('data-has-alpine-state')

        }

        directive2('show', (element, { modifiers, expression }, { effect: effect3 }) => {

            const evaluate2 = evaluateLater(element, expression)

            if (!element._x_doHide) {

                element._x_doHide = () => {

                    mutateDom(() => {
                        element.style.setProperty('display', 'none', modifiers.includes('important') ? 'important' : void 0)
                    })

                }

            }

            if (!element._x_doShow) {

                element._x_doShow = () => {

                    mutateDom(() => {

                        if (element.style.length === 1 && element.style.display === 'none') {
                            element.removeAttribute('style')
                        } else {
                            element.style.removeProperty('display')
                        }

                    })

                }

            }

            const hide = () => {

                element._x_doHide()
                element._x_isShown = false

            }

            const show = () => {

                element._x_doShow()
                element._x_isShown = true

            }

            const clickAwayCompatibleShow = () => setTimeout(show)

            const toggle = once(value => value ? show() : hide(), value => {

                if (typeof element._x_toggleAndCascadeWithTransitions === 'function') {
                    element._x_toggleAndCascadeWithTransitions(element, value, show, hide)
                } else {
                    value ? clickAwayCompatibleShow() : hide()
                }

            })

            let oldValue
            let firstTime = true

            effect3(() => evaluate2(value => {

                if (!firstTime && value === oldValue) { return }

                if (modifiers.includes('immediate')) { value ? clickAwayCompatibleShow() : hide() }

                toggle(value)
                oldValue = value
                firstTime = false

            }))

        })

        directive2('for', (element, { expression }, { effect: effect3, cleanup }) => {

            const iteratorNames = parseForExpression(expression)
            const evaluateItems = evaluateLater(element, iteratorNames.items)
            const evaluateKey = evaluateLater(element, element._x_keyExpression || 'index')

            element._x_prevKeys = []
            element._x_lookup = {}
            effect3(() => loop(element, iteratorNames, evaluateItems, evaluateKey))

            cleanup(() => {

                for (const element2 of Object.values(element._x_lookup)) { element2.remove() }

                delete element._x_prevKeys
                delete element._x_lookup

            })

        })

        function loop(element, iteratorNames, evaluateItems, evaluateKey) {

            const isObject2 = index => typeof index === 'object' && !Array.isArray(index)
            const templateElement = element

            evaluateItems(items => {

                if (isNumeric3(items) && items >= 0) {
                    items = Array.from(Array(items).keys(), index => index + 1)
                }

                if (items === void 0) { items = [] }

                const lookup = element._x_lookup

                let previousKeys = element._x_prevKeys

                const scopes = []
                const keys = []

                if (isObject2(items)) {

                    items = Object.entries(items).map(([ key, value ]) => {

                        const scope2 = getIterationScopeVariables(iteratorNames, value, key, items)

                        evaluateKey(value2 => {

                            if (keys.includes(value2)) { warn('Duplicate key on x-for', element) }

                            keys.push(value2)

                        }, { scope: { index: key, ...scope2 } })

                        scopes.push(scope2)

                    })

                } else {

                    for (let index = 0; index < items.length; index++) {

                        const scope2 = getIterationScopeVariables(iteratorNames, items[ index ], index, items)

                        evaluateKey(value => {

                            if (keys.includes(value)) { warn('Duplicate key on x-for', element) }

                            keys.push(value)

                        }, { scope: { index, ...scope2 } })

                        scopes.push(scope2)

                    }

                }

                const adds = []
                const moves = []
                const removes = []
                const sames = []

                for (let index = 0; index < previousKeys.length; index++) {

                    const key = previousKeys[ index ]

                    if (keys.indexOf(key) === -1) { removes.push(key) }

                }

                previousKeys = previousKeys.filter(key => !removes.includes(key))

                let lastKey = 'template'

                for (let index = 0; index < keys.length; index++) {

                    const key = keys[ index ]
                    const previousIndex = previousKeys.indexOf(key)

                    if (previousIndex === -1) {

                        previousKeys.splice(index, 0, key)
                        adds.push([ lastKey, index ])

                    } else if (previousIndex !== index) {

                        const keyInSpot = previousKeys.splice(index, 1)[ 0 ]
                        const keyForSpot = previousKeys.splice(previousIndex - 1, 1)[ 0 ]

                        previousKeys.splice(index, 0, keyForSpot)
                        previousKeys.splice(previousIndex, 0, keyInSpot)
                        moves.push([ keyInSpot, keyForSpot ])

                    } else {
                        sames.push(key)
                    }

                    lastKey = key

                }

                for (let index = 0; index < removes.length; index++) {

                    const key = removes[ index ]

                    if (lookup[ key ]._x_effects) {
                        lookup[ key ]._x_effects.forEach(dequeueJob)
                    }

                    lookup[ key ].remove()
                    lookup[ key ] = null
                    delete lookup[ key ]

                }

                for (let index = 0; index < moves.length; index++) {

                    const [ keyInSpot, keyForSpot ] = moves[ index ]
                    const elementInSpot = lookup[ keyInSpot ]
                    const elementForSpot = lookup[ keyForSpot ]
                    const marker = document.createElement('div')

                    mutateDom(() => {

                        if (!elementForSpot) { warn('x-for ":key" is undefined or invalid', templateElement, keyForSpot, lookup) }

                        elementForSpot.after(marker)
                        elementInSpot.after(elementForSpot)
                        elementForSpot._x_currentIfEl && elementForSpot.after(elementForSpot._x_currentIfEl)
                        marker.before(elementInSpot)
                        elementInSpot._x_currentIfEl && elementInSpot.after(elementInSpot._x_currentIfEl)
                        marker.remove()

                    })

                    elementForSpot._x_refreshXForScope(scopes[ keys.indexOf(keyForSpot) ])

                }

                for (let index_ = 0; index_ < adds.length; index_++) {

                    const [ lastKey2, index ] = adds[ index_ ]

                    let lastElement = lastKey2 === 'template' ? templateElement : lookup[ lastKey2 ]

                    if (lastElement._x_currentIfEl) { lastElement = lastElement._x_currentIfEl }

                    const scope2 = scopes[ index ]
                    const key = keys[ index ]
                    const clone2 = document.importNode(templateElement.content, true).firstElementChild
                    const reactiveScope = reactive(scope2)

                    addScopeToNode(clone2, reactiveScope, templateElement)

                    clone2._x_refreshXForScope = newScope => {

                        for (const [ key2, value ] of Object.entries(newScope)) {
                            reactiveScope[ key2 ] = value
                        }

                    }

                    mutateDom(() => {

                        lastElement.after(clone2)
                        skipDuringClone(() => initTree(clone2))()

                    })

                    if (typeof key === 'object') {
                        warn('x-for key cannot be an object, it must be a string or an integer', templateElement)
                    }

                    lookup[ key ] = clone2

                }

                for (let index = 0; index < sames.length; index++) {
                    lookup[ sames[ index ] ]._x_refreshXForScope(scopes[ keys.indexOf(sames[ index ]) ])
                }

                templateElement._x_prevKeys = keys

            })

        }

        function parseForExpression(expression) {

            const forIteratorRE = /,([^,\}\]]*)(?:,([^,\}\]]*))?$/
            const stripParensRE = /^\s*\(|\)\s*$/g
            const forAliasRE = /([\s\S]*?)\s+(?:in|of)\s+([\s\S]*)/
            const inMatch = expression.match(forAliasRE)

            if (!inMatch) { return }

            const res = {}

            res.items = inMatch[ 2 ].trim()

            const item = inMatch[ 1 ].replace(stripParensRE, '').trim()
            const iteratorMatch = item.match(forIteratorRE)

            if (iteratorMatch) {

                res.item = item.replace(forIteratorRE, '').trim()
                res.index = iteratorMatch[ 1 ].trim()

                if (iteratorMatch[ 2 ]) {
                    res.collection = iteratorMatch[ 2 ].trim()
                }

            } else {
                res.item = item
            }

            return res

        }

        function getIterationScopeVariables(iteratorNames, item, index, items) {

            const scopeVariables = {}

            if (/^\[.*\]$/.test(iteratorNames.item) && Array.isArray(item)) {

                const names = iteratorNames.item.replace('[', '').replace(']', '').split(',').map(index_ => index_.trim())

                for (const [ index_, name ] of names.entries()) {
                    scopeVariables[ name ] = item[ index_ ]
                }

            } else if (/^\{.*\}$/.test(iteratorNames.item) && !Array.isArray(item) && typeof item === 'object') {

                const names = iteratorNames.item.replace('{', '').replace('}', '').split(',').map(index_ => index_.trim())

                for (const name of names) {
                    scopeVariables[ name ] = item[ name ]
                }

            } else {
                scopeVariables[ iteratorNames.item ] = item
            }

            if (iteratorNames.index) { scopeVariables[ iteratorNames.index ] = index }

            if (iteratorNames.collection) { scopeVariables[ iteratorNames.collection ] = items }

            return scopeVariables

        }

        function isNumeric3(subject) {
            return !Array.isArray(subject) && !isNaN(subject)
        }

        function handler3() {
        }

        handler3.inline = (element, { expression }, { cleanup }) => {

            const root = closestRoot(element)

            if (!root._x_refs) { root._x_refs = {} }

            root._x_refs[ expression ] = element
            cleanup(() => delete root._x_refs[ expression ])

        }

        directive2('ref', handler3)

        directive2('if', (element, { expression }, { effect: effect3, cleanup }) => {

            if (element.tagName.toLowerCase() !== 'template') { warn('x-if can only be used on a <template> tag', element) }

            const evaluate2 = evaluateLater(element, expression)

            const show = () => {

                if (element._x_currentIfEl) { return element._x_currentIfEl }

                const clone2 = element.content.cloneNode(true).firstElementChild

                addScopeToNode(clone2, {}, element)

                mutateDom(() => {

                    element.after(clone2)
                    skipDuringClone(() => initTree(clone2))()

                })

                element._x_currentIfEl = clone2

                element._x_undoIf = () => {

                    walk(clone2, node => {

                        if (node._x_effects) {
                            node._x_effects.forEach(dequeueJob)
                        }

                    })

                    clone2.remove()
                    delete element._x_currentIfEl

                }

                return clone2

            }

            const hide = () => {

                if (!element._x_undoIf) { return }

                element._x_undoIf()
                delete element._x_undoIf

            }

            effect3(() => evaluate2(value => {
                value ? show() : hide()
            }))

            cleanup(() => element._x_undoIf && element._x_undoIf())

        })

        directive2('id', (element, { expression }, { evaluate: evaluate2 }) => {

            const names = evaluate2(expression)

            for (const name of names) { setIdRoot(element, name) }

        })

        interceptClone((from, to) => {

            if (from._x_ids) {
                to._x_ids = from._x_ids
            }

        })

        mapAttributes(startingWith('@', into(prefix('on:'))))

        directive2('on', skipDuringClone((element, { value, modifiers, expression }, { cleanup }) => {

            const evaluate2 = expression
                ? evaluateLater(element, expression)
                : () => {
                }

            if (element.tagName.toLowerCase() === 'template') {

                if (!element._x_forwardEvents) { element._x_forwardEvents = [] }

                if (!element._x_forwardEvents.includes(value)) { element._x_forwardEvents.push(value) }

            }

            const removeListener = on3(element, value, modifiers, e => {

                evaluate2(() => {
                }, { scope: { $event: e }, params: [ e ] })

            })

            cleanup(() => removeListener())

        }))

        warnMissingPluginDirective('Collapse', 'collapse', 'collapse')
        warnMissingPluginDirective('Intersect', 'intersect', 'intersect')
        warnMissingPluginDirective('Focus', 'trap', 'focus')
        warnMissingPluginDirective('Mask', 'mask', 'mask')

        function warnMissingPluginDirective(name, directiveName, slug) {
            directive2(directiveName, element => warn(`You can't use [x-${ directiveName }] without first installing the "${ name }" plugin here: https://alpinejs.dev/plugins/${ slug }`, element))
        }

        alpine_default.setEvaluator(normalEvaluator)
        alpine_default.setReactivityEngine({ reactive: import_reactivity10.reactive, effect: import_reactivity10.effect, release: import_reactivity10.stop, raw: import_reactivity10.toRaw })

        var source_default = alpine_default

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/collapse/dist/module.cjs.js
const require_module_cjs2 = __commonJS({
    'node_modules/@alpinejs/collapse/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            collapse: () => source_default,
            default: () => module_default,
        })

        module.exports = __toCommonJS(module_exports)

        function source_default(Alpine19) {

            Alpine19.directive('collapse', collapse3)

            collapse3.inline = (element, { modifiers }) => {

                if (!modifiers.includes('min')) { return }

                element._x_doShow = () => {
                }

                element._x_doHide = () => {
                }

            }

            function collapse3(element, { modifiers }) {

                const duration = modifierValue(modifiers, 'duration', 250) / 1e3
                const floor = modifierValue(modifiers, 'min', 0)
                const fullyHide = !modifiers.includes('min')

                if (!element._x_isShown) { element.style.height = `${ floor }px` }

                if (!element._x_isShown && fullyHide) { element.hidden = true }

                if (!element._x_isShown) { element.style.overflow = 'hidden' }

                const setFunction = (element2, styles) => {

                    const revertFunction = Alpine19.setStyles(element2, styles)

                    return styles.height
                        ? () => {
                        }
                        : revertFunction

                }

                const transitionStyles = {
                    transitionProperty: 'height',
                    transitionDuration: `${ duration }s`,
                    transitionTimingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
                }

                element._x_transition = {
                    in(before = () => {
                    }, after = () => {
                    }) {

                        if (fullyHide) { element.hidden = false }

                        if (fullyHide) { element.style.display = null }

                        let current = element.getBoundingClientRect().height

                        element.style.height = 'auto'

                        const full = element.getBoundingClientRect().height

                        if (current === full) {
                            current = floor
                        }

                        Alpine19.transition(element, Alpine19.setStyles, {
                            during: transitionStyles,
                            start: { height: `${ current }px` },
                            end: { height: `${ full }px` },
                        }, () => element._x_isShown = true, () => {

                            if (Math.abs(element.getBoundingClientRect().height - full) < 1) {
                                element.style.overflow = null
                            }

                        })

                    },
                    out(before = () => {
                    }, after = () => {
                    }) {

                        const full = element.getBoundingClientRect().height

                        Alpine19.transition(element, setFunction, {
                            during: transitionStyles,
                            start: { height: `${ full }px` },
                            end: { height: `${ floor }px` },
                        }, () => element.style.overflow = 'hidden', () => {

                            element._x_isShown = false

                            if (element.style.height == `${ floor }px` && fullyHide) {

                                element.style.display = 'none'
                                element.hidden = true

                            }

                        })

                    },
                }

            }

        }

        function modifierValue(modifiers, key, fallback2) {

            if (modifiers.indexOf(key) === -1) { return fallback2 }

            const rawValue = modifiers[ modifiers.indexOf(key) + 1 ]

            if (!rawValue) { return fallback2 }

            if (key === 'duration') {

                const match = rawValue.match(/([0-9]+)ms/)

                if (match) { return match[ 1 ] }

            }

            if (key === 'min') {

                const match = rawValue.match(/([0-9]+)px/)

                if (match) { return match[ 1 ] }

            }

            return rawValue

        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/focus/dist/module.cjs.js
const require_module_cjs3 = __commonJS({
    'node_modules/@alpinejs/focus/dist/module.cjs.js'(exports, module) {

        const __create2 = Object.create
        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __getProtoOf2 = Object.getPrototypeOf
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __commonJS2 = (callback, module_) => function __require() {
            return module_ || (0, callback[ __getOwnPropertyNames2(callback)[ 0 ] ])((module_ = { exports: {} }).exports, module_), module_.exports
        }

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toESM2 = (module_, isNodeMode, target) => (target = module_ != null ? __create2(__getProtoOf2(module_)) : {}, __copyProperties2(isNodeMode || !module_ || !module_.__esModule ? __defProp2(target, 'default', { value: module_, enumerable: true }) : target, module_))
        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)

        const require_dist = __commonJS2({
            'node_modules/tabbable/dist/index.js'(exports2) {

                'use strict'

                Object.defineProperty(exports2, '__esModule', { value: true })

                const candidateSelectors = [ 'input', 'select', 'textarea', 'a[href]', 'button', '[tabindex]:not(slot)', 'audio[controls]', 'video[controls]', '[contenteditable]:not([contenteditable="false"])', 'details>summary:first-of-type', 'details' ]
                const candidateSelector = /* @__PURE__ */ candidateSelectors.join(',')
                const NoElement = typeof Element === 'undefined'

                const matches = NoElement
                    ? function() {
                    }
                    : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector

                const getRootNode = !NoElement && Element.prototype.getRootNode
                    ? function(element) {
                        return element.getRootNode()
                    }
                    : function(element) {
                        return element.ownerDocument
                    }

                const getCandidates = function getCandidates2(element, includeContainer, filter) {

                    let candidates = Array.prototype.slice.apply(element.querySelectorAll(candidateSelector))

                    if (includeContainer && matches.call(element, candidateSelector)) {
                        candidates.unshift(element)
                    }

                    candidates = candidates.filter(filter)

                    return candidates

                }

                const getCandidatesIteratively = function getCandidatesIteratively2(elements, includeContainer, options) {

                    const candidates = []
                    const elementsToCheck = Array.from(elements)

                    while (elementsToCheck.length) {

                        const element = elementsToCheck.shift()

                        if (element.tagName === 'SLOT') {

                            const assigned = element.assignedElements()
                            const content = assigned.length ? assigned : element.children
                            const nestedCandidates = getCandidatesIteratively2(content, true, options)

                            if (options.flatten) {
                                candidates.push.apply(candidates, nestedCandidates)
                            } else {

                                candidates.push({
                                    scope: element,
                                    candidates: nestedCandidates,
                                })

                            }

                        } else {

                            const validCandidate = matches.call(element, candidateSelector)

                            if (validCandidate && options.filter(element) && (includeContainer || !elements.includes(element))) {
                                candidates.push(element)
                            }

                            const shadowRoot = element.shadowRoot || typeof options.getShadowRoot === 'function' && options.getShadowRoot(element)
                            const validShadowRoot = !options.shadowRootFilter || options.shadowRootFilter(element)

                            if (shadowRoot && validShadowRoot) {

                                const _nestedCandidates = getCandidatesIteratively2(shadowRoot === true ? element.children : shadowRoot.children, true, options)

                                if (options.flatten) {
                                    candidates.push.apply(candidates, _nestedCandidates)
                                } else {

                                    candidates.push({
                                        scope: element,
                                        candidates: _nestedCandidates,
                                    })

                                }

                            } else {
                                elementsToCheck.unshift.apply(elementsToCheck, element.children)
                            }

                        }

                    }

                    return candidates

                }

                const getTabindex = function getTabindex2(node, isScope) {

                    if (node.tabIndex < 0) {

                        if ((isScope || /^(AUDIO|VIDEO|DETAILS)$/.test(node.tagName) || node.isContentEditable) && isNaN(parseInt(node.getAttribute('tabindex'), 10))) {
                            return 0
                        }

                    }

                    return node.tabIndex

                }

                const sortOrderedTabbables = function sortOrderedTabbables2(a, b) {
                    return a.tabIndex === b.tabIndex ? a.documentOrder - b.documentOrder : a.tabIndex - b.tabIndex
                }

                const isInput = function isInput2(node) {
                    return node.tagName === 'INPUT'
                }

                const isHiddenInput = function isHiddenInput2(node) {
                    return isInput(node) && node.type === 'hidden'
                }

                const isDetailsWithSummary = function isDetailsWithSummary2(node) {

                    const r = node.tagName === 'DETAILS' && Array.prototype.slice.apply(node.children).some(function(child) {
                        return child.tagName === 'SUMMARY'
                    })

                    return r

                }

                const getCheckedRadio = function getCheckedRadio2(nodes, form) {

                    for (let index = 0; index < nodes.length; index++) {

                        if (nodes[ index ].checked && nodes[ index ].form === form) {
                            return nodes[ index ]
                        }

                    }

                }

                const isTabbableRadio = function isTabbableRadio2(node) {

                    if (!node.name) {
                        return true
                    }

                    const radioScope = node.form || getRootNode(node)

                    const queryRadios = function queryRadios2(name) {
                        return radioScope.querySelectorAll(`input[type="radio"][name="${ name }"]`)
                    }

                    let radioSet

                    if (typeof window !== 'undefined' && typeof window.CSS !== 'undefined' && typeof window.CSS.escape === 'function') {
                        radioSet = queryRadios(window.CSS.escape(node.name))
                    } else {

                        try {
                            radioSet = queryRadios(node.name)
                        } catch (error) {

                            console.error('Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s', error.message)

                            return false

                        }

                    }

                    const checked = getCheckedRadio(radioSet, node.form)

                    return !checked || checked === node

                }

                const isRadio = function isRadio2(node) {
                    return isInput(node) && node.type === 'radio'
                }

                const isNonTabbableRadio = function isNonTabbableRadio2(node) {
                    return isRadio(node) && !isTabbableRadio(node)
                }

                const isZeroArea = function isZeroArea2(node) {

                    const _node$getBoundingClie = node.getBoundingClientRect(), width = _node$getBoundingClie.width, height = _node$getBoundingClie.height

                    return width === 0 && height === 0

                }

                const isHidden = function isHidden2(node, _reference) {

                    const displayCheck = _reference.displayCheck, getShadowRoot = _reference.getShadowRoot

                    if (getComputedStyle(node).visibility === 'hidden') {
                        return true
                    }

                    const isDirectSummary = matches.call(node, 'details>summary:first-of-type')
                    const nodeUnderDetails = isDirectSummary ? node.parentElement : node

                    if (matches.call(nodeUnderDetails, 'details:not([open]) *')) {
                        return true
                    }

                    const nodeRootHost = getRootNode(node).host
                    const nodeIsAttached = (nodeRootHost === null || nodeRootHost === void 0 ? void 0 : nodeRootHost.ownerDocument.contains(nodeRootHost)) || node.ownerDocument.contains(node)

                    if (!displayCheck || displayCheck === 'full') {

                        if (typeof getShadowRoot === 'function') {

                            const originalNode = node

                            while (node) {

                                const parentElement = node.parentElement
                                const rootNode = getRootNode(node)

                                if (parentElement && !parentElement.shadowRoot && getShadowRoot(parentElement) === true) {
                                    return isZeroArea(node)
                                }

                                if (node.assignedSlot) {
                                    node = node.assignedSlot
                                } else if (!parentElement && rootNode !== node.ownerDocument) {
                                    node = rootNode.host
                                } else {
                                    node = parentElement
                                }

                            }

                            node = originalNode

                        }

                        if (nodeIsAttached) {
                            return !node.getClientRects().length
                        }

                    } else if (displayCheck === 'non-zero-area') {
                        return isZeroArea(node)
                    }

                    return false

                }

                const isDisabledFromFieldset = function isDisabledFromFieldset2(node) {

                    if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(node.tagName)) {

                        let parentNode = node.parentElement

                        while (parentNode) {

                            if (parentNode.tagName === 'FIELDSET' && parentNode.disabled) {

                                for (let index = 0; index < parentNode.children.length; index++) {

                                    const child = parentNode.children.item(index)

                                    if (child.tagName === 'LEGEND') {
                                        return matches.call(parentNode, 'fieldset[disabled] *') ? true : !child.contains(node)
                                    }

                                }

                                return true

                            }

                            parentNode = parentNode.parentElement

                        }

                    }

                    return false

                }

                const isNodeMatchingSelectorFocusable = function isNodeMatchingSelectorFocusable2(options, node) {

                    if (node.disabled || isHiddenInput(node) || isHidden(node, options) || isDetailsWithSummary(node) || isDisabledFromFieldset(node)) {
                        return false
                    }

                    return true

                }

                const isNodeMatchingSelectorTabbable = function isNodeMatchingSelectorTabbable2(options, node) {

                    if (isNonTabbableRadio(node) || getTabindex(node) < 0 || !isNodeMatchingSelectorFocusable(options, node)) {
                        return false
                    }

                    return true

                }

                const isValidShadowRootTabbable = function isValidShadowRootTabbable2(shadowHostNode) {

                    const tabIndex = parseInt(shadowHostNode.getAttribute('tabindex'), 10)

                    if (isNaN(tabIndex) || tabIndex >= 0) {
                        return true
                    }

                    return false

                }

                const sortByOrder = function sortByOrder2(candidates) {

                    const regularTabbables = []
                    const orderedTabbables = []

                    for (const [ index, item ] of candidates.entries()) {

                        const isScope = !!item.scope
                        const element = isScope ? item.scope : item
                        const candidateTabindex = getTabindex(element, isScope)
                        const elements = isScope ? sortByOrder2(item.candidates) : element

                        if (candidateTabindex === 0) {
                            isScope ? regularTabbables.push.apply(regularTabbables, elements) : regularTabbables.push(element)
                        } else {

                            orderedTabbables.push({
                                documentOrder: index,
                                tabIndex: candidateTabindex,
                                item,
                                isScope,
                                content: elements,
                            })

                        }

                    }

                    return orderedTabbables.sort(sortOrderedTabbables).reduce(function(accumulator, sortable) {

                        sortable.isScope ? accumulator.push.apply(accumulator, sortable.content) : accumulator.push(sortable.content)

                        return accumulator

                    }, []).concat(regularTabbables)

                }

                const tabbable = function tabbable2(element, options) {

                    options = options || {}

                    let candidates

                    if (options.getShadowRoot) {

                        candidates = getCandidatesIteratively([ element ], options.includeContainer, {
                            filter: isNodeMatchingSelectorTabbable.bind(null, options),
                            flatten: false,
                            getShadowRoot: options.getShadowRoot,
                            shadowRootFilter: isValidShadowRootTabbable,
                        })

                    } else {
                        candidates = getCandidates(element, options.includeContainer, isNodeMatchingSelectorTabbable.bind(null, options))
                    }

                    return sortByOrder(candidates)

                }

                const focusable2 = function focusable3(element, options) {

                    options = options || {}

                    let candidates

                    if (options.getShadowRoot) {

                        candidates = getCandidatesIteratively([ element ], options.includeContainer, {
                            filter: isNodeMatchingSelectorFocusable.bind(null, options),
                            flatten: true,
                            getShadowRoot: options.getShadowRoot,
                        })

                    } else {
                        candidates = getCandidates(element, options.includeContainer, isNodeMatchingSelectorFocusable.bind(null, options))
                    }

                    return candidates

                }

                const isTabbable = function isTabbable2(node, options) {

                    options = options || {}

                    if (!node) {
                        throw new Error('No node provided')
                    }

                    if (matches.call(node, candidateSelector) === false) {
                        return false
                    }

                    return isNodeMatchingSelectorTabbable(options, node)

                }

                const focusableCandidateSelector = /* @__PURE__ */ candidateSelectors.concat('iframe').join(',')

                const isFocusable2 = function isFocusable3(node, options) {

                    options = options || {}

                    if (!node) {
                        throw new Error('No node provided')
                    }

                    if (matches.call(node, focusableCandidateSelector) === false) {
                        return false
                    }

                    return isNodeMatchingSelectorFocusable(options, node)

                }

                exports2.focusable = focusable2
                exports2.isFocusable = isFocusable2
                exports2.isTabbable = isTabbable
                exports2.tabbable = tabbable

            },
        })

        const require_focus_trap = __commonJS2({
            'node_modules/focus-trap/dist/focus-trap.js'(exports2) {

                'use strict'

                Object.defineProperty(exports2, '__esModule', { value: true })

                const tabbable = require_dist()

                function ownKeys(object, enumerableOnly) {

                    const keys = Object.keys(object)

                    if (Object.getOwnPropertySymbols) {

                        let symbols = Object.getOwnPropertySymbols(object)

                        enumerableOnly && (symbols = symbols.filter(function(sym) {
                            return Object.getOwnPropertyDescriptor(object, sym).enumerable
                        })), keys.push.apply(keys, symbols)

                    }

                    return keys

                }

                function _objectSpread2(target) {

                    for (let index = 1; index < arguments.length; index++) {

                        var source = null != arguments[ index ] ? arguments[ index ] : {}

                        index % 2
                            ? ownKeys(Object(source), true).forEach(function(key) {
                                _defineProperty(target, key, source[ key ])
                            })
                            : (Object.getOwnPropertyDescriptors
                                ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source))
                                : ownKeys(Object(source)).forEach(function(key) {
                                    Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key))
                                }))

                    }

                    return target

                }

                function _defineProperty(object, key, value) {

                    if (key in object) {

                        Object.defineProperty(object, key, {
                            value,
                            enumerable: true,
                            configurable: true,
                            writable: true,
                        })

                    } else {
                        object[ key ] = value
                    }

                    return object

                }

                const activeFocusTraps = function() {

                    const trapQueue = []

                    return {
                        activateTrap: function activateTrap(trap) {

                            if (trapQueue.length > 0) {

                                const activeTrap = trapQueue[ trapQueue.length - 1 ]

                                if (activeTrap !== trap) {
                                    activeTrap.pause()
                                }

                            }

                            const trapIndex = trapQueue.indexOf(trap)

                            if (trapIndex === -1) {
                                trapQueue.push(trap)
                            } else {

                                trapQueue.splice(trapIndex, 1)
                                trapQueue.push(trap)

                            }

                        },
                        deactivateTrap: function deactivateTrap(trap) {

                            const trapIndex = trapQueue.indexOf(trap)

                            if (trapIndex !== -1) {
                                trapQueue.splice(trapIndex, 1)
                            }

                            if (trapQueue.length > 0) {
                                trapQueue[ trapQueue.length - 1 ].unpause()
                            }

                        },
                    }

                }()

                const isSelectableInput = function isSelectableInput2(node) {
                    return node.tagName && node.tagName.toLowerCase() === 'input' && typeof node.select === 'function'
                }

                const isEscapeEvent = function isEscapeEvent2(e) {
                    return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27
                }

                const isTabEvent = function isTabEvent2(e) {
                    return e.key === 'Tab' || e.keyCode === 9
                }

                const delay = function delay2(function_) {
                    return setTimeout(function_, 0)
                }

                const findIndex = function findIndex2(array, function_) {

                    let index = -1

                    array.every(function(value, index_) {

                        if (function_(value)) {

                            index = index_

                            return false

                        }

                        return true

                    })

                    return index

                }

                const valueOrHandler = function valueOrHandler2(value) {

                    for (var _length = arguments.length, parameters = new Array(_length > 1 ? _length - 1 : 0), _key = 1; _key < _length; _key++) {
                        parameters[ _key - 1 ] = arguments[ _key ]
                    }

                    return typeof value === 'function' ? value.apply(void 0, parameters) : value

                }

                const getActualTarget = function getActualTarget2(event) {
                    return event.target.shadowRoot && typeof event.composedPath === 'function' ? event.composedPath()[ 0 ] : event.target
                }

                const createFocusTrap2 = function createFocusTrap3(elements, userOptions) {

                    const document_ = (userOptions === null || userOptions === void 0 ? void 0 : userOptions.document) || document

                    const config = _objectSpread2({
                        returnFocusOnDeactivate: true,
                        escapeDeactivates: true,
                        delayInitialFocus: true,
                    }, userOptions)

                    const state = {
                        containers: [],
                        containerGroups: [],
                        tabbableGroups: [],
                        nodeFocusedBeforeActivation: null,
                        mostRecentlyFocusedNode: null,
                        active: false,
                        paused: false,
                        delayInitialFocusTimer: void 0,
                    }

                    let trap

                    const getOption = function getOption2(configOverrideOptions, optionName, configOptionName) {
                        return configOverrideOptions && configOverrideOptions[ optionName ] !== void 0 ? configOverrideOptions[ optionName ] : config[ configOptionName || optionName ]
                    }

                    const findContainerIndex = function findContainerIndex2(element) {

                        return state.containerGroups.findIndex(function(_reference) {

                            const container = _reference.container, tabbableNodes = _reference.tabbableNodes

                            return container.contains(element) || tabbableNodes.find(function(node) {
                                return node === element
                            })

                        })

                    }

                    const getNodeForOption = function getNodeForOption2(optionName) {

                        let optionValue = config[ optionName ]

                        if (typeof optionValue === 'function') {

                            for (var _length2 = arguments.length, parameters = new Array(_length2 > 1 ? _length2 - 1 : 0), _key2 = 1; _key2 < _length2; _key2++) {
                                parameters[ _key2 - 1 ] = arguments[ _key2 ]
                            }

                            optionValue = optionValue.apply(void 0, parameters)

                        }

                        if (optionValue === true) {
                            optionValue = void 0
                        }

                        if (!optionValue) {

                            if (optionValue === void 0 || optionValue === false) {
                                return optionValue
                            }

                            throw new Error('`'.concat(optionName, '` was specified but was not a node, or did not return a node'))

                        }

                        let node = optionValue

                        if (typeof optionValue === 'string') {

                            node = document_.querySelector(optionValue)

                            if (!node) {
                                throw new Error('`'.concat(optionName, '` as selector refers to no known node'))
                            }

                        }

                        return node

                    }

                    const getInitialFocusNode = function getInitialFocusNode2() {

                        let node = getNodeForOption('initialFocus')

                        if (node === false) {
                            return false
                        }

                        if (node === void 0) {

                            if (findContainerIndex(document_.activeElement) >= 0) {
                                node = document_.activeElement
                            } else {

                                const firstTabbableGroup = state.tabbableGroups[ 0 ]
                                const firstTabbableNode = firstTabbableGroup && firstTabbableGroup.firstTabbableNode

                                node = firstTabbableNode || getNodeForOption('fallbackFocus')

                            }

                        }

                        if (!node) {
                            throw new Error('Your focus-trap needs to have at least one focusable element')
                        }

                        return node

                    }

                    const updateTabbableNodes = function updateTabbableNodes2() {

                        state.containerGroups = state.containers.map(function(container) {

                            const tabbableNodes = tabbable.tabbable(container, config.tabbableOptions)
                            const focusableNodes = tabbable.focusable(container, config.tabbableOptions)

                            return {
                                container,
                                tabbableNodes,
                                focusableNodes,
                                firstTabbableNode: tabbableNodes.length > 0 ? tabbableNodes[ 0 ] : null,
                                lastTabbableNode: tabbableNodes.length > 0 ? tabbableNodes[ tabbableNodes.length - 1 ] : null,
                                nextTabbableNode: function nextTabbableNode(node) {

                                    const forward = arguments.length > 1 && arguments[ 1 ] !== void 0 ? arguments[ 1 ] : true

                                    const nodeIndex = focusableNodes.findIndex(function(n) {
                                        return n === node
                                    })

                                    if (nodeIndex < 0) {
                                        return void 0
                                    }

                                    if (forward) {

                                        return focusableNodes.slice(nodeIndex + 1).find(function(n) {
                                            return tabbable.isTabbable(n, config.tabbableOptions)
                                        })

                                    }

                                    return focusableNodes.slice(0, nodeIndex).reverse().find(function(n) {
                                        return tabbable.isTabbable(n, config.tabbableOptions)
                                    })

                                },
                            }

                        })

                        state.tabbableGroups = state.containerGroups.filter(function(group) {
                            return group.tabbableNodes.length > 0
                        })

                        if (state.tabbableGroups.length <= 0 && !getNodeForOption('fallbackFocus')) {
                            throw new Error('Your focus-trap must have at least one container with at least one tabbable node in it at all times')
                        }

                    }

                    const tryFocus = function tryFocus2(node) {

                        if (node === false) {
                            return
                        }

                        if (node === document_.activeElement) {
                            return
                        }

                        if (!node || !node.focus) {

                            tryFocus2(getInitialFocusNode())

                            return

                        }

                        node.focus({
                            preventScroll: !!config.preventScroll,
                        })

                        state.mostRecentlyFocusedNode = node

                        if (isSelectableInput(node)) {
                            node.select()
                        }

                    }

                    const getReturnFocusNode = function getReturnFocusNode2(previousActiveElement) {

                        const node = getNodeForOption('setReturnFocus', previousActiveElement)

                        return node ? node : (node === false ? false : previousActiveElement)

                    }

                    const checkPointerDown = function checkPointerDown2(e) {

                        const target = getActualTarget(e)

                        if (findContainerIndex(target) >= 0) {
                            return
                        }

                        if (valueOrHandler(config.clickOutsideDeactivates, e)) {

                            trap.deactivate({
                                returnFocus: config.returnFocusOnDeactivate && !tabbable.isFocusable(target, config.tabbableOptions),
                            })

                            return

                        }

                        if (valueOrHandler(config.allowOutsideClick, e)) {
                            return
                        }

                        e.preventDefault()

                    }

                    const checkFocusIn = function checkFocusIn2(e) {

                        const target = getActualTarget(e)
                        const targetContained = findContainerIndex(target) >= 0

                        if (targetContained || target instanceof Document) {

                            if (targetContained) {
                                state.mostRecentlyFocusedNode = target
                            }

                        } else {

                            e.stopImmediatePropagation()
                            tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode())

                        }

                    }

                    const checkTab = function checkTab2(e) {

                        const target = getActualTarget(e)

                        updateTabbableNodes()

                        let destinationNode = null

                        if (state.tabbableGroups.length > 0) {

                            const containerIndex = findContainerIndex(target)
                            const containerGroup = containerIndex >= 0 ? state.containerGroups[ containerIndex ] : void 0

                            if (containerIndex < 0) {

                                if (e.shiftKey) {
                                    destinationNode = state.tabbableGroups[ state.tabbableGroups.length - 1 ].lastTabbableNode
                                } else {
                                    destinationNode = state.tabbableGroups[ 0 ].firstTabbableNode
                                }

                            } else if (e.shiftKey) {

                                let startOfGroupIndex = findIndex(state.tabbableGroups, function(_reference2) {

                                    const firstTabbableNode = _reference2.firstTabbableNode

                                    return target === firstTabbableNode

                                })

                                if (startOfGroupIndex < 0 && (containerGroup.container === target || tabbable.isFocusable(target, config.tabbableOptions) && !tabbable.isTabbable(target, config.tabbableOptions) && !containerGroup.nextTabbableNode(target, false))) {
                                    startOfGroupIndex = containerIndex
                                }

                                if (startOfGroupIndex >= 0) {

                                    const destinationGroupIndex = startOfGroupIndex === 0 ? state.tabbableGroups.length - 1 : startOfGroupIndex - 1
                                    const destinationGroup = state.tabbableGroups[ destinationGroupIndex ]

                                    destinationNode = destinationGroup.lastTabbableNode

                                }

                            } else {

                                let lastOfGroupIndex = findIndex(state.tabbableGroups, function(_reference3) {

                                    const lastTabbableNode = _reference3.lastTabbableNode

                                    return target === lastTabbableNode

                                })

                                if (lastOfGroupIndex < 0 && (containerGroup.container === target || tabbable.isFocusable(target, config.tabbableOptions) && !tabbable.isTabbable(target, config.tabbableOptions) && !containerGroup.nextTabbableNode(target))) {
                                    lastOfGroupIndex = containerIndex
                                }

                                if (lastOfGroupIndex >= 0) {

                                    const _destinationGroupIndex = lastOfGroupIndex === state.tabbableGroups.length - 1 ? 0 : lastOfGroupIndex + 1
                                    const _destinationGroup = state.tabbableGroups[ _destinationGroupIndex ]

                                    destinationNode = _destinationGroup.firstTabbableNode

                                }

                            }

                        } else {
                            destinationNode = getNodeForOption('fallbackFocus')
                        }

                        if (destinationNode) {

                            e.preventDefault()
                            tryFocus(destinationNode)

                        }

                    }

                    const checkKey = function checkKey2(e) {

                        if (isEscapeEvent(e) && valueOrHandler(config.escapeDeactivates, e) !== false) {

                            e.preventDefault()
                            trap.deactivate()

                            return

                        }

                        if (isTabEvent(e)) {
                            checkTab(e)
                        }

                    }

                    const checkClick = function checkClick2(e) {

                        const target = getActualTarget(e)

                        if (findContainerIndex(target) >= 0) {
                            return
                        }

                        if (valueOrHandler(config.clickOutsideDeactivates, e)) {
                            return
                        }

                        if (valueOrHandler(config.allowOutsideClick, e)) {
                            return
                        }

                        e.preventDefault()
                        e.stopImmediatePropagation()

                    }

                    const addListeners = function addListeners2() {

                        if (!state.active) {
                            return
                        }

                        activeFocusTraps.activateTrap(trap)

                        state.delayInitialFocusTimer = config.delayInitialFocus
                            ? delay(function() {
                                tryFocus(getInitialFocusNode())
                            })
                            : tryFocus(getInitialFocusNode())

                        document_.addEventListener('focusin', checkFocusIn, true)

                        document_.addEventListener('mousedown', checkPointerDown, {
                            capture: true,
                            passive: false,
                        })

                        document_.addEventListener('touchstart', checkPointerDown, {
                            capture: true,
                            passive: false,
                        })

                        document_.addEventListener('click', checkClick, {
                            capture: true,
                            passive: false,
                        })

                        document_.addEventListener('keydown', checkKey, {
                            capture: true,
                            passive: false,
                        })

                        return trap

                    }

                    const removeListeners = function removeListeners2() {

                        if (!state.active) {
                            return
                        }

                        document_.removeEventListener('focusin', checkFocusIn, true)
                        document_.removeEventListener('mousedown', checkPointerDown, true)
                        document_.removeEventListener('touchstart', checkPointerDown, true)
                        document_.removeEventListener('click', checkClick, true)
                        document_.removeEventListener('keydown', checkKey, true)

                        return trap

                    }

                    trap = {
                        get active() {
                            return state.active
                        },
                        get paused() {
                            return state.paused
                        },
                        activate: function activate(activateOptions) {

                            if (state.active) {
                                return this
                            }

                            const onActivate = getOption(activateOptions, 'onActivate')
                            const onPostActivate = getOption(activateOptions, 'onPostActivate')
                            const checkCanFocusTrap = getOption(activateOptions, 'checkCanFocusTrap')

                            if (!checkCanFocusTrap) {
                                updateTabbableNodes()
                            }

                            state.active = true
                            state.paused = false
                            state.nodeFocusedBeforeActivation = document_.activeElement

                            if (onActivate) {
                                onActivate()
                            }

                            const finishActivation = function finishActivation2() {

                                if (checkCanFocusTrap) {
                                    updateTabbableNodes()
                                }

                                addListeners()

                                if (onPostActivate) {
                                    onPostActivate()
                                }

                            }

                            if (checkCanFocusTrap) {

                                checkCanFocusTrap(state.containers.concat()).then(finishActivation, finishActivation)

                                return this

                            }

                            finishActivation()

                            return this

                        },
                        deactivate: function deactivate(deactivateOptions) {

                            if (!state.active) {
                                return this
                            }

                            const options = _objectSpread2({
                                onDeactivate: config.onDeactivate,
                                onPostDeactivate: config.onPostDeactivate,
                                checkCanReturnFocus: config.checkCanReturnFocus,
                            }, deactivateOptions)

                            clearTimeout(state.delayInitialFocusTimer)
                            state.delayInitialFocusTimer = void 0
                            removeListeners()
                            state.active = false
                            state.paused = false
                            activeFocusTraps.deactivateTrap(trap)

                            const onDeactivate = getOption(options, 'onDeactivate')
                            const onPostDeactivate = getOption(options, 'onPostDeactivate')
                            const checkCanReturnFocus = getOption(options, 'checkCanReturnFocus')
                            const returnFocus = getOption(options, 'returnFocus', 'returnFocusOnDeactivate')

                            if (onDeactivate) {
                                onDeactivate()
                            }

                            const finishDeactivation = function finishDeactivation2() {

                                delay(function() {

                                    if (returnFocus) {
                                        tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation))
                                    }

                                    if (onPostDeactivate) {
                                        onPostDeactivate()
                                    }

                                })

                            }

                            if (returnFocus && checkCanReturnFocus) {

                                checkCanReturnFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation)).then(finishDeactivation, finishDeactivation)

                                return this

                            }

                            finishDeactivation()

                            return this

                        },
                        pause: function pause() {

                            if (state.paused || !state.active) {
                                return this
                            }

                            state.paused = true
                            removeListeners()

                            return this

                        },
                        unpause: function unpause() {

                            if (!state.paused || !state.active) {
                                return this
                            }

                            state.paused = false
                            updateTabbableNodes()
                            addListeners()

                            return this

                        },
                        updateContainerElements: function updateContainerElements(containerElements) {

                            const elementsAsArray = [].concat(containerElements).filter(Boolean)

                            state.containers = elementsAsArray.map(function(element) {
                                return typeof element === 'string' ? document_.querySelector(element) : element
                            })

                            if (state.active) {
                                updateTabbableNodes()
                            }

                            return this

                        },
                    }

                    trap.updateContainerElements(elements)

                    return trap

                }

                exports2.createFocusTrap = createFocusTrap2

            },
        })

        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            focus: () => source_default,
        })

        module.exports = __toCommonJS(module_exports)

        const import_focus_trap = __toESM2(require_focus_trap())
        const import_tabbable = __toESM2(require_dist())

        function source_default(Alpine19) {

            let lastFocused
            let currentFocused

            window.addEventListener('focusin', () => {

                lastFocused = currentFocused
                currentFocused = document.activeElement

            })

            Alpine19.magic('focus', element => {

                let within = element

                return {
                    __noscroll: false,
                    __wrapAround: false,
                    within(element2) {

                        within = element2

                        return this

                    },
                    withoutScrolling() {

                        this.__noscroll = true

                        return this

                    },
                    noscroll() {

                        this.__noscroll = true

                        return this

                    },
                    withWrapAround() {

                        this.__wrapAround = true

                        return this

                    },
                    wrap() {
                        return this.withWrapAround()
                    },
                    focusable(element2) {
                        return (0, import_tabbable.isFocusable)(element2)
                    },
                    previouslyFocused() {
                        return lastFocused
                    },
                    lastFocused() {
                        return lastFocused
                    },
                    focused() {
                        return currentFocused
                    },
                    focusables() {

                        if (Array.isArray(within)) { return within }

                        return (0, import_tabbable.focusable)(within, { displayCheck: 'none' })

                    },
                    all() {
                        return this.focusables()
                    },
                    isFirst(element2) {

                        const els2 = this.all()

                        return els2[ 0 ] && els2[ 0 ].isSameNode(element2)

                    },
                    isLast(element2) {

                        const els2 = this.all()

                        return els2.length && els2.slice(-1)[ 0 ].isSameNode(element2)

                    },
                    getFirst() {
                        return this.all()[ 0 ]
                    },
                    getLast() {
                        return this.all().slice(-1)[ 0 ]
                    },
                    getNext() {

                        const list = this.all()
                        const current = document.activeElement

                        if (list.indexOf(current) === -1) { return }

                        if (this.__wrapAround && list.indexOf(current) === list.length - 1) {
                            return list[ 0 ]
                        }

                        return list[ list.indexOf(current) + 1 ]

                    },
                    getPrevious() {

                        const list = this.all()
                        const current = document.activeElement

                        if (list.indexOf(current) === -1) { return }

                        if (this.__wrapAround && list.indexOf(current) === 0) {
                            return list.slice(-1)[ 0 ]
                        }

                        return list[ list.indexOf(current) - 1 ]

                    },
                    first() {
                        this.focus(this.getFirst())
                    },
                    last() {
                        this.focus(this.getLast())
                    },
                    next() {
                        this.focus(this.getNext())
                    },
                    previous() {
                        this.focus(this.getPrevious())
                    },
                    prev() {
                        return this.previous()
                    },
                    focus(element2) {

                        if (!element2) { return }

                        setTimeout(() => {

                            if (!element2.hasAttribute('tabindex')) { element2.setAttribute('tabindex', '0') }

                            element2.focus({ preventScroll: this.__noscroll })

                        })

                    },
                }

            })

            Alpine19.directive('trap', Alpine19.skipDuringClone((element, { expression, modifiers }, { effect, evaluateLater, cleanup }) => {

                const evaluator = evaluateLater(expression)

                let oldValue = false

                const options = {
                    escapeDeactivates: false,
                    allowOutsideClick: true,
                    fallbackFocus: () => element,
                }

                if (modifiers.includes('noautofocus')) {
                    options.initialFocus = false
                } else {

                    const autofocusElement = element.querySelector('[autofocus]')

                    if (autofocusElement) { options.initialFocus = autofocusElement }

                }

                const trap = (0, import_focus_trap.createFocusTrap)(element, options)

                let undoInert = () => {
                }

                let undoDisableScrolling = () => {
                }

                const releaseFocus = () => {

                    undoInert()

                    undoInert = () => {
                    }

                    undoDisableScrolling()

                    undoDisableScrolling = () => {
                    }

                    trap.deactivate({
                        returnFocus: !modifiers.includes('noreturn'),
                    })

                }

                effect(() => evaluator(value => {

                    if (oldValue === value) { return }

                    if (value && !oldValue) {

                        if (modifiers.includes('noscroll')) { undoDisableScrolling = disableScrolling() }

                        if (modifiers.includes('inert')) { undoInert = setInert(element) }

                        setTimeout(() => {
                            trap.activate()
                        }, 15)

                    }

                    if (!value && oldValue) {
                        releaseFocus()
                    }

                    oldValue = !!value

                }))

                cleanup(releaseFocus)

            }, (element, { expression, modifiers }, { evaluate }) => {
                if (modifiers.includes('inert') && evaluate(expression)) { setInert(element) }
            }))

        }

        function setInert(element) {

            const undos = []

            crawlSiblingsUp(element, sibling => {

                const cache = sibling.hasAttribute('aria-hidden')

                sibling.setAttribute('aria-hidden', 'true')
                undos.push(() => cache || sibling.removeAttribute('aria-hidden'))

            })

            return () => {
                while (undos.length) { undos.pop()() }
            }

        }

        function crawlSiblingsUp(element, callback) {

            if (element.isSameNode(document.body) || !element.parentNode) { return }

            for (const sibling of Array.from(element.parentNode.children)) {

                if (sibling.isSameNode(element)) {
                    crawlSiblingsUp(element.parentNode, callback)
                } else {
                    callback(sibling)
                }

            }

        }

        function disableScrolling() {

            const overflow = document.documentElement.style.overflow
            const paddingRight = document.documentElement.style.paddingRight
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

            document.documentElement.style.overflow = 'hidden'
            document.documentElement.style.paddingRight = `${ scrollbarWidth }px`

            return () => {

                document.documentElement.style.overflow = overflow
                document.documentElement.style.paddingRight = paddingRight

            }

        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/persist/dist/module.cjs.js
const require_module_cjs4 = __commonJS({
    'node_modules/@alpinejs/persist/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            persist: () => source_default,
        })

        module.exports = __toCommonJS(module_exports)

        function source_default(Alpine19) {

            const persist3 = () => {

                let alias
                let storage

                try {
                    storage = localStorage
                } catch (e) {

                    console.error(e)
                    console.warn('Alpine: $persist is using temporary storage since localStorage is unavailable.')

                    const dummy = /* @__PURE__ */ new Map()

                    storage = {
                        getItem: dummy.get.bind(dummy),
                        setItem: dummy.set.bind(dummy),
                    }

                }

                return Alpine19.interceptor((initialValue, getter, setter, path, key) => {

                    const lookup = alias || `_x_${ path }`
                    const initial = storageHas(lookup, storage) ? storageGet(lookup, storage) : initialValue

                    setter(initial)

                    Alpine19.effect(() => {

                        const value = getter()

                        storageSet(lookup, value, storage)
                        setter(value)

                    })

                    return initial

                }, function_ => {

                    function_.as = key => {

                        alias = key

                        return function_

                    }, function_.using = target => {

                        storage = target

                        return function_

                    }

                })

            }

            Object.defineProperty(Alpine19, '$persist', { get: () => persist3() })
            Alpine19.magic('persist', persist3)

            Alpine19.persist = (key, { get, set }, storage = localStorage) => {

                const initial = storageHas(key, storage) ? storageGet(key, storage) : get()

                set(initial)

                Alpine19.effect(() => {

                    const value = get()

                    storageSet(key, value, storage)
                    set(value)

                })

            }

        }

        function storageHas(key, storage) {
            return storage.getItem(key) !== null
        }

        function storageGet(key, storage) {

            const value = storage.getItem(key, storage)

            if (value === void 0) { return }

            return JSON.parse(value)

        }

        function storageSet(key, value, storage) {
            storage.setItem(key, JSON.stringify(value))
        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/intersect/dist/module.cjs.js
const require_module_cjs5 = __commonJS({
    'node_modules/@alpinejs/intersect/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            intersect: () => source_default,
        })

        module.exports = __toCommonJS(module_exports)

        function source_default(Alpine19) {

            Alpine19.directive('intersect', Alpine19.skipDuringClone((element, { value, expression, modifiers }, { evaluateLater, cleanup }) => {

                const evaluate = evaluateLater(expression)

                const options = {
                    rootMargin: getRootMargin(modifiers),
                    threshold: getThreshold(modifiers),
                }

                const observer = new IntersectionObserver(entries => {

                    for (const entry of entries) {

                        if (entry.isIntersecting === (value === 'leave')) { continue }

                        evaluate()
                        modifiers.includes('once') && observer.disconnect()

                    }

                }, options)

                observer.observe(element)

                cleanup(() => {
                    observer.disconnect()
                })

            }))

        }

        function getThreshold(modifiers) {

            if (modifiers.includes('full')) { return 0.99 }

            if (modifiers.includes('half')) { return 0.5 }

            if (!modifiers.includes('threshold')) { return 0 }

            const threshold = modifiers[ modifiers.indexOf('threshold') + 1 ]

            if (threshold === '100') { return 1 }

            if (threshold === '0') { return 0 }

            return Number(`.${ threshold }`)

        }

        function getLengthValue(rawValue) {

            const match = rawValue.match(/^(-?[0-9]+)(px|%)?$/)

            return match ? match[ 1 ] + (match[ 2 ] || 'px') : void 0

        }

        function getRootMargin(modifiers) {

            const key = 'margin'
            const fallback2 = '0px 0px 0px 0px'
            const index = modifiers.indexOf(key)

            if (index === -1) { return fallback2 }

            let values = []

            for (let index_ = 1; index_ < 5; index_++) {
                values.push(getLengthValue(modifiers[ index + index_ ] || ''))
            }

            values = values.filter(v => v !== void 0)

            return values.length ? values.join(' ').trim() : fallback2

        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/resize/dist/module.cjs.js
const require_module_cjs6 = __commonJS({
    'node_modules/@alpinejs/resize/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            resize: () => source_default,
        })

        module.exports = __toCommonJS(module_exports)

        function source_default(Alpine19) {

            Alpine19.directive('resize', Alpine19.skipDuringClone((element, { value, expression, modifiers }, { evaluateLater, cleanup }) => {

                const evaluator = evaluateLater(expression)

                const evaluate = (width, height) => {

                    evaluator(() => {
                    }, { scope: { $width: width, $height: height } })

                }

                const off = modifiers.includes('document') ? onDocumentResize(evaluate) : onElementResize(element, evaluate)

                cleanup(() => off())

            }))

        }

        function onElementResize(element, callback) {

            const observer = new ResizeObserver(entries => {

                const [ width, height ] = dimensions(entries)

                callback(width, height)

            })

            observer.observe(element)

            return () => observer.disconnect()

        }

        let documentResizeObserver

        const documentResizeObserverCallbacks = /* @__PURE__ */ new Set()

        function onDocumentResize(callback) {

            documentResizeObserverCallbacks.add(callback)

            if (!documentResizeObserver) {

                documentResizeObserver = new ResizeObserver(entries => {

                    const [ width, height ] = dimensions(entries)

                    for (const index of documentResizeObserverCallbacks) { index(width, height) }

                })

                documentResizeObserver.observe(document.documentElement)

            }

            return () => {
                documentResizeObserverCallbacks.delete(callback)
            }

        }

        function dimensions(entries) {

            let width, height

            for (const entry of entries) {

                width = entry.borderBoxSize[ 0 ].inlineSize
                height = entry.borderBoxSize[ 0 ].blockSize

            }

            return [ width, height ]

        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/anchor/dist/module.cjs.js
const require_module_cjs7 = __commonJS({
    'node_modules/@alpinejs/anchor/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            anchor: () => source_default,
            default: () => module_default,
        })

        module.exports = __toCommonJS(module_exports)

        const min = Math.min
        const max = Math.max
        const round = Math.round
        const floor = Math.floor

        const createCoords = v => ({
            x: v,
            y: v,
        })

        const oppositeSideMap = {
            left: 'right',
            right: 'left',
            bottom: 'top',
            top: 'bottom',
        }

        const oppositeAlignmentMap = {
            start: 'end',
            end: 'start',
        }

        function clamp(start2, value, end) {
            return max(start2, min(value, end))
        }

        function evaluate(value, parameter) {
            return typeof value === 'function' ? value(parameter) : value
        }

        function getSide(placement) {
            return placement.split('-')[ 0 ]
        }

        function getAlignment(placement) {
            return placement.split('-')[ 1 ]
        }

        function getOppositeAxis(axis) {
            return axis === 'x' ? 'y' : 'x'
        }

        function getAxisLength(axis) {
            return axis === 'y' ? 'height' : 'width'
        }

        function getSideAxis(placement) {
            return [ 'top', 'bottom' ].includes(getSide(placement)) ? 'y' : 'x'
        }

        function getAlignmentAxis(placement) {
            return getOppositeAxis(getSideAxis(placement))
        }

        function getAlignmentSides(placement, rects, rtl) {

            if (rtl === void 0) {
                rtl = false
            }

            const alignment = getAlignment(placement)
            const alignmentAxis = getAlignmentAxis(placement)
            const length = getAxisLength(alignmentAxis)

            let mainAlignmentSide = alignmentAxis === 'x' ? (alignment === (rtl ? 'end' : 'start') ? 'right' : 'left') : (alignment === 'start' ? 'bottom' : 'top')

            if (rects.reference[ length ] > rects.floating[ length ]) {
                mainAlignmentSide = getOppositePlacement(mainAlignmentSide)
            }

            return [ mainAlignmentSide, getOppositePlacement(mainAlignmentSide) ]

        }

        function getExpandedPlacements(placement) {

            const oppositePlacement = getOppositePlacement(placement)

            return [ getOppositeAlignmentPlacement(placement), oppositePlacement, getOppositeAlignmentPlacement(oppositePlacement) ]

        }

        function getOppositeAlignmentPlacement(placement) {
            return placement.replace(/start|end/g, alignment => oppositeAlignmentMap[ alignment ])
        }

        function getSideList(side, isStart, rtl) {

            const lr = [ 'left', 'right' ]
            const rl = [ 'right', 'left' ]
            const tb = [ 'top', 'bottom' ]
            const bt = [ 'bottom', 'top' ]

            switch (side) {

            case 'top':

            case 'bottom':
                if (rtl) { return isStart ? rl : lr }

                return isStart ? lr : rl

            case 'left':

            case 'right':
                return isStart ? tb : bt

            default:
                return []

            }

        }

        function getOppositeAxisPlacements(placement, flipAlignment, direction, rtl) {

            const alignment = getAlignment(placement)

            let list = getSideList(getSide(placement), direction === 'start', rtl)

            if (alignment) {

                list = list.map(side => `${ side }-${ alignment }`)

                if (flipAlignment) {
                    list = list.concat(list.map(getOppositeAlignmentPlacement))
                }

            }

            return list

        }

        function getOppositePlacement(placement) {
            return placement.replace(/left|right|bottom|top/g, side => oppositeSideMap[ side ])
        }

        function expandPaddingObject(padding) {

            return {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                ...padding,
            }

        }

        function getPaddingObject(padding) {

            return typeof padding !== 'number'
                ? expandPaddingObject(padding)
                : {
                    top: padding,
                    right: padding,
                    bottom: padding,
                    left: padding,
                }

        }

        function rectToClientRect(rect) {

            return {
                ...rect,
                top: rect.y,
                left: rect.x,
                right: rect.x + rect.width,
                bottom: rect.y + rect.height,
            }

        }

        function computeCoordsFromPlacement(_reference, placement, rtl) {

            const {
                reference,
                floating,
            } = _reference

            const sideAxis = getSideAxis(placement)
            const alignmentAxis = getAlignmentAxis(placement)
            const alignLength = getAxisLength(alignmentAxis)
            const side = getSide(placement)
            const isVertical = sideAxis === 'y'
            const commonX = reference.x + reference.width / 2 - floating.width / 2
            const commonY = reference.y + reference.height / 2 - floating.height / 2
            const commonAlign = reference[ alignLength ] / 2 - floating[ alignLength ] / 2

            let coords

            switch (side) {

            case 'top':
                coords = {
                    x: commonX,
                    y: reference.y - floating.height,
                }

                break

            case 'bottom':
                coords = {
                    x: commonX,
                    y: reference.y + reference.height,
                }

                break

            case 'right':
                coords = {
                    x: reference.x + reference.width,
                    y: commonY,
                }

                break

            case 'left':
                coords = {
                    x: reference.x - floating.width,
                    y: commonY,
                }

                break

            default:
                coords = {
                    x: reference.x,
                    y: reference.y,
                }

            }

            switch (getAlignment(placement)) {

            case 'start':
                coords[ alignmentAxis ] -= commonAlign * (rtl && isVertical ? -1 : 1)

                break

            case 'end':
                coords[ alignmentAxis ] += commonAlign * (rtl && isVertical ? -1 : 1)

                break

            }

            return coords

        }

        const computePosition = async(reference, floating, config) => {

            const {
                placement = 'bottom',
                strategy = 'absolute',
                middleware = [],
                platform: platform2,
            } = config

            const validMiddleware = middleware.filter(Boolean)
            const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(floating))

            let rects = await platform2.getElementRects({
                reference,
                floating,
                strategy,
            })

            let {
                x,
                y,
            } = computeCoordsFromPlacement(rects, placement, rtl)

            let statefulPlacement = placement
            let middlewareData = {}
            let resetCount = 0

            for (let index = 0; index < validMiddleware.length; index++) {

                const {
                    name,
                    fn,
                } = validMiddleware[ index ]

                const {
                    x: nextX,
                    y: nextY,
                    data,
                    reset,
                } = await fn({
                    x,
                    y,
                    initialPlacement: placement,
                    placement: statefulPlacement,
                    strategy,
                    middlewareData,
                    rects,
                    platform: platform2,
                    elements: {
                        reference,
                        floating,
                    },
                })

                x = nextX != null ? nextX : x
                y = nextY != null ? nextY : y

                middlewareData = {
                    ...middlewareData,
                    [ name ]: {
                        ...middlewareData[ name ],
                        ...data,
                    },
                }

                if (reset && resetCount <= 50) {

                    resetCount++

                    if (typeof reset === 'object') {

                        if (reset.placement) {
                            statefulPlacement = reset.placement
                        }

                        if (reset.rects) {

                            rects = reset.rects === true
                                ? await platform2.getElementRects({
                                    reference,
                                    floating,
                                    strategy,
                                })
                                : reset.rects

                        }

                        ({
                            x,
                            y,
                        } = computeCoordsFromPlacement(rects, statefulPlacement, rtl))

                    }

                    index = -1

                    continue

                }

            }

            return {
                x,
                y,
                placement: statefulPlacement,
                strategy,
                middlewareData,
            }

        }

        async function detectOverflow(state, options) {

            let _await$platform$isEle

            if (options === void 0) {
                options = {}
            }

            const {
                x,
                y,
                platform: platform2,
                rects,
                elements,
                strategy,
            } = state

            const {
                boundary = 'clippingAncestors',
                rootBoundary = 'viewport',
                elementContext = 'floating',
                altBoundary = false,
                padding = 0,
            } = evaluate(options, state)

            const paddingObject = getPaddingObject(padding)
            const altContext = elementContext === 'floating' ? 'reference' : 'floating'
            const element = elements[ altBoundary ? altContext : elementContext ]

            const clippingClientRect = rectToClientRect(await platform2.getClippingRect({
                element: ((_await$platform$isEle = await (platform2.isElement == null ? void 0 : platform2.isElement(element))) != null ? _await$platform$isEle : true) ? element : element.contextElement || await (platform2.getDocumentElement == null ? void 0 : platform2.getDocumentElement(elements.floating)),
                boundary,
                rootBoundary,
                strategy,
            }))

            const rect = elementContext === 'floating'
                ? {
                    ...rects.floating,
                    x,
                    y,
                }
                : rects.reference

            const offsetParent = await (platform2.getOffsetParent == null ? void 0 : platform2.getOffsetParent(elements.floating))

            const offsetScale = await (platform2.isElement == null ? void 0 : platform2.isElement(offsetParent))
                ? await (platform2.getScale == null ? void 0 : platform2.getScale(offsetParent)) || {
                    x: 1,
                    y: 1,
                }
                : {
                    x: 1,
                    y: 1,
                }

            const elementClientRect = rectToClientRect(platform2.convertOffsetParentRelativeRectToViewportRelativeRect
                ? await platform2.convertOffsetParentRelativeRectToViewportRelativeRect({
                    rect,
                    offsetParent,
                    strategy,
                })
                : rect)

            return {
                top: (clippingClientRect.top - elementClientRect.top + paddingObject.top) / offsetScale.y,
                bottom: (elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom) / offsetScale.y,
                left: (clippingClientRect.left - elementClientRect.left + paddingObject.left) / offsetScale.x,
                right: (elementClientRect.right - clippingClientRect.right + paddingObject.right) / offsetScale.x,
            }

        }

        const flip = function(options) {

            if (options === void 0) {
                options = {}
            }

            return {
                name: 'flip',
                options,
                async fn(state) {

                    let _middlewareData$arrow, _middlewareData$flip

                    const {
                        placement,
                        middlewareData,
                        rects,
                        initialPlacement,
                        platform: platform2,
                        elements,
                    } = state

                    const {
                        mainAxis: checkMainAxis = true,
                        crossAxis: checkCrossAxis = true,
                        fallbackPlacements: specifiedFallbackPlacements,
                        fallbackStrategy = 'bestFit',
                        fallbackAxisSideDirection = 'none',
                        flipAlignment = true,
                        ...detectOverflowOptions
                    } = evaluate(options, state)

                    if ((_middlewareData$arrow = middlewareData.arrow) != null && _middlewareData$arrow.alignmentOffset) {
                        return {}
                    }

                    const side = getSide(placement)
                    const isBasePlacement = getSide(initialPlacement) === initialPlacement
                    const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(elements.floating))
                    const fallbackPlacements = specifiedFallbackPlacements || (isBasePlacement || !flipAlignment ? [ getOppositePlacement(initialPlacement) ] : getExpandedPlacements(initialPlacement))

                    if (!specifiedFallbackPlacements && fallbackAxisSideDirection !== 'none') {
                        fallbackPlacements.push(...getOppositeAxisPlacements(initialPlacement, flipAlignment, fallbackAxisSideDirection, rtl))
                    }

                    const placements2 = [ initialPlacement, ...fallbackPlacements ]
                    const overflow = await detectOverflow(state, detectOverflowOptions)
                    const overflows = []

                    let overflowsData = ((_middlewareData$flip = middlewareData.flip) == null ? void 0 : _middlewareData$flip.overflows) || []

                    if (checkMainAxis) {
                        overflows.push(overflow[ side ])
                    }

                    if (checkCrossAxis) {

                        const sides2 = getAlignmentSides(placement, rects, rtl)

                        overflows.push(overflow[ sides2[ 0 ] ], overflow[ sides2[ 1 ] ])

                    }

                    overflowsData = [
                        ...overflowsData, {
                            placement,
                            overflows,
                        },
                    ]

                    if (!overflows.every(side2 => side2 <= 0)) {

                        let _middlewareData$flip2, _overflowsData$filter

                        const nextIndex = (((_middlewareData$flip2 = middlewareData.flip) == null ? void 0 : _middlewareData$flip2.index) || 0) + 1
                        const nextPlacement = placements2[ nextIndex ]

                        if (nextPlacement) {

                            return {
                                data: {
                                    index: nextIndex,
                                    overflows: overflowsData,
                                },
                                reset: {
                                    placement: nextPlacement,
                                },
                            }

                        }

                        let resetPlacement = (_overflowsData$filter = overflowsData.filter(d => d.overflows[ 0 ] <= 0).sort((a, b) => a.overflows[ 1 ] - b.overflows[ 1 ])[ 0 ]) == null ? void 0 : _overflowsData$filter.placement

                        if (!resetPlacement) {

                            switch (fallbackStrategy) {

                            case 'bestFit': {

                                let _overflowsData$map$so

                                const placement2 = (_overflowsData$map$so = overflowsData.map(d => [ d.placement, d.overflows.filter(overflow2 => overflow2 > 0).reduce((accumulator, overflow2) => accumulator + overflow2, 0) ]).sort((a, b) => a[ 1 ] - b[ 1 ])[ 0 ]) == null ? void 0 : _overflowsData$map$so[ 0 ]

                                if (placement2) {
                                    resetPlacement = placement2
                                }

                                break

                            }

                            case 'initialPlacement':
                                resetPlacement = initialPlacement

                                break

                            }

                        }

                        if (placement !== resetPlacement) {

                            return {
                                reset: {
                                    placement: resetPlacement,
                                },
                            }

                        }

                    }

                    return {}

                },
            }

        }

        async function convertValueToCoords(state, options) {

            const {
                placement,
                platform: platform2,
                elements,
            } = state

            const rtl = await (platform2.isRTL == null ? void 0 : platform2.isRTL(elements.floating))
            const side = getSide(placement)
            const alignment = getAlignment(placement)
            const isVertical = getSideAxis(placement) === 'y'
            const mainAxisMulti = [ 'left', 'top' ].includes(side) ? -1 : 1
            const crossAxisMulti = rtl && isVertical ? -1 : 1
            const rawValue = evaluate(options, state)

            let {
                mainAxis,
                crossAxis,
                alignmentAxis,
            } = typeof rawValue === 'number'
                ? {
                    mainAxis: rawValue,
                    crossAxis: 0,
                    alignmentAxis: null,
                }
                : {
                    mainAxis: 0,
                    crossAxis: 0,
                    alignmentAxis: null,
                    ...rawValue,
                }

            if (alignment && typeof alignmentAxis === 'number') {
                crossAxis = alignment === 'end' ? alignmentAxis * -1 : alignmentAxis
            }

            return isVertical
                ? {
                    x: crossAxis * crossAxisMulti,
                    y: mainAxis * mainAxisMulti,
                }
                : {
                    x: mainAxis * mainAxisMulti,
                    y: crossAxis * crossAxisMulti,
                }

        }

        const offset = function(options) {

            if (options === void 0) {
                options = 0
            }

            return {
                name: 'offset',
                options,
                async fn(state) {

                    const {
                        x,
                        y,
                    } = state

                    const diffCoords = await convertValueToCoords(state, options)

                    return {
                        x: x + diffCoords.x,
                        y: y + diffCoords.y,
                        data: diffCoords,
                    }

                },
            }

        }

        const shift = function(options) {

            if (options === void 0) {
                options = {}
            }

            return {
                name: 'shift',
                options,
                async fn(state) {

                    const {
                        x,
                        y,
                        placement,
                    } = state

                    const {
                        mainAxis: checkMainAxis = true,
                        crossAxis: checkCrossAxis = false,
                        limiter = {
                            fn: _reference => {

                                const {
                                    x: x2,
                                    y: y2,
                                } = _reference

                                return {
                                    x: x2,
                                    y: y2,
                                }

                            },
                        },
                        ...detectOverflowOptions
                    } = evaluate(options, state)

                    const coords = {
                        x,
                        y,
                    }

                    const overflow = await detectOverflow(state, detectOverflowOptions)
                    const crossAxis = getSideAxis(getSide(placement))
                    const mainAxis = getOppositeAxis(crossAxis)

                    let mainAxisCoord = coords[ mainAxis ]
                    let crossAxisCoord = coords[ crossAxis ]

                    if (checkMainAxis) {

                        const minSide = mainAxis === 'y' ? 'top' : 'left'
                        const maxSide = mainAxis === 'y' ? 'bottom' : 'right'
                        const min2 = mainAxisCoord + overflow[ minSide ]
                        const max2 = mainAxisCoord - overflow[ maxSide ]

                        mainAxisCoord = clamp(min2, mainAxisCoord, max2)

                    }

                    if (checkCrossAxis) {

                        const minSide = crossAxis === 'y' ? 'top' : 'left'
                        const maxSide = crossAxis === 'y' ? 'bottom' : 'right'
                        const min2 = crossAxisCoord + overflow[ minSide ]
                        const max2 = crossAxisCoord - overflow[ maxSide ]

                        crossAxisCoord = clamp(min2, crossAxisCoord, max2)

                    }

                    const limitedCoords = limiter.fn({
                        ...state,
                        [ mainAxis ]: mainAxisCoord,
                        [ crossAxis ]: crossAxisCoord,
                    })

                    return {
                        ...limitedCoords,
                        data: {
                            x: limitedCoords.x - x,
                            y: limitedCoords.y - y,
                        },
                    }

                },
            }

        }

        function getNodeName(node) {

            if (isNode(node)) {
                return (node.nodeName || '').toLowerCase()
            }

            return '#document'

        }

        function getWindow(node) {

            let _node$ownerDocument

            return (node == null ? void 0 : ((_node$ownerDocument = node.ownerDocument) == null ? void 0 : _node$ownerDocument.defaultView)) || window

        }

        function getDocumentElement(node) {

            let _reference

            return (_reference = (isNode(node) ? node.ownerDocument : node.document) || window.document) == null ? void 0 : _reference.documentElement

        }

        function isNode(value) {
            return value instanceof Node || value instanceof getWindow(value).Node
        }

        function isElement(value) {
            return value instanceof Element || value instanceof getWindow(value).Element
        }

        function isHTMLElement(value) {
            return value instanceof HTMLElement || value instanceof getWindow(value).HTMLElement
        }

        function isShadowRoot(value) {

            if (typeof ShadowRoot === 'undefined') {
                return false
            }

            return value instanceof ShadowRoot || value instanceof getWindow(value).ShadowRoot

        }

        function isOverflowElement(element) {

            const {
                overflow,
                overflowX,
                overflowY,
                display,
            } = getComputedStyle2(element)

            return /auto|scroll|overlay|hidden|clip/.test(overflow + overflowY + overflowX) && ![ 'inline', 'contents' ].includes(display)

        }

        function isTableElement(element) {
            return [ 'table', 'td', 'th' ].includes(getNodeName(element))
        }

        function isContainingBlock(element) {

            const webkit = isWebKit()
            const css = getComputedStyle2(element)

            return css.transform !== 'none' || css.perspective !== 'none' || (css.containerType ? css.containerType !== 'normal' : false) || !webkit && (css.backdropFilter ? css.backdropFilter !== 'none' : false) || !webkit && (css.filter ? css.filter !== 'none' : false) || [ 'transform', 'perspective', 'filter' ].some(value => (css.willChange || '').includes(value)) || [ 'paint', 'layout', 'strict', 'content' ].some(value => (css.contain || '').includes(value))

        }

        function getContainingBlock(element) {

            let currentNode = getParentNode(element)

            while (isHTMLElement(currentNode) && !isLastTraversableNode(currentNode)) {

                if (isContainingBlock(currentNode)) {
                    return currentNode
                }

                currentNode = getParentNode(currentNode)

            }

            return null

        }

        function isWebKit() {

            if (typeof CSS === 'undefined' || !CSS.supports) { return false }

            return CSS.supports('-webkit-backdrop-filter', 'none')

        }

        function isLastTraversableNode(node) {
            return [ 'html', 'body', '#document' ].includes(getNodeName(node))
        }

        function getComputedStyle2(element) {
            return getWindow(element).getComputedStyle(element)
        }

        function getNodeScroll(element) {

            if (isElement(element)) {

                return {
                    scrollLeft: element.scrollLeft,
                    scrollTop: element.scrollTop,
                }

            }

            return {
                scrollLeft: element.pageXOffset,
                scrollTop: element.pageYOffset,
            }

        }

        function getParentNode(node) {

            if (getNodeName(node) === 'html') {
                return node
            }

            const result = node.assignedSlot || node.parentNode || isShadowRoot(node) && node.host || getDocumentElement(node)

            return isShadowRoot(result) ? result.host : result

        }

        function getNearestOverflowAncestor(node) {

            const parentNode = getParentNode(node)

            if (isLastTraversableNode(parentNode)) {
                return node.ownerDocument ? node.ownerDocument.body : node.body
            }

            if (isHTMLElement(parentNode) && isOverflowElement(parentNode)) {
                return parentNode
            }

            return getNearestOverflowAncestor(parentNode)

        }

        function getOverflowAncestors(node, list, traverseIframes) {

            let _node$ownerDocument2

            if (list === void 0) {
                list = []
            }

            if (traverseIframes === void 0) {
                traverseIframes = true
            }

            const scrollableAncestor = getNearestOverflowAncestor(node)
            const isBody = scrollableAncestor === ((_node$ownerDocument2 = node.ownerDocument) == null ? void 0 : _node$ownerDocument2.body)
            const win = getWindow(scrollableAncestor)

            if (isBody) {
                return list.concat(win, win.visualViewport || [], isOverflowElement(scrollableAncestor) ? scrollableAncestor : [], win.frameElement && traverseIframes ? getOverflowAncestors(win.frameElement) : [])
            }

            return list.concat(scrollableAncestor, getOverflowAncestors(scrollableAncestor, [], traverseIframes))

        }

        function getCssDimensions(element) {

            const css = getComputedStyle2(element)

            let width = parseFloat(css.width) || 0
            let height = parseFloat(css.height) || 0

            const hasOffset = isHTMLElement(element)
            const offsetWidth = hasOffset ? element.offsetWidth : width
            const offsetHeight = hasOffset ? element.offsetHeight : height
            const shouldFallback = round(width) !== offsetWidth || round(height) !== offsetHeight

            if (shouldFallback) {

                width = offsetWidth
                height = offsetHeight

            }

            return {
                width,
                height,
                $: shouldFallback,
            }

        }

        function unwrapElement(element) {
            return !isElement(element) ? element.contextElement : element
        }

        function getScale(element) {

            const domElement = unwrapElement(element)

            if (!isHTMLElement(domElement)) {
                return createCoords(1)
            }

            const rect = domElement.getBoundingClientRect()

            const {
                width,
                height,
                $,
            } = getCssDimensions(domElement)

            let x = ($ ? round(rect.width) : rect.width) / width
            let y = ($ ? round(rect.height) : rect.height) / height

            if (!x || !Number.isFinite(x)) {
                x = 1
            }

            if (!y || !Number.isFinite(y)) {
                y = 1
            }

            return {
                x,
                y,
            }

        }

        const noOffsets = /* @__PURE__ */ createCoords(0)

        function getVisualOffsets(element) {

            const win = getWindow(element)

            if (!isWebKit() || !win.visualViewport) {
                return noOffsets
            }

            return {
                x: win.visualViewport.offsetLeft,
                y: win.visualViewport.offsetTop,
            }

        }

        function shouldAddVisualOffsets(element, isFixed, floatingOffsetParent) {

            if (isFixed === void 0) {
                isFixed = false
            }

            if (!floatingOffsetParent || isFixed && floatingOffsetParent !== getWindow(element)) {
                return false
            }

            return isFixed

        }

        function getBoundingClientRect(element, includeScale, isFixedStrategy, offsetParent) {

            if (includeScale === void 0) {
                includeScale = false
            }

            if (isFixedStrategy === void 0) {
                isFixedStrategy = false
            }

            const clientRect = element.getBoundingClientRect()
            const domElement = unwrapElement(element)

            let scale = createCoords(1)

            if (includeScale) {

                if (offsetParent) {

                    if (isElement(offsetParent)) {
                        scale = getScale(offsetParent)
                    }

                } else {
                    scale = getScale(element)
                }

            }

            const visualOffsets = shouldAddVisualOffsets(domElement, isFixedStrategy, offsetParent) ? getVisualOffsets(domElement) : createCoords(0)

            let x = (clientRect.left + visualOffsets.x) / scale.x
            let y = (clientRect.top + visualOffsets.y) / scale.y
            let width = clientRect.width / scale.x
            let height = clientRect.height / scale.y

            if (domElement) {

                const win = getWindow(domElement)
                const offsetWin = offsetParent && isElement(offsetParent) ? getWindow(offsetParent) : offsetParent

                let currentIFrame = win.frameElement

                while (currentIFrame && offsetParent && offsetWin !== win) {

                    const iframeScale = getScale(currentIFrame)
                    const iframeRect = currentIFrame.getBoundingClientRect()
                    const css = getComputedStyle2(currentIFrame)
                    const left = iframeRect.left + (currentIFrame.clientLeft + parseFloat(css.paddingLeft)) * iframeScale.x
                    const top = iframeRect.top + (currentIFrame.clientTop + parseFloat(css.paddingTop)) * iframeScale.y

                    x *= iframeScale.x
                    y *= iframeScale.y
                    width *= iframeScale.x
                    height *= iframeScale.y
                    x += left
                    y += top
                    currentIFrame = getWindow(currentIFrame).frameElement

                }

            }

            return rectToClientRect({
                width,
                height,
                x,
                y,
            })

        }

        function convertOffsetParentRelativeRectToViewportRelativeRect(_reference) {

            const {
                rect,
                offsetParent,
                strategy,
            } = _reference

            const isOffsetParentAnElement = isHTMLElement(offsetParent)
            const documentElement = getDocumentElement(offsetParent)

            if (offsetParent === documentElement) {
                return rect
            }

            let scroll = {
                scrollLeft: 0,
                scrollTop: 0,
            }

            let scale = createCoords(1)

            const offsets = createCoords(0)

            if (isOffsetParentAnElement || !isOffsetParentAnElement && strategy !== 'fixed') {

                if (getNodeName(offsetParent) !== 'body' || isOverflowElement(documentElement)) {
                    scroll = getNodeScroll(offsetParent)
                }

                if (isHTMLElement(offsetParent)) {

                    const offsetRect = getBoundingClientRect(offsetParent)

                    scale = getScale(offsetParent)
                    offsets.x = offsetRect.x + offsetParent.clientLeft
                    offsets.y = offsetRect.y + offsetParent.clientTop

                }

            }

            return {
                width: rect.width * scale.x,
                height: rect.height * scale.y,
                x: rect.x * scale.x - scroll.scrollLeft * scale.x + offsets.x,
                y: rect.y * scale.y - scroll.scrollTop * scale.y + offsets.y,
            }

        }

        function getClientRects(element) {
            return Array.from(element.getClientRects())
        }

        function getWindowScrollBarX(element) {
            return getBoundingClientRect(getDocumentElement(element)).left + getNodeScroll(element).scrollLeft
        }

        function getDocumentRect(element) {

            const html = getDocumentElement(element)
            const scroll = getNodeScroll(element)
            const body = element.ownerDocument.body
            const width = max(html.scrollWidth, html.clientWidth, body.scrollWidth, body.clientWidth)
            const height = max(html.scrollHeight, html.clientHeight, body.scrollHeight, body.clientHeight)

            let x = -scroll.scrollLeft + getWindowScrollBarX(element)

            const y = -scroll.scrollTop

            if (getComputedStyle2(body).direction === 'rtl') {
                x += max(html.clientWidth, body.clientWidth) - width
            }

            return {
                width,
                height,
                x,
                y,
            }

        }

        function getViewportRect(element, strategy) {

            const win = getWindow(element)
            const html = getDocumentElement(element)
            const visualViewport = win.visualViewport

            let width = html.clientWidth
            let height = html.clientHeight
            let x = 0
            let y = 0

            if (visualViewport) {

                width = visualViewport.width
                height = visualViewport.height

                const visualViewportBased = isWebKit()

                if (!visualViewportBased || visualViewportBased && strategy === 'fixed') {

                    x = visualViewport.offsetLeft
                    y = visualViewport.offsetTop

                }

            }

            return {
                width,
                height,
                x,
                y,
            }

        }

        function getInnerBoundingClientRect(element, strategy) {

            const clientRect = getBoundingClientRect(element, true, strategy === 'fixed')
            const top = clientRect.top + element.clientTop
            const left = clientRect.left + element.clientLeft
            const scale = isHTMLElement(element) ? getScale(element) : createCoords(1)
            const width = element.clientWidth * scale.x
            const height = element.clientHeight * scale.y
            const x = left * scale.x
            const y = top * scale.y

            return {
                width,
                height,
                x,
                y,
            }

        }

        function getClientRectFromClippingAncestor(element, clippingAncestor, strategy) {

            let rect

            if (clippingAncestor === 'viewport') {
                rect = getViewportRect(element, strategy)
            } else if (clippingAncestor === 'document') {
                rect = getDocumentRect(getDocumentElement(element))
            } else if (isElement(clippingAncestor)) {
                rect = getInnerBoundingClientRect(clippingAncestor, strategy)
            } else {

                const visualOffsets = getVisualOffsets(element)

                rect = {
                    ...clippingAncestor,
                    x: clippingAncestor.x - visualOffsets.x,
                    y: clippingAncestor.y - visualOffsets.y,
                }

            }

            return rectToClientRect(rect)

        }

        function hasFixedPositionAncestor(element, stopNode) {

            const parentNode = getParentNode(element)

            if (parentNode === stopNode || !isElement(parentNode) || isLastTraversableNode(parentNode)) {
                return false
            }

            return getComputedStyle2(parentNode).position === 'fixed' || hasFixedPositionAncestor(parentNode, stopNode)

        }

        function getClippingElementAncestors(element, cache) {

            const cachedResult = cache.get(element)

            if (cachedResult) {
                return cachedResult
            }

            let result = getOverflowAncestors(element, [], false).filter(element_ => isElement(element_) && getNodeName(element_) !== 'body')
            let currentContainingBlockComputedStyle = null

            const elementIsFixed = getComputedStyle2(element).position === 'fixed'

            let currentNode = elementIsFixed ? getParentNode(element) : element

            while (isElement(currentNode) && !isLastTraversableNode(currentNode)) {

                const computedStyle = getComputedStyle2(currentNode)
                const currentNodeIsContaining = isContainingBlock(currentNode)

                if (!currentNodeIsContaining && computedStyle.position === 'fixed') {
                    currentContainingBlockComputedStyle = null
                }

                const shouldDropCurrentNode = elementIsFixed ? !currentNodeIsContaining && !currentContainingBlockComputedStyle : !currentNodeIsContaining && computedStyle.position === 'static' && !!currentContainingBlockComputedStyle && [ 'absolute', 'fixed' ].includes(currentContainingBlockComputedStyle.position) || isOverflowElement(currentNode) && !currentNodeIsContaining && hasFixedPositionAncestor(element, currentNode)

                if (shouldDropCurrentNode) {
                    result = result.filter(ancestor => ancestor !== currentNode)
                } else {
                    currentContainingBlockComputedStyle = computedStyle
                }

                currentNode = getParentNode(currentNode)

            }

            cache.set(element, result)

            return result

        }

        function getClippingRect(_reference) {

            const {
                element,
                boundary,
                rootBoundary,
                strategy,
            } = _reference

            const elementClippingAncestors = boundary === 'clippingAncestors' ? getClippingElementAncestors(element, this._c) : [].concat(boundary)
            const clippingAncestors = [ ...elementClippingAncestors, rootBoundary ]
            const firstClippingAncestor = clippingAncestors[ 0 ]

            const clippingRect = clippingAncestors.reduce((accumulatorRect, clippingAncestor) => {

                const rect = getClientRectFromClippingAncestor(element, clippingAncestor, strategy)

                accumulatorRect.top = max(rect.top, accumulatorRect.top)
                accumulatorRect.right = min(rect.right, accumulatorRect.right)
                accumulatorRect.bottom = min(rect.bottom, accumulatorRect.bottom)
                accumulatorRect.left = max(rect.left, accumulatorRect.left)

                return accumulatorRect

            }, getClientRectFromClippingAncestor(element, firstClippingAncestor, strategy))

            return {
                width: clippingRect.right - clippingRect.left,
                height: clippingRect.bottom - clippingRect.top,
                x: clippingRect.left,
                y: clippingRect.top,
            }

        }

        function getDimensions(element) {
            return getCssDimensions(element)
        }

        function getRectRelativeToOffsetParent(element, offsetParent, strategy) {

            const isOffsetParentAnElement = isHTMLElement(offsetParent)
            const documentElement = getDocumentElement(offsetParent)
            const isFixed = strategy === 'fixed'
            const rect = getBoundingClientRect(element, true, isFixed, offsetParent)

            let scroll = {
                scrollLeft: 0,
                scrollTop: 0,
            }

            const offsets = createCoords(0)

            if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {

                if (getNodeName(offsetParent) !== 'body' || isOverflowElement(documentElement)) {
                    scroll = getNodeScroll(offsetParent)
                }

                if (isOffsetParentAnElement) {

                    const offsetRect = getBoundingClientRect(offsetParent, true, isFixed, offsetParent)

                    offsets.x = offsetRect.x + offsetParent.clientLeft
                    offsets.y = offsetRect.y + offsetParent.clientTop

                } else if (documentElement) {
                    offsets.x = getWindowScrollBarX(documentElement)
                }

            }

            return {
                x: rect.left + scroll.scrollLeft - offsets.x,
                y: rect.top + scroll.scrollTop - offsets.y,
                width: rect.width,
                height: rect.height,
            }

        }

        function getTrueOffsetParent(element, polyfill) {

            if (!isHTMLElement(element) || getComputedStyle2(element).position === 'fixed') {
                return null
            }

            if (polyfill) {
                return polyfill(element)
            }

            return element.offsetParent

        }

        function getOffsetParent(element, polyfill) {

            const window2 = getWindow(element)

            if (!isHTMLElement(element)) {
                return window2
            }

            let offsetParent = getTrueOffsetParent(element, polyfill)

            while (offsetParent && isTableElement(offsetParent) && getComputedStyle2(offsetParent).position === 'static') {
                offsetParent = getTrueOffsetParent(offsetParent, polyfill)
            }

            if (offsetParent && (getNodeName(offsetParent) === 'html' || getNodeName(offsetParent) === 'body' && getComputedStyle2(offsetParent).position === 'static' && !isContainingBlock(offsetParent))) {
                return window2
            }

            return offsetParent || getContainingBlock(element) || window2

        }

        const getElementRects = async function(_reference) {

            const {
                reference,
                floating,
                strategy,
            } = _reference

            const getOffsetParentFunction = this.getOffsetParent || getOffsetParent
            const getDimensionsFunction = this.getDimensions

            return {
                reference: getRectRelativeToOffsetParent(reference, await getOffsetParentFunction(floating), strategy),
                floating: {
                    x: 0,
                    y: 0,
                    ...await getDimensionsFunction(floating),
                },
            }

        }

        function isRTL(element) {
            return getComputedStyle2(element).direction === 'rtl'
        }

        const platform = {
            convertOffsetParentRelativeRectToViewportRelativeRect,
            getDocumentElement,
            getClippingRect,
            getOffsetParent,
            getElementRects,
            getClientRects,
            getDimensions,
            getScale,
            isElement,
            isRTL,
        }

        function observeMove(element, onMove) {

            let io = null
            let timeoutId

            const root = getDocumentElement(element)

            function cleanup() {

                clearTimeout(timeoutId)
                io && io.disconnect()
                io = null

            }

            function refresh(skip, threshold) {

                if (skip === void 0) {
                    skip = false
                }

                if (threshold === void 0) {
                    threshold = 1
                }

                cleanup()

                const {
                    left,
                    top,
                    width,
                    height,
                } = element.getBoundingClientRect()

                if (!skip) {
                    onMove()
                }

                if (!width || !height) {
                    return
                }

                const insetTop = floor(top)
                const insetRight = floor(root.clientWidth - (left + width))
                const insetBottom = floor(root.clientHeight - (top + height))
                const insetLeft = floor(left)
                const rootMargin = `${ -insetTop }px ${ -insetRight }px ${ -insetBottom }px ${ -insetLeft }px`

                const options = {
                    rootMargin,
                    threshold: max(0, min(1, threshold)) || 1,
                }

                let isFirstUpdate = true

                function handleObserve(entries) {

                    const ratio = entries[ 0 ].intersectionRatio

                    if (ratio !== threshold) {

                        if (!isFirstUpdate) {
                            return refresh()
                        }

                        if (!ratio) {

                            timeoutId = setTimeout(() => {
                                refresh(false, 1e-7)
                            }, 100)

                        } else {
                            refresh(false, ratio)
                        }

                    }

                    isFirstUpdate = false

                }

                try {

                    io = new IntersectionObserver(handleObserve, {
                        ...options,
                        root: root.ownerDocument,
                    })

                } catch (e) {
                    io = new IntersectionObserver(handleObserve, options)
                }

                io.observe(element)

            }

            refresh(true)

            return cleanup

        }

        function autoUpdate(reference, floating, update, options) {

            if (options === void 0) {
                options = {}
            }

            const {
                ancestorScroll = true,
                ancestorResize = true,
                elementResize = typeof ResizeObserver === 'function',
                layoutShift = typeof IntersectionObserver === 'function',
                animationFrame = false,
            } = options

            const referenceElement = unwrapElement(reference)
            const ancestors = ancestorScroll || ancestorResize ? [ ...referenceElement ? getOverflowAncestors(referenceElement) : [], ...getOverflowAncestors(floating) ] : []

            for (const ancestor of ancestors) {

                ancestorScroll && ancestor.addEventListener('scroll', update, {
                    passive: true,
                })

                ancestorResize && ancestor.addEventListener('resize', update)

            }

            const cleanupIo = referenceElement && layoutShift ? observeMove(referenceElement, update) : null

            let reobserveFrame = -1
            let resizeObserver = null

            if (elementResize) {

                resizeObserver = new ResizeObserver(_reference => {

                    const [ firstEntry ] = _reference

                    if (firstEntry && firstEntry.target === referenceElement && resizeObserver) {

                        resizeObserver.unobserve(floating)
                        cancelAnimationFrame(reobserveFrame)

                        reobserveFrame = requestAnimationFrame(() => {
                            resizeObserver && resizeObserver.observe(floating)
                        })

                    }

                    update()

                })

                if (referenceElement && !animationFrame) {
                    resizeObserver.observe(referenceElement)
                }

                resizeObserver.observe(floating)

            }

            let frameId
            let previousReferenceRect = animationFrame ? getBoundingClientRect(reference) : null

            if (animationFrame) {
                frameLoop()
            }

            function frameLoop() {

                const nextReferenceRect = getBoundingClientRect(reference)

                if (previousReferenceRect && (nextReferenceRect.x !== previousReferenceRect.x || nextReferenceRect.y !== previousReferenceRect.y || nextReferenceRect.width !== previousReferenceRect.width || nextReferenceRect.height !== previousReferenceRect.height)) {
                    update()
                }

                previousReferenceRect = nextReferenceRect
                frameId = requestAnimationFrame(frameLoop)

            }

            update()

            return () => {

                for (const ancestor of ancestors) {

                    ancestorScroll && ancestor.removeEventListener('scroll', update)
                    ancestorResize && ancestor.removeEventListener('resize', update)

                }

                cleanupIo && cleanupIo()
                resizeObserver && resizeObserver.disconnect()
                resizeObserver = null

                if (animationFrame) {
                    cancelAnimationFrame(frameId)
                }

            }

        }

        const computePosition2 = (reference, floating, options) => {

            const cache = /* @__PURE__ */ new Map()

            const mergedOptions = {
                platform,
                ...options,
            }

            const platformWithCache = {
                ...mergedOptions.platform,
                _c: cache,
            }

            return computePosition(reference, floating, {
                ...mergedOptions,
                platform: platformWithCache,
            })

        }

        function source_default(Alpine19) {

            Alpine19.magic('anchor', element => {

                if (!element._x_anchor) { throw 'Alpine: No x-anchor directive found on element using $anchor...' }

                return element._x_anchor

            })

            Alpine19.interceptClone((from, to) => {

                if (from && from._x_anchor && !to._x_anchor) {
                    to._x_anchor = from._x_anchor
                }

            })

            Alpine19.directive('anchor', Alpine19.skipDuringClone((element, { expression, modifiers, value }, { cleanup, evaluate: evaluate2 }) => {

                const { placement, offsetValue, unstyled } = getOptions(modifiers)

                element._x_anchor = Alpine19.reactive({ x: 0, y: 0 })

                const reference = evaluate2(expression)

                if (!reference) { throw 'Alpine: no element provided to x-anchor...' }

                const compute = () => {

                    let previousValue

                    computePosition2(reference, element, {
                        placement,
                        middleware: [ flip(), shift({ padding: 5 }), offset(offsetValue) ],
                    }).then(({ x, y }) => {

                        unstyled || setStyles(element, x, y)

                        if (JSON.stringify({ x, y }) !== previousValue) {

                            element._x_anchor.x = x
                            element._x_anchor.y = y

                        }

                        previousValue = JSON.stringify({ x, y })

                    })

                }

                const release = autoUpdate(reference, element, () => compute())

                cleanup(() => release())

            }, (element, { expression, modifiers, value }, { cleanup, evaluate: evaluate2 }) => {

                const { placement, offsetValue, unstyled } = getOptions(modifiers)

                if (element._x_anchor) {
                    unstyled || setStyles(element, element._x_anchor.x, element._x_anchor.y)
                }

            }))

        }

        function setStyles(element, x, y) {

            Object.assign(element.style, {
                left: `${ x }px`,
                top: `${ y }px`,
                position: 'absolute',
            })

        }

        function getOptions(modifiers) {

            const positions = [ 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end' ]
            const placement = positions.find(index => modifiers.includes(index))

            let offsetValue = 0

            if (modifiers.includes('offset')) {

                const index = modifiers.findIndex(index_ => index_ === 'offset')

                offsetValue = modifiers[ index + 1 ] !== void 0 ? Number(modifiers[ index + 1 ]) : offsetValue

            }

            const unstyled = modifiers.includes('no-style')

            return { placement, offsetValue, unstyled }

        }

        var module_default = source_default

    },
})

// Node_modules/nprogress/nprogress.js
const require_nprogress = __commonJS({
    'node_modules/nprogress/nprogress.js'(exports, module) {

        (function(root, factory) {

            if (typeof define === 'function' && define.amd) {
                define(factory)
            } else if (typeof exports === 'object') {
                module.exports = factory()
            } else {
                root.NProgress = factory()
            }

        })(exports, function() {

            const NProgress2 = {}

            NProgress2.version = '0.2.0'

            const Settings = NProgress2.settings = {
                minimum: 0.08,
                easing: 'ease',
                positionUsing: '',
                speed: 200,
                trickle: true,
                trickleRate: 0.02,
                trickleSpeed: 800,
                showSpinner: true,
                barSelector: '[role="bar"]',
                spinnerSelector: '[role="spinner"]',
                parent: 'body',
                template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
            }

            NProgress2.configure = function(options) {

                let key, value

                for (key in options) {

                    value = options[ key ]

                    if (value !== void 0 && options.hasOwnProperty(key)) { Settings[ key ] = value }

                }

                return this

            }

            NProgress2.status = null

            NProgress2.set = function(n) {

                const started = NProgress2.isStarted()

                n = clamp(n, Settings.minimum, 1)
                NProgress2.status = n === 1 ? null : n

                const progress = NProgress2.render(!started), bar = progress.querySelector(Settings.barSelector), speed = Settings.speed, ease = Settings.easing

                progress.offsetWidth

                queue(function(next) {

                    if (Settings.positionUsing === '') { Settings.positionUsing = NProgress2.getPositioningCSS() }

                    css(bar, barPositionCSS(n, speed, ease))

                    if (n === 1) {

                        css(progress, {
                            transition: 'none',
                            opacity: 1,
                        })

                        progress.offsetWidth

                        setTimeout(function() {

                            css(progress, {
                                transition: `all ${ speed }ms linear`,
                                opacity: 0,
                            })

                            setTimeout(function() {

                                NProgress2.remove()
                                next()

                            }, speed)

                        }, speed)

                    } else {
                        setTimeout(next, speed)
                    }

                })

                return this

            }

            NProgress2.isStarted = function() {
                return typeof NProgress2.status === 'number'
            }

            NProgress2.start = function() {

                if (!NProgress2.status) { NProgress2.set(0) }

                var work = function() {

                    setTimeout(function() {

                        if (!NProgress2.status) { return }

                        NProgress2.trickle()
                        work()

                    }, Settings.trickleSpeed)

                }

                if (Settings.trickle) { work() }

                return this

            }

            NProgress2.done = function(force) {

                if (!force && !NProgress2.status) { return this }

                return NProgress2.inc(0.3 + 0.5 * Math.random()).set(1)

            }

            NProgress2.inc = function(amount) {

                let n = NProgress2.status

                if (!n) {
                    return NProgress2.start()
                }

                if (typeof amount !== 'number') {
                    amount = (1 - n) * clamp(Math.random() * n, 0.1, 0.95)
                }

                n = clamp(n + amount, 0, 0.994)

                return NProgress2.set(n)

            }

            NProgress2.trickle = function() {
                return NProgress2.inc(Math.random() * Settings.trickleRate)
            };

            (function() {

                let initial = 0, current = 0

                NProgress2.promise = function($promise) {

                    if (!$promise || $promise.state() === 'resolved') {
                        return this
                    }

                    if (current === 0) {
                        NProgress2.start()
                    }

                    initial++
                    current++

                    $promise.always(function() {

                        current--

                        if (current === 0) {

                            initial = 0
                            NProgress2.done()

                        } else {
                            NProgress2.set((initial - current) / initial)
                        }

                    })

                    return this

                }

            })()

            NProgress2.render = function(fromStart) {

                if (NProgress2.isRendered()) { return document.getElementById('nprogress') }

                addClass(document.documentElement, 'nprogress-busy')

                const progress = document.createElement('div')

                progress.id = 'nprogress'
                progress.innerHTML = Settings.template

                let bar = progress.querySelector(Settings.barSelector), perc = fromStart ? '-100' : toBarPerc(NProgress2.status || 0), parent = document.querySelector(Settings.parent), spinner

                css(bar, {
                    transition: 'all 0 linear',
                    transform: `translate3d(${ perc }%,0,0)`,
                })

                if (!Settings.showSpinner) {

                    spinner = progress.querySelector(Settings.spinnerSelector)
                    spinner && removeElement(spinner)

                }

                if (parent != document.body) {
                    addClass(parent, 'nprogress-custom-parent')
                }

                parent.appendChild(progress)

                return progress

            }

            NProgress2.remove = function() {

                removeClass(document.documentElement, 'nprogress-busy')
                removeClass(document.querySelector(Settings.parent), 'nprogress-custom-parent')

                const progress = document.getElementById('nprogress')

                progress && removeElement(progress)

            }

            NProgress2.isRendered = function() {
                return !!document.getElementById('nprogress')
            }

            NProgress2.getPositioningCSS = function() {

                const bodyStyle = document.body.style
                const vendorPrefix = 'WebkitTransform' in bodyStyle ? 'Webkit' : ('MozTransform' in bodyStyle ? 'Moz' : 'msTransform' in bodyStyle ? 'ms' : 'OTransform' in bodyStyle ? 'O' : '')

                if (`${ vendorPrefix }Perspective` in bodyStyle) {
                    return 'translate3d'
                }

                if (`${ vendorPrefix }Transform` in bodyStyle) {
                    return 'translate'
                }

                return 'margin'

            }

            function clamp(n, min, max) {

                if (n < min) { return min }

                if (n > max) { return max }

                return n

            }

            function toBarPerc(n) {
                return (-1 + n) * 100
            }

            function barPositionCSS(n, speed, ease) {

                let barCSS

                if (Settings.positionUsing === 'translate3d') {
                    barCSS = { transform: `translate3d(${ toBarPerc(n) }%,0,0)` }
                } else if (Settings.positionUsing === 'translate') {
                    barCSS = { transform: `translate(${ toBarPerc(n) }%,0)` }
                } else {
                    barCSS = { 'margin-left': `${ toBarPerc(n) }%` }
                }

                barCSS.transition = `all ${ speed }ms ${ ease }`

                return barCSS

            }

            var queue = function() {

                const pending = []

                function next() {

                    const function_ = pending.shift()

                    if (function_) {
                        function_(next)
                    }

                }

                return function(function_) {

                    pending.push(function_)

                    if (pending.length == 1) { next() }

                }

            }()

            var css = function() {

                const cssPrefixes = [ 'Webkit', 'O', 'Moz', 'ms' ], cssProperties = {}

                function camelCase(string) {

                    return string.replace(/^-ms-/, 'ms-').replace(/-([\da-z])/gi, function(match, letter) {
                        return letter.toUpperCase()
                    })

                }

                function getVendorProperty(name) {

                    const style = document.body.style

                    if (name in style) { return name }

                    let index = cssPrefixes.length, capName = name.charAt(0).toUpperCase() + name.slice(1), vendorName

                    while (index--) {

                        vendorName = cssPrefixes[ index ] + capName

                        if (vendorName in style) { return vendorName }

                    }

                    return name

                }

                function getStyleProperty(name) {

                    name = camelCase(name)

                    return cssProperties[ name ] || (cssProperties[ name ] = getVendorProperty(name))

                }

                function applyCss(element, property, value) {

                    property = getStyleProperty(property)
                    element.style[ property ] = value

                }

                return function(element, properties2) {

                    let arguments_ = arguments, property, value

                    if (arguments_.length == 2) {

                        for (property in properties2) {

                            value = properties2[ property ]

                            if (value !== void 0 && properties2.hasOwnProperty(property)) { applyCss(element, property, value) }

                        }

                    } else {
                        applyCss(element, arguments_[ 1 ], arguments_[ 2 ])
                    }

                }

            }()

            function hasClass(element, name) {

                const list = typeof element === 'string' ? element : classList(element)

                return list.indexOf(` ${ name } `) >= 0

            }

            function addClass(element, name) {

                const oldList = classList(element), newList = oldList + name

                if (hasClass(oldList, name)) { return }

                element.className = newList.substring(1)

            }

            function removeClass(element, name) {

                let oldList = classList(element), newList

                if (!hasClass(element, name)) { return }

                newList = oldList.replace(` ${ name } `, ' ')
                element.className = newList.substring(1, newList.length - 1)

            }

            function classList(element) {
                return (` ${ element.className || '' } `).replace(/\s+/gi, ' ')
            }

            function removeElement(element) {
                element && element.parentNode && element.parentNode.removeChild(element)
            }

            return NProgress2

        })

    },
})

// Node_modules/@alpinejs/morph/dist/module.cjs.js
const require_module_cjs8 = __commonJS({
    'node_modules/@alpinejs/morph/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            morph: () => source_default,
        })

        module.exports = __toCommonJS(module_exports)

        function morph3(from, toHtml, options) {

            monkeyPatchDomSetAttributeToAllowAtSymbols()

            let fromElement
            let toElement
            let key, lookahead, updating, updated, removing, removed, adding, added

            function assignOptions(options2 = {}) {

                const defaultGetKey = element => element.getAttribute('key')

                const noop = () => {
                }

                updating = options2.updating || noop
                updated = options2.updated || noop
                removing = options2.removing || noop
                removed = options2.removed || noop
                adding = options2.adding || noop
                added = options2.added || noop
                key = options2.key || defaultGetKey
                lookahead = options2.lookahead || false

            }

            function patch(from2, to) {

                if (differentElementNamesTypesOrKeys(from2, to)) {
                    return swapElements(from2, to)
                }

                let updateChildrenOnly = false

                if (shouldSkip(updating, from2, to, () => updateChildrenOnly = true)) { return }

                if (from2.nodeType === 1 && window.Alpine) {

                    window.Alpine.cloneNode(from2, to)

                    if (from2._x_teleport && to._x_teleport) {
                        patch(from2._x_teleport, to._x_teleport)
                    }

                }

                if (textOrComment(to)) {

                    patchNodeValue(from2, to)
                    updated(from2, to)

                    return

                }

                if (!updateChildrenOnly) {
                    patchAttributes(from2, to)
                }

                updated(from2, to)
                patchChildren(from2, to)

            }

            function differentElementNamesTypesOrKeys(from2, to) {
                return from2.nodeType != to.nodeType || from2.nodeName != to.nodeName || getKey(from2) != getKey(to)
            }

            function swapElements(from2, to) {

                if (shouldSkip(removing, from2)) { return }

                const toCloned = to.cloneNode(true)

                if (shouldSkip(adding, toCloned)) { return }

                from2.replaceWith(toCloned)
                removed(from2)
                added(toCloned)

            }

            function patchNodeValue(from2, to) {

                const value = to.nodeValue

                if (from2.nodeValue !== value) {
                    from2.nodeValue = value
                }

            }

            function patchAttributes(from2, to) {

                if (from2._x_transitioning) { return }

                if (from2._x_isShown && !to._x_isShown) {
                    return
                }

                if (!from2._x_isShown && to._x_isShown) {
                    return
                }

                const domAttributes = Array.from(from2.attributes)
                const toAttributes = Array.from(to.attributes)

                for (let index = domAttributes.length - 1; index >= 0; index--) {

                    const name = domAttributes[ index ].name

                    if (!to.hasAttribute(name)) {
                        from2.removeAttribute(name)
                    }

                }

                for (let index = toAttributes.length - 1; index >= 0; index--) {

                    const name = toAttributes[ index ].name
                    const value = toAttributes[ index ].value

                    if (from2.getAttribute(name) !== value) {
                        from2.setAttribute(name, value)
                    }

                }

            }

            function patchChildren(from2, to) {

                const fromKeys = keyToMap(from2.children)
                const fromKeyHoldovers = {}

                let currentTo = getFirstNode(to)
                let currentFrom = getFirstNode(from2)

                while (currentTo) {

                    seedingMatchingId(currentTo, currentFrom)

                    const toKey = getKey(currentTo)

                    let fromKey = getKey(currentFrom)

                    if (!currentFrom) {

                        if (toKey && fromKeyHoldovers[ toKey ]) {

                            const holdover = fromKeyHoldovers[ toKey ]

                            from2.appendChild(holdover)
                            currentFrom = holdover

                        } else {

                            if (!shouldSkip(adding, currentTo)) {

                                const clone = currentTo.cloneNode(true)

                                from2.appendChild(clone)
                                added(clone)

                            }

                            currentTo = getNextSibling(to, currentTo)

                            continue

                        }

                    }

                    const isIf = node => node && node.nodeType === 8 && node.textContent === '[if BLOCK]><![endif]'
                    const isEnd = node => node && node.nodeType === 8 && node.textContent === '[if ENDBLOCK]><![endif]'

                    if (isIf(currentTo) && isIf(currentFrom)) {

                        let nestedIfCount = 0

                        const fromBlockStart = currentFrom

                        while (currentFrom) {

                            const next = getNextSibling(from2, currentFrom)

                            if (isIf(next)) {
                                nestedIfCount++
                            } else if (isEnd(next) && nestedIfCount > 0) {
                                nestedIfCount--
                            } else if (isEnd(next) && nestedIfCount === 0) {

                                currentFrom = next

                                break

                            }

                            currentFrom = next

                        }

                        const fromBlockEnd = currentFrom

                        nestedIfCount = 0

                        const toBlockStart = currentTo

                        while (currentTo) {

                            const next = getNextSibling(to, currentTo)

                            if (isIf(next)) {
                                nestedIfCount++
                            } else if (isEnd(next) && nestedIfCount > 0) {
                                nestedIfCount--
                            } else if (isEnd(next) && nestedIfCount === 0) {

                                currentTo = next

                                break

                            }

                            currentTo = next

                        }

                        const toBlockEnd = currentTo
                        const fromBlock = new Block(fromBlockStart, fromBlockEnd)
                        const toBlock = new Block(toBlockStart, toBlockEnd)

                        patchChildren(fromBlock, toBlock)

                        continue

                    }

                    if (currentFrom.nodeType === 1 && lookahead && !currentFrom.isEqualNode(currentTo)) {

                        let nextToElementSibling = getNextSibling(to, currentTo)
                        let found = false

                        while (!found && nextToElementSibling) {

                            if (nextToElementSibling.nodeType === 1 && currentFrom.isEqualNode(nextToElementSibling)) {

                                found = true
                                currentFrom = addNodeBefore(from2, currentTo, currentFrom)
                                fromKey = getKey(currentFrom)

                            }

                            nextToElementSibling = getNextSibling(to, nextToElementSibling)

                        }

                    }

                    if (toKey !== fromKey) {

                        if (!toKey && fromKey) {

                            fromKeyHoldovers[ fromKey ] = currentFrom
                            currentFrom = addNodeBefore(from2, currentTo, currentFrom)
                            fromKeyHoldovers[ fromKey ].remove()
                            currentFrom = getNextSibling(from2, currentFrom)
                            currentTo = getNextSibling(to, currentTo)

                            continue

                        }

                        if (toKey && !fromKey) {

                            if (fromKeys[ toKey ]) {

                                currentFrom.replaceWith(fromKeys[ toKey ])
                                currentFrom = fromKeys[ toKey ]

                            }

                        }

                        if (toKey && fromKey) {

                            const fromKeyNode = fromKeys[ toKey ]

                            if (fromKeyNode) {

                                fromKeyHoldovers[ fromKey ] = currentFrom
                                currentFrom.replaceWith(fromKeyNode)
                                currentFrom = fromKeyNode

                            } else {

                                fromKeyHoldovers[ fromKey ] = currentFrom
                                currentFrom = addNodeBefore(from2, currentTo, currentFrom)
                                fromKeyHoldovers[ fromKey ].remove()
                                currentFrom = getNextSibling(from2, currentFrom)
                                currentTo = getNextSibling(to, currentTo)

                                continue

                            }

                        }

                    }

                    const currentFromNext = currentFrom && getNextSibling(from2, currentFrom)

                    patch(currentFrom, currentTo)
                    currentTo = currentTo && getNextSibling(to, currentTo)
                    currentFrom = currentFromNext

                }

                const removals = []

                while (currentFrom) {

                    if (!shouldSkip(removing, currentFrom)) { removals.push(currentFrom) }

                    currentFrom = getNextSibling(from2, currentFrom)

                }

                while (removals.length) {

                    const domForRemoval = removals.shift()

                    domForRemoval.remove()
                    removed(domForRemoval)

                }

            }

            function getKey(element) {
                return element && element.nodeType === 1 && key(element)
            }

            function keyToMap(els2) {

                const map = {}

                for (const element of els2) {

                    const theKey = getKey(element)

                    if (theKey) {
                        map[ theKey ] = element
                    }

                }

                return map

            }

            function addNodeBefore(parent, node, beforeMe) {

                if (!shouldSkip(adding, node)) {

                    const clone = node.cloneNode(true)

                    parent.insertBefore(clone, beforeMe)
                    added(clone)

                    return clone

                }

                return node

            }

            assignOptions(options)
            fromElement = from
            toElement = typeof toHtml === 'string' ? createElement(toHtml) : toHtml

            if (window.Alpine && window.Alpine.closestDataStack && !from._x_dataStack) {

                toElement._x_dataStack = window.Alpine.closestDataStack(from)
                toElement._x_dataStack && window.Alpine.cloneNode(from, toElement)

            }

            patch(from, toElement)
            fromElement = void 0
            toElement = void 0

            return from

        }

        morph3.step = () => {
        }

        morph3.log = () => {
        }

        function shouldSkip(hook, ...arguments_) {

            let skip = false

            hook(...arguments_, () => skip = true)

            return skip

        }

        let patched = false

        function createElement(html) {

            const template = document.createElement('template')

            template.innerHTML = html

            return template.content.firstElementChild

        }

        function textOrComment(element) {
            return element.nodeType === 3 || element.nodeType === 8
        }

        var Block = class {

            constructor(start2, end) {

                this.startComment = start2
                this.endComment = end

            }

            get children() {

                const children = []

                let currentNode = this.startComment.nextSibling

                while (currentNode && currentNode !== this.endComment) {

                    children.push(currentNode)
                    currentNode = currentNode.nextSibling

                }

                return children

            }

            appendChild(child) {
                this.endComment.before(child)
            }

            get firstChild() {

                const first2 = this.startComment.nextSibling

                if (first2 === this.endComment) { return }

                return first2

            }

            nextNode(reference) {

                const next = reference.nextSibling

                if (next === this.endComment) { return }

                return next

            }

            insertBefore(newNode, reference) {

                reference.before(newNode)

                return newNode

            }

        }

        function getFirstNode(parent) {
            return parent.firstChild
        }

        function getNextSibling(parent, reference) {

            let next

            if (parent instanceof Block) {
                next = parent.nextNode(reference)
            } else {
                next = reference.nextSibling
            }

            return next

        }

        function monkeyPatchDomSetAttributeToAllowAtSymbols() {

            if (patched) { return }

            patched = true

            const original = Element.prototype.setAttribute
            const hostDiv = document.createElement('div')

            Element.prototype.setAttribute = function newSetAttribute(name, value) {

                if (!name.includes('@')) {
                    return original.call(this, name, value)
                }

                hostDiv.innerHTML = `<span ${ name }="${ value }"></span>`

                const attribute = hostDiv.firstElementChild.getAttributeNode(name)

                hostDiv.firstElementChild.removeAttributeNode(attribute)
                this.setAttributeNode(attribute)

            }

        }

        function seedingMatchingId(to, from) {

            const fromId = from && from._x_bindings && from._x_bindings.id

            if (!fromId) { return }

            to.setAttribute('id', fromId)
            to.id = fromId

        }

        function source_default(Alpine19) {
            Alpine19.morph = morph3
        }

        var module_default = source_default

    },
})

// Node_modules/@alpinejs/mask/dist/module.cjs.js
const require_module_cjs9 = __commonJS({
    'node_modules/@alpinejs/mask/dist/module.cjs.js'(exports, module) {

        const __defProp2 = Object.defineProperty
        const __getOwnPropertyDesc2 = Object.getOwnPropertyDescriptor
        const __getOwnPropertyNames2 = Object.getOwnPropertyNames
        const __hasOwnProperty2 = Object.prototype.hasOwnProperty

        const __export = (target, all2) => {
            for (const name in all2) { __defProp2(target, name, { get: all2[ name ], enumerable: true }) }
        }

        const __copyProperties2 = (to, from, except, desc) => {

            if (from && typeof from === 'object' || typeof from === 'function') {
                for (const key of __getOwnPropertyNames2(from)) { if (!__hasOwnProperty2.call(to, key) && key !== except) { __defProp2(to, key, { get: () => from[ key ], enumerable: !(desc = __getOwnPropertyDesc2(from, key)) || desc.enumerable }) } }
            }

            return to

        }

        const __toCommonJS = module_ => __copyProperties2(__defProp2({}, '__esModule', { value: true }), module_)
        const module_exports = {}

        __export(module_exports, {
            default: () => module_default,
            mask: () => source_default,
            stripDown: () => stripDown,
        })

        module.exports = __toCommonJS(module_exports)

        function source_default(Alpine19) {

            Alpine19.directive('mask', (element, { value, expression }, { effect, evaluateLater, cleanup }) => {

                let templateFunction = () => expression
                let lastInputValue = ''

                queueMicrotask(() => {

                    if ([ 'function', 'dynamic' ].includes(value)) {

                        const evaluator = evaluateLater(expression)

                        effect(() => {

                            templateFunction = input => {

                                let result

                                Alpine19.dontAutoEvaluateFunctions(() => {

                                    evaluator(value2 => {
                                        result = typeof value2 === 'function' ? value2(input) : value2
                                    }, { scope: {
                                        $input: input,
                                        $money: formatMoney.bind({ el: element }),
                                    } })

                                })

                                return result

                            }

                            processInputValue(element, false)

                        })

                    } else {
                        processInputValue(element, false)
                    }

                    if (element._x_model) { element._x_model.set(element.value) }

                })

                const controller = new AbortController()

                cleanup(() => {
                    controller.abort()
                })

                element.addEventListener('input', () => processInputValue(element), {
                    signal: controller.signal,
                    capture: true,
                })

                element.addEventListener('blur', () => processInputValue(element, false), { signal: controller.signal })

                function processInputValue(element2, shouldRestoreCursor = true) {

                    const input = element2.value
                    const template = templateFunction(input)

                    if (!template || template === 'false') { return false }

                    if (lastInputValue.length - element2.value.length === 1) {
                        return lastInputValue = element2.value
                    }

                    const setInput = () => {
                        lastInputValue = element2.value = formatInput(input, template)
                    }

                    if (shouldRestoreCursor) {

                        restoreCursorPosition(element2, template, () => {
                            setInput()
                        })

                    } else {
                        setInput()
                    }

                }

                function formatInput(input, template) {

                    if (input === '') { return '' }

                    const strippedDownInput = stripDown(template, input)
                    const rebuiltInput = buildUp(template, strippedDownInput)

                    return rebuiltInput

                }

            }).before('model')

        }

        function restoreCursorPosition(element, template, callback) {

            const cursorPosition = element.selectionStart
            const unformattedValue = element.value

            callback()

            const beforeLeftOfCursorBeforeFormatting = unformattedValue.slice(0, cursorPosition)
            const newPosition = buildUp(template, stripDown(template, beforeLeftOfCursorBeforeFormatting)).length

            element.setSelectionRange(newPosition, newPosition)

        }

        function stripDown(template, input) {

            let inputToBeStripped = input
            let output = ''

            const regexes = {
                9: /[0-9]/,
                a: /[a-zA-Z]/,
                '*': /[a-zA-Z0-9]/,
            }

            let wildcardTemplate = ''

            for (let index = 0; index < template.length; index++) {

                if ([ '9', 'a', '*' ].includes(template[ index ])) {

                    wildcardTemplate += template[ index ]

                    continue

                }

                for (let index_ = 0; index_ < inputToBeStripped.length; index_++) {

                    if (inputToBeStripped[ index_ ] === template[ index ]) {

                        inputToBeStripped = inputToBeStripped.slice(0, index_) + inputToBeStripped.slice(index_ + 1)

                        break

                    }

                }

            }

            for (let index = 0; index < wildcardTemplate.length; index++) {

                let found = false

                for (let index_ = 0; index_ < inputToBeStripped.length; index_++) {

                    if (regexes[ wildcardTemplate[ index ] ].test(inputToBeStripped[ index_ ])) {

                        output += inputToBeStripped[ index_ ]
                        inputToBeStripped = inputToBeStripped.slice(0, index_) + inputToBeStripped.slice(index_ + 1)
                        found = true

                        break

                    }

                }

                if (!found) { break }

            }

            return output

        }

        function buildUp(template, input) {

            const clean = Array.from(input)

            let output = ''

            for (let index = 0; index < template.length; index++) {

                if (![ '9', 'a', '*' ].includes(template[ index ])) {

                    output += template[ index ]

                    continue

                }

                if (clean.length === 0) { break }

                output += clean.shift()

            }

            return output

        }

        function formatMoney(input, delimiter = '.', thousands, precision = 2) {

            if (input === '-') { return '-' }

            if (/^\D+$/.test(input)) { return '9' }

            if (thousands === null || thousands === void 0) {
                thousands = delimiter === ',' ? '.' : ','
            }

            const addThousands = (input2, thousands2) => {

                let output = ''
                let counter = 0

                for (let index = input2.length - 1; index >= 0; index--) {

                    if (input2[ index ] === thousands2) { continue }

                    if (counter === 3) {

                        output = input2[ index ] + thousands2 + output
                        counter = 0

                    } else {
                        output = input2[ index ] + output
                    }

                    counter++

                }

                return output

            }

            const minus = input.startsWith('-') ? '-' : ''
            const strippedInput = input.replaceAll(new RegExp(`[^0-9\\${ delimiter }]`, 'g'), '')

            let template = Array.from({ length: strippedInput.split(delimiter)[ 0 ].length }).fill('9').join('')

            template = `${ minus }${ addThousands(template, thousands) }`

            if (precision > 0 && input.includes(delimiter)) { template += `${ delimiter }${ '9'.repeat(precision) }` }

            queueMicrotask(() => {

                if (this.el.value.endsWith(delimiter)) { return }

                if (this.el.value[ this.el.selectionStart - 1 ] === delimiter) {
                    this.el.setSelectionRange(this.el.selectionStart - 1, this.el.selectionStart - 1)
                }

            })

            return template

        }

        var module_default = source_default

    },
})

// Js/utils.js
const Bag = class {

    constructor() {
        this.arrays = {}
    }

    add(key, value) {

        if (!this.arrays[ key ]) { this.arrays[ key ] = [] }

        this.arrays[ key ].push(value)

    }

    remove(key) {
        if (this.arrays[ key ]) { delete this.arrays[ key ] }
    }

    get(key) {
        return this.arrays[ key ] || []
    }

    each(key, callback) {
        return this.get(key).forEach(callback)
    }

}

const WeakBag = class {

    constructor() {
        this.arrays = /* @__PURE__ */ new WeakMap()
    }

    add(key, value) {

        if (!this.arrays.has(key)) { this.arrays.set(key, []) }

        this.arrays.get(key).push(value)

    }

    remove(key) {
        if (this.arrays.has(key)) { this.arrays.delete(key, []) }
    }

    get(key) {
        return this.arrays.has(key) ? this.arrays.get(key) : []
    }

    each(key, callback) {
        return this.get(key).forEach(callback)
    }

}

function dispatch(target, name, detail = {}, bubbles = true) {

    target.dispatchEvent(new CustomEvent(name, {
        detail,
        bubbles,
        composed: true,
        cancelable: true,
    }))

}

function listen(target, name, handler) {

    target.addEventListener(name, handler)

    return () => target.removeEventListener(name, handler)

}

function isObjecty(subject) {
    return typeof subject === 'object' && subject !== null
}

function isObject(subject) {
    return isObjecty(subject) && !isArray(subject)
}

function isArray(subject) {
    return Array.isArray(subject)
}

function isFunction(subject) {
    return typeof subject === 'function'
}

function isPrimitive(subject) {
    return typeof subject !== 'object' || subject === null
}

function deepClone(object) {
    return JSON.parse(JSON.stringify(object))
}

function dataGet(object, key) {

    if (key === '') { return object }

    return key.split('.').reduce((carry, index) => {

        if (carry === void 0) { return void 0 }

        return carry[ index ]

    }, object)

}

function dataSet(object, key, value) {

    const segments = key.split('.')

    if (segments.length === 1) {
        return object[ key ] = value
    }

    const firstSegment = segments.shift()
    const restOfSegments = segments.join('.')

    if (object[ firstSegment ] === void 0) {
        object[ firstSegment ] = {}
    }

    dataSet(object[ firstSegment ], restOfSegments, value)

}

function diff(left, right, diffs = {}, path = '') {

    if (left === right) { return diffs }

    if (typeof left !== typeof right || isObject(left) && isArray(right) || isArray(left) && isObject(right)) {

        diffs[ path ] = right

        return diffs

    }

    if (isPrimitive(left) || isPrimitive(right)) {

        diffs[ path ] = right

        return diffs

    }

    let leftKeys = Object.keys(left)

    for (const [ key, value ] of Object.entries(right)) {

        diffs = { ...diffs, ...diff(left[ key ], right[ key ], diffs, path === '' ? key : `${ path }.${ key }`) }
        leftKeys = leftKeys.filter(index => index !== key)

    }

    for (const key of leftKeys) {
        diffs[ `${ path }.${ key }` ] = '__rm__'
    }

    return diffs

}

function extractData(payload) {

    const value = isSynthetic(payload) ? payload[ 0 ] : payload
    const meta = isSynthetic(payload) ? payload[ 1 ] : void 0

    if (isObjecty(value)) {

        for (const [ key, indexValue ] of Object.entries(value)) {
            value[ key ] = extractData(indexValue)
        }

    }

    return value

}

function isSynthetic(subject) {
    return Array.isArray(subject) && subject.length === 2 && typeof subject[ 1 ] === 'object' && Object.keys(subject[ 1 ]).includes('s')
}

function getCsrfToken() {

    if (document.querySelector('meta[name="csrf-token"]')) {
        return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    if (document.querySelector('[data-csrf]')) {
        return document.querySelector('[data-csrf]').getAttribute('data-csrf')
    }

    if (window.livewireScriptConfig.csrf ?? false) {
        return window.livewireScriptConfig.csrf
    }

    throw 'Livewire: No CSRF token detected'

}

let nonce

function getNonce() {

    if (nonce) { return nonce }

    if (window.livewireScriptConfig && (window.livewireScriptConfig.nonce ?? false)) {

        nonce = window.livewireScriptConfig.nonce

        return nonce

    }

    const elementWithNonce = document.querySelector('style[data-livewire-style][nonce]')

    if (elementWithNonce) {

        nonce = elementWithNonce.nonce

        return nonce

    }

    return null

}

function getUpdateUri() {
    return document.querySelector('[data-update-uri]')?.getAttribute('data-update-uri') ?? window.livewireScriptConfig.uri ?? null
}

function contentIsFromDump(content) {
    return !!content.match(/<script>Sfdump\(".+"\)<\/script>/)
}

function splitDumpFromContent(content) {

    const dump = content.match(/.*<script>Sfdump\(".+"\)<\/script>/s)

    return [ dump, content.replace(dump, '') ]

}

// Js/features/supportFileUploads.js
const uploadManagers = /* @__PURE__ */ new WeakMap()

function getUploadManager(component) {

    if (!uploadManagers.has(component)) {

        const manager = new UploadManager(component)

        uploadManagers.set(component, manager)
        manager.registerListeners()

    }

    return uploadManagers.get(component)

}

function handleFileUpload(element, property, component, cleanup) {

    const manager = getUploadManager(component)
    const start2 = () => element.dispatchEvent(new CustomEvent('livewire-upload-start', { bubbles: true, detail: { id: component.id, property } }))
    const finish = () => element.dispatchEvent(new CustomEvent('livewire-upload-finish', { bubbles: true, detail: { id: component.id, property } }))
    const error2 = () => element.dispatchEvent(new CustomEvent('livewire-upload-error', { bubbles: true, detail: { id: component.id, property } }))
    const cancel = () => element.dispatchEvent(new CustomEvent('livewire-upload-cancel', { bubbles: true, detail: { id: component.id, property } }))

    const progress = progressEvent => {

        const percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total)

        element.dispatchEvent(new CustomEvent('livewire-upload-progress', {
            bubbles: true,
            detail: { progress: percentCompleted },
        }))

    }

    const eventHandler = e => {

        if (e.target.files.length === 0) { return }

        start2()

        if (e.target.multiple) {
            manager.uploadMultiple(property, e.target.files, finish, error2, progress, cancel)
        } else {
            manager.upload(property, e.target.files[ 0 ], finish, error2, progress, cancel)
        }

    }

    element.addEventListener('change', eventHandler)

    component.$wire.$watch(property, value => {

        if (!element.isConnected) { return }

        if (value === null || value === '') {
            element.value = ''
        }

    })

    const clearFileInputValue = () => {
        element.value = null
    }

    element.addEventListener('click', clearFileInputValue)
    element.addEventListener('livewire-upload-cancel', clearFileInputValue)

    cleanup(() => {

        element.removeEventListener('change', eventHandler)
        element.removeEventListener('click', clearFileInputValue)

    })

}

var UploadManager = class {

    constructor(component) {

        this.component = component
        this.uploadBag = new MessageBag()
        this.removeBag = new MessageBag()

    }

    registerListeners() {

        this.component.$wire.$on('upload:generatedSignedUrl', ({ name, url }) => {

            setUploadLoading(this.component, name)
            this.handleSignedUrl(name, url)

        })

        this.component.$wire.$on('upload:generatedSignedUrlForS3', ({ name, payload }) => {

            setUploadLoading(this.component, name)
            this.handleS3PreSignedUrl(name, payload)

        })

        this.component.$wire.$on('upload:finished', ({ name, tmpFilenames }) => this.markUploadFinished(name, tmpFilenames))
        this.component.$wire.$on('upload:errored', ({ name }) => this.markUploadErrored(name))
        this.component.$wire.$on('upload:removed', ({ name, tmpFilename }) => this.removeBag.shift(name).finishCallback(tmpFilename))

    }

    upload(name, file, finishCallback, errorCallback, progressCallback, cancelledCallback) {

        this.setUpload(name, {
            files: [ file ],
            multiple: false,
            finishCallback,
            errorCallback,
            progressCallback,
            cancelledCallback,
        })

    }

    uploadMultiple(name, files, finishCallback, errorCallback, progressCallback, cancelledCallback) {

        this.setUpload(name, {
            files: Array.from(files),
            multiple: true,
            finishCallback,
            errorCallback,
            progressCallback,
            cancelledCallback,
        })

    }

    removeUpload(name, temporaryFilename, finishCallback) {

        this.removeBag.push(name, {
            tmpFilename: temporaryFilename,
            finishCallback,
        })

        this.component.$wire.call('_removeUpload', name, temporaryFilename)

    }

    setUpload(name, uploadObject) {

        this.uploadBag.add(name, uploadObject)

        if (this.uploadBag.get(name).length === 1) {
            this.startUpload(name, uploadObject)
        }

    }

    handleSignedUrl(name, url) {

        const formData = new FormData()

        for (const file of Array.from(this.uploadBag.first(name).files)) { formData.append('files[]', file, file.name) }

        const headers = {
            Accept: 'application/json',
        }

        const csrfToken = getCsrfToken()

        if (csrfToken) { headers[ 'X-CSRF-TOKEN' ] = csrfToken }

        this.makeRequest(name, formData, 'post', url, headers, response => response.paths)

    }

    handleS3PreSignedUrl(name, payload) {

        const formData = this.uploadBag.first(name).files[ 0 ]
        const headers = payload.headers

        if ('Host' in headers) { delete headers.Host }

        const url = payload.url

        this.makeRequest(name, formData, 'put', url, headers, response => [ payload.path ])

    }

    makeRequest(name, formData, method, url, headers, retrievePaths) {

        const request = new XMLHttpRequest()

        request.open(method, url)

        for (const [ key, value ] of Object.entries(headers)) {
            request.setRequestHeader(key, value)
        }

        request.upload.addEventListener('progress', e => {

            e.detail = {}
            e.detail.progress = Math.floor(e.loaded * 100 / e.total)
            this.uploadBag.first(name).progressCallback(e)

        })

        request.addEventListener('load', () => {

            if ((`${ request.status }`)[ 0 ] === '2') {

                const paths = retrievePaths(request.response && JSON.parse(request.response))

                this.component.$wire.call('_finishUpload', name, paths, this.uploadBag.first(name).multiple)

                return

            }

            let errors = null

            if (request.status === 422) {
                errors = request.response
            }

            this.component.$wire.call('_uploadErrored', name, errors, this.uploadBag.first(name).multiple)

        })

        this.uploadBag.first(name).request = request
        request.send(formData)

    }

    startUpload(name, uploadObject) {

        const fileInfos = uploadObject.files.map(file => ({ name: file.name, size: file.size, type: file.type }))

        this.component.$wire.call('_startUpload', name, fileInfos, uploadObject.multiple)
        setUploadLoading(this.component, name)

    }

    markUploadFinished(name, temporaryFilenames) {

        unsetUploadLoading(this.component)

        const uploadObject = this.uploadBag.shift(name)

        uploadObject.finishCallback(uploadObject.multiple ? temporaryFilenames : temporaryFilenames[ 0 ])

        if (this.uploadBag.get(name).length > 0) { this.startUpload(name, this.uploadBag.last(name)) }

    }

    markUploadErrored(name) {

        unsetUploadLoading(this.component)
        this.uploadBag.shift(name).errorCallback()

        if (this.uploadBag.get(name).length > 0) { this.startUpload(name, this.uploadBag.last(name)) }

    }

    cancelUpload(name, cancelledCallback = null) {

        unsetUploadLoading(this.component)

        const uploadItem = this.uploadBag.first(name)

        if (uploadItem) {

            if (uploadItem.request) {
                uploadItem.request.abort()
            }

            this.uploadBag.shift(name).cancelledCallback()

            if (cancelledCallback) { cancelledCallback() }

        }

    }

}

var MessageBag = class {

    constructor() {
        this.bag = {}
    }

    add(name, thing) {

        if (!this.bag[ name ]) {
            this.bag[ name ] = []
        }

        this.bag[ name ].push(thing)

    }

    push(name, thing) {
        this.add(name, thing)
    }

    first(name) {

        if (!this.bag[ name ]) { return null }

        return this.bag[ name ][ 0 ]

    }

    last(name) {
        return this.bag[ name ].slice(-1)[ 0 ]
    }

    get(name) {
        return this.bag[ name ]
    }

    shift(name) {
        return this.bag[ name ].shift()
    }

    call(name, ...parameters) {

        for (const callback of (this.listeners[ name ] || [])) {
            callback(...parameters)
        }

    }

    has(name) {
        return Object.keys(this.listeners).includes(name)
    }

}

function setUploadLoading() {
}

function unsetUploadLoading() {
}

function upload(component, name, file, finishCallback = () => {
}, errorCallback = () => {
}, progressCallback = () => {
}, cancelledCallback = () => {
}) {

    const uploadManager = getUploadManager(component)

    uploadManager.upload(name, file, finishCallback, errorCallback, progressCallback, cancelledCallback)

}

function uploadMultiple(component, name, files, finishCallback = () => {
}, errorCallback = () => {
}, progressCallback = () => {
}, cancelledCallback = () => {
}) {

    const uploadManager = getUploadManager(component)

    uploadManager.uploadMultiple(name, files, finishCallback, errorCallback, progressCallback, cancelledCallback)

}

function removeUpload(component, name, temporaryFilename, finishCallback = () => {
}, errorCallback = () => {
}) {

    const uploadManager = getUploadManager(component)

    uploadManager.removeUpload(name, temporaryFilename, finishCallback, errorCallback)

}

function cancelUpload(component, name, cancelledCallback = () => {
}) {

    const uploadManager = getUploadManager(component)

    uploadManager.cancelUpload(name, cancelledCallback)

}

// Js/features/supportEntangle.js
const import_alpinejs = __toESM(require_module_cjs())

function generateEntangleFunction(component, cleanup) {

    if (!cleanup) {

        cleanup = () => {
        }

    }

    return (name, live = false) => {

        let isLive = live

        const livewireProperty = name
        const livewireComponent = component.$wire
        const livewirePropertyValue = livewireComponent.get(livewireProperty)

        const interceptor = import_alpinejs.default.interceptor((initialValue, getter, setter, path, key) => {

            if (typeof livewirePropertyValue === 'undefined') {

                console.error(`Livewire Entangle Error: Livewire property ['${ livewireProperty }'] cannot be found on component: ['${ component.name }']`)

                return

            }

            const release = import_alpinejs.default.entangle({
                get() {
                    return livewireComponent.get(name)
                },
                set(value) {
                    livewireComponent.set(name, value, isLive)
                },
            }, {
                get() {
                    return getter()
                },
                set(value) {
                    setter(value)
                },
            })

            cleanup(() => release())

            return cloneIfObject(livewireComponent.get(name))

        }, object => {

            Object.defineProperty(object, 'live', {
                get() {

                    isLive = true

                    return object

                },
            })

        })

        return interceptor(livewirePropertyValue)

    }

}

function cloneIfObject(value) {
    return typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value
}

// Js/hooks.js
const listeners = []

function on(name, callback) {

    if (!listeners[ name ]) { listeners[ name ] = [] }

    listeners[ name ].push(callback)

    return () => {
        listeners[ name ] = listeners[ name ].filter(index => index !== callback)
    }

}

function trigger(name, ...parameters) {

    const callbacks = listeners[ name ] || []
    const finishers = []

    for (let index = 0; index < callbacks.length; index++) {

        const finisher = callbacks[ index ](...parameters)

        if (isFunction(finisher)) { finishers.push(finisher) }

    }

    return result => runFinishers(finishers, result)

}

async function triggerAsync(name, ...parameters) {

    const callbacks = listeners[ name ] || []
    const finishers = []

    for (let index = 0; index < callbacks.length; index++) {

        const finisher = await callbacks[ index ](...parameters)

        if (isFunction(finisher)) { finishers.push(finisher) }

    }

    return result => runFinishers(finishers, result)

}

function runFinishers(finishers, result) {

    let latest = result

    for (let index = 0; index < finishers.length; index++) {

        const indexResult = finishers[ index ](latest)

        if (indexResult !== void 0) {
            latest = indexResult
        }

    }

    return latest

}

// Js/request/modal.js
function showHtmlModal(html) {

    const page = document.createElement('html')

    page.innerHTML = html

    for (const a of page.querySelectorAll('a')) { a.setAttribute('target', '_top') }

    let modal = document.getElementById('livewire-error')

    if (typeof modal !== 'undefined' && modal != null) {
        modal.innerHTML = ''
    } else {

        modal = document.createElement('div')
        modal.id = 'livewire-error'
        modal.style.position = 'fixed'
        modal.style.width = '100vw'
        modal.style.height = '100vh'
        modal.style.padding = '50px'
        modal.style.backgroundColor = 'rgba(0, 0, 0, .6)'
        modal.style.zIndex = 2e5

    }

    const iframe = document.createElement('iframe')

    iframe.style.backgroundColor = '#17161A'
    iframe.style.borderRadius = '5px'
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    modal.appendChild(iframe)
    document.body.prepend(modal)
    document.body.style.overflow = 'hidden'
    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(page.outerHTML)
    iframe.contentWindow.document.close()
    modal.addEventListener('click', () => hideHtmlModal(modal))
    modal.setAttribute('tabindex', 0)

    modal.addEventListener('keydown', e => {
        if (e.key === 'Escape') { hideHtmlModal(modal) }
    })

    modal.focus()

}

function hideHtmlModal(modal) {

    modal.outerHTML = ''
    document.body.style.overflow = 'visible'

}

// Js/request/pool.js
const RequestPool = class {

    constructor() {
        this.commits = /* @__PURE__ */ new Set()
    }

    add(commit) {
        this.commits.add(commit)
    }

    delete(commit) {
        this.commits.delete(commit)
    }

    hasCommitFor(component) {
        return !!this.findCommitByComponent(component)
    }

    findCommitByComponent(component) {

        for (const [ index, commit ] of this.commits.entries()) {
            if (commit.component === component) { return commit }
        }

    }

    shouldHoldCommit(commit) {
        return !commit.isolate
    }

    empty() {
        return this.commits.size === 0
    }

    async send() {

        this.prepare()
        await sendRequest(this)

    }

    prepare() {
        for (const index of this.commits) { index.prepare() }
    }

    payload() {

        const commitPayloads = []
        const successReceivers = []
        const failureReceivers = []

        for (const commit of this.commits) {

            const [ payload, succeed2, fail2 ] = commit.toRequestPayload()

            commitPayloads.push(payload)
            successReceivers.push(succeed2)
            failureReceivers.push(fail2)

        }

        const succeed = components2 => successReceivers.forEach(receiver => receiver(components2.shift()))
        const fail = () => failureReceivers.forEach(receiver => receiver())

        return [ commitPayloads, succeed, fail ]

    }

}

// Js/request/commit.js
const Commit = class {

    constructor(component) {

        this.component = component
        this.isolate = false
        this.calls = []
        this.receivers = []
        this.resolvers = []

    }

    addResolver(resolver) {
        this.resolvers.push(resolver)
    }

    addCall(method, parameters, receiver) {

        this.calls.push({
            path: '',
            method,
            params: parameters,
            handleReturn(value) {
                receiver(value)
            },
        })

    }

    prepare() {
        trigger('commit.prepare', { component: this.component })
    }

    toRequestPayload() {

        const propertiesDiff = diff(this.component.canonical, this.component.ephemeral)
        const updates = this.component.mergeQueuedUpdates(propertiesDiff)

        const payload = {
            snapshot: this.component.snapshotEncoded,
            updates,
            calls: this.calls.map(index => ({
                path: index.path,
                method: index.method,
                params: index.params,
            })),
        }

        const succeedCallbacks = []
        const failCallbacks = []
        const respondCallbacks = []
        const succeed = fwd => succeedCallbacks.forEach(index => index(fwd))
        const fail = () => failCallbacks.forEach(index => index())
        const respond = () => respondCallbacks.forEach(index => index())

        const finishTarget = trigger('commit', {
            component: this.component,
            commit: payload,
            succeed: callback => {
                succeedCallbacks.push(callback)
            },
            fail: callback => {
                failCallbacks.push(callback)
            },
            respond: callback => {
                respondCallbacks.push(callback)
            },
        })

        const handleResponse = response => {

            const { snapshot, effects } = response

            respond()
            this.component.mergeNewSnapshot(snapshot, effects, updates)
            this.component.processEffects(this.component.effects)

            if (effects.returns) {

                const returns = effects.returns
                const returnHandlerStack = this.calls.map(({ handleReturn }) => handleReturn)

                for (const [ index, handleReturn ] of returnHandlerStack.entries()) {
                    handleReturn(returns[ index ])
                }

            }

            const parsedSnapshot = JSON.parse(snapshot)

            finishTarget({ snapshot: parsedSnapshot, effects })

            for (const index of this.resolvers) { index() }

            succeed(response)

        }

        const handleFailure = () => {

            respond()
            fail()

        }

        return [ payload, handleResponse, handleFailure ]

    }

}

// Js/request/bus.js
const CommitBus = class {

    constructor() {

        this.commits = /* @__PURE__ */ new Set()
        this.pools = /* @__PURE__ */ new Set()

    }

    add(component) {

        const commit = this.findCommitOr(component, () => {

            const newCommit = new Commit(component)

            this.commits.add(newCommit)

            return newCommit

        })

        bufferPoolingForFiveMs(commit, () => {

            const pool = this.findPoolWithComponent(commit.component)

            if (!pool) {
                this.createAndSendNewPool()
            }

        })

        return commit

    }

    findCommitOr(component, callback) {

        for (const [ index, commit ] of this.commits.entries()) {

            if (commit.component === component) {
                return commit
            }

        }

        return callback()

    }

    findPoolWithComponent(component) {

        for (const [ index, pool ] of this.pools.entries()) {
            if (pool.hasCommitFor(component)) { return pool }
        }

    }

    createAndSendNewPool() {

        trigger('commit.pooling', { commits: this.commits })

        const pools = this.corraleCommitsIntoPools()

        this.commits.clear()
        trigger('commit.pooled', { pools })

        for (const pool of pools) {

            if (pool.empty()) { continue }

            this.pools.add(pool)

            pool.send().then(() => {

                this.pools.delete(pool)
                this.sendAnyQueuedCommits()

            })

        }

    }

    corraleCommitsIntoPools() {

        const pools = /* @__PURE__ */ new Set()

        for (const [ index, commit ] of this.commits.entries()) {

            let hasFoundPool = false

            for (const pool of pools) {

                if (pool.shouldHoldCommit(commit)) {

                    pool.add(commit)
                    hasFoundPool = true

                }

            }

            if (!hasFoundPool) {

                const newPool = new RequestPool()

                newPool.add(commit)
                pools.add(newPool)

            }

        }

        return pools

    }

    sendAnyQueuedCommits() {

        if (this.commits.size > 0) {
            this.createAndSendNewPool()
        }

    }

}

const buffersByCommit = /* @__PURE__ */ new WeakMap()

function bufferPoolingForFiveMs(commit, callback) {

    if (buffersByCommit.has(commit)) { return }

    buffersByCommit.set(commit, setTimeout(() => {

        callback()
        buffersByCommit.delete(commit)

    }, 5))

}

// Js/request/index.js
const commitBus = new CommitBus()

async function requestCommit(component) {

    const commit = commitBus.add(component)

    const promise = new Promise(resolve => {
        commit.addResolver(resolve)
    })

    promise.commit = commit

    return promise

}

async function requestCall(component, method, parameters) {

    const commit = commitBus.add(component)

    const promise = new Promise(resolve => {
        commit.addCall(method, parameters, value => resolve(value))
    })

    promise.commit = commit

    return promise

}

async function sendRequest(pool) {

    const [ payload, handleSuccess, handleFailure ] = pool.payload()

    const options = {
        method: 'POST',
        body: JSON.stringify({
            _token: getCsrfToken(),
            components: payload,
        }),
        headers: {
            'Content-type': 'application/json',
            'X-Livewire': '',
        },
    }

    const succeedCallbacks = []
    const failCallbacks = []
    const respondCallbacks = []
    const succeed = fwd => succeedCallbacks.forEach(index => index(fwd))
    const fail = fwd => failCallbacks.forEach(index => index(fwd))
    const respond = fwd => respondCallbacks.forEach(index => index(fwd))
    const finishProfile = trigger('request.profile', options)
    const updateUri = getUpdateUri()

    trigger('request', {
        url: updateUri,
        options,
        payload: options.body,
        respond: index => respondCallbacks.push(index),
        succeed: index => succeedCallbacks.push(index),
        fail: index => failCallbacks.push(index),
    })

    let response

    try {
        response = await fetch(updateUri, options)
    } catch (e) {

        finishProfile({ content: '{}', failed: true })
        handleFailure()

        fail({
            status: 503,
            content: null,
            preventDefault: () => {
            },
        })

        return

    }

    const mutableObject = {
        status: response.status,
        response,
    }

    respond(mutableObject)
    response = mutableObject.response

    let content = await response.text()

    if (!response.ok) {

        finishProfile({ content: '{}', failed: true })

        let preventDefault = false

        handleFailure()

        fail({
            status: response.status,
            content,
            preventDefault: () => preventDefault = true,
        })

        if (preventDefault) { return }

        if (response.status === 419) {
            handlePageExpiry()
        }

        return showFailureModal(content)

    }

    if (response.redirected) {
        window.location.href = response.url
    }

    if (contentIsFromDump(content)) {

        let dump;

        [ dump, content ] = splitDumpFromContent(content)
        showHtmlModal(dump)
        finishProfile({ content: '{}', failed: true })

    } else {
        finishProfile({ content, failed: false })
    }

    const { components: components2, assets } = JSON.parse(content)

    await triggerAsync('payload.intercept', { components: components2, assets })
    await handleSuccess(components2)
    succeed({ status: response.status, json: JSON.parse(content) })

}

function handlePageExpiry() {
    confirm('This page has expired.\nWould you like to refresh the page?') && window.location.reload()
}

function showFailureModal(content) {

    const html = content

    showHtmlModal(html)

}

// Js/$wire.js
const import_alpinejs2 = __toESM(require_module_cjs())
const properties = {}

let fallback

function wireProperty(name, callback, component = null) {
    properties[ name ] = callback
}

function wireFallback(callback) {
    fallback = callback
}

const aliases = {
    on: '$on',
    el: '$el',
    id: '$id',
    get: '$get',
    set: '$set',
    call: '$call',
    commit: '$commit',
    watch: '$watch',
    entangle: '$entangle',
    dispatch: '$dispatch',
    dispatchTo: '$dispatchTo',
    dispatchSelf: '$dispatchSelf',
    upload: '$upload',
    uploadMultiple: '$uploadMultiple',
    removeUpload: '$removeUpload',
    cancelUpload: '$cancelUpload',
}

function generateWireObject(component, state) {

    return new Proxy({}, {
        get(target, property) {

            if (property === '__instance') { return component }

            if (property in aliases) {
                return getProperty(component, aliases[ property ])
            }

            if (property in properties) {
                return getProperty(component, property)
            }

            if (property in state) {
                return state[ property ]
            }

            if (![ 'then' ].includes(property)) {
                return getFallback(component)(property)
            }

        },
        set(target, property, value) {

            if (property in state) {
                state[ property ] = value
            }

            return true

        },
    })

}

function getProperty(component, name) {
    return properties[ name ](component)
}

function getFallback(component) {
    return fallback(component)
}

import_alpinejs2.default.magic('wire', (element, { cleanup }) => {

    let component

    return new Proxy({}, {
        get(target, property) {

            if (!component) { component = closestComponent(element) }

            if ([ '$entangle', 'entangle' ].includes(property)) {
                return generateEntangleFunction(component, cleanup)
            }

            return component.$wire[ property ]

        },
        set(target, property, value) {

            if (!component) { component = closestComponent(element) }

            component.$wire[ property ] = value

            return true

        },
    })

})

wireProperty('__instance', component => component)
wireProperty('$get', component => (property, reactive = true) => dataGet(reactive ? component.reactive : component.ephemeral, property))
wireProperty('$el', component => component.el)
wireProperty('$id', component => component.id)

wireProperty('$set', component => async(property, value, live = true) => {

    dataSet(component.reactive, property, value)

    if (live) {

        component.queueUpdate(property, value)

        return await requestCommit(component)

    }

    return Promise.resolve()

})

wireProperty('$call', component => async(method, ...parameters) => await component.$wire[ method ](...parameters))
wireProperty('$entangle', component => (name, live = false) => generateEntangleFunction(component)(name, live))
wireProperty('$toggle', component => (name, live = true) => component.$wire.set(name, !component.$wire.get(name), live))

wireProperty('$watch', component => (path, callback) => {

    const getter = () => dataGet(component.reactive, path)
    const unwatch = import_alpinejs2.default.watch(getter, callback)

    component.addCleanup(unwatch)

})

wireProperty('$refresh', component => component.$wire.$commit)
wireProperty('$commit', component => async() => await requestCommit(component))
wireProperty('$on', component => (...parameters) => listen2(component, ...parameters))
wireProperty('$dispatch', component => (...parameters) => dispatch2(component, ...parameters))
wireProperty('$dispatchSelf', component => (...parameters) => dispatchSelf(component, ...parameters))
wireProperty('$dispatchTo', () => (...parameters) => dispatchTo(...parameters))
wireProperty('$upload', component => (...parameters) => upload(component, ...parameters))
wireProperty('$uploadMultiple', component => (...parameters) => uploadMultiple(component, ...parameters))
wireProperty('$removeUpload', component => (...parameters) => removeUpload(component, ...parameters))
wireProperty('$cancelUpload', component => (...parameters) => cancelUpload(component, ...parameters))

const parentMemo = /* @__PURE__ */ new WeakMap()

wireProperty('$parent', component => {

    if (parentMemo.has(component)) { return parentMemo.get(component).$wire }

    const parent = component.parent

    parentMemo.set(component, parent)

    return parent.$wire

})

const overriddenMethods = /* @__PURE__ */ new WeakMap()

function overrideMethod(component, method, callback) {

    if (!overriddenMethods.has(component)) {
        overriddenMethods.set(component, {})
    }

    const object = overriddenMethods.get(component)

    object[ method ] = callback
    overriddenMethods.set(component, object)

}

wireFallback(component => property => async(...parameters) => {

    if (parameters.length === 1 && parameters[ 0 ] instanceof Event) {
        parameters = []
    }

    if (overriddenMethods.has(component)) {

        const overrides = overriddenMethods.get(component)

        if (typeof overrides[ property ] === 'function') {
            return overrides[ property ](parameters)
        }

    }

    return await requestCall(component, property, parameters)

})

// Js/component.js
const Component = class {

    constructor(element) {

        if (element.__livewire) { throw 'Component already initialized' }

        element.__livewire = this
        this.el = element
        this.id = element.getAttribute('wire:id')
        this.__livewireId = this.id
        this.snapshotEncoded = element.getAttribute('wire:snapshot')
        this.snapshot = JSON.parse(this.snapshotEncoded)

        if (!this.snapshot) {
            throw `Snapshot missing on Livewire component with id: ${ this.id }`
        }

        this.name = this.snapshot.memo.name
        this.effects = JSON.parse(element.getAttribute('wire:effects'))
        this.originalEffects = deepClone(this.effects)
        this.canonical = extractData(deepClone(this.snapshot.data))
        this.ephemeral = extractData(deepClone(this.snapshot.data))
        this.reactive = Alpine.reactive(this.ephemeral)
        this.queuedUpdates = {}
        this.$wire = generateWireObject(this, this.reactive)
        this.cleanups = []
        this.processEffects(this.effects)

    }

    mergeNewSnapshot(snapshotEncoded, effects, updates = {}) {

        const snapshot = JSON.parse(snapshotEncoded)
        const oldCanonical = deepClone(this.canonical)
        const updatedOldCanonical = this.applyUpdates(oldCanonical, updates)
        const newCanonical = extractData(deepClone(snapshot.data))
        const dirty = diff(updatedOldCanonical, newCanonical)

        this.snapshotEncoded = snapshotEncoded
        this.snapshot = snapshot
        this.effects = effects
        this.canonical = extractData(deepClone(snapshot.data))

        const newData = extractData(deepClone(snapshot.data))

        for (const [ key, value ] of Object.entries(dirty)) {

            const rootKey = key.split('.')[ 0 ]

            this.reactive[ rootKey ] = newData[ rootKey ]

        }

        return dirty

    }

    queueUpdate(propertyName, value) {
        this.queuedUpdates[ propertyName ] = value
    }

    mergeQueuedUpdates(diff2) {

        for (const [ updateKey, updateValue ] of Object.entries(this.queuedUpdates)) {

            for (const [ diffKey, diffValue ] of Object.entries(diff2)) {

                if (diffKey.startsWith(updateValue)) {
                    delete diff2[ diffKey ]
                }

            }

            diff2[ updateKey ] = updateValue

        }

        this.queuedUpdates = []

        return diff2

    }

    applyUpdates(object, updates) {

        for (const key in updates) {
            dataSet(object, key, updates[ key ])
        }

        return object

    }

    replayUpdate(snapshot, html) {

        const effects = { ...this.effects, html }

        this.mergeNewSnapshot(JSON.stringify(snapshot), effects)
        this.processEffects({ html })

    }

    processEffects(effects) {

        trigger('effects', this, effects)

        trigger('effect', {
            component: this,
            effects,
            cleanup: index => this.addCleanup(index),
        })

    }

    get children() {

        const meta = this.snapshot.memo
        const childIds = Object.values(meta.children).map(index => index[ 1 ])

        return childIds.map(id => findComponent(id))

    }

    get parent() {
        return closestComponent(this.el.parentElement)
    }

    inscribeSnapshotAndEffectsOnElement() {

        const element = this.el

        element.setAttribute('wire:snapshot', this.snapshotEncoded)

        const effects = this.originalEffects.listeners ? { listeners: this.originalEffects.listeners } : {}

        if (this.originalEffects.url) {
            effects.url = this.originalEffects.url
        }

        if (this.originalEffects.scripts) {
            effects.scripts = this.originalEffects.scripts
        }

        element.setAttribute('wire:effects', JSON.stringify(effects))

    }

    addCleanup(cleanup) {
        this.cleanups.push(cleanup)
    }

    cleanup() {

        delete this.el.__livewire

        while (this.cleanups.length > 0) {
            this.cleanups.pop()()
        }

    }

}

// Js/store.js
const components = {}

function initComponent(element) {

    const component = new Component(element)

    if (components[ component.id ]) { throw 'Component already registered' }

    const cleanup = index => component.addCleanup(index)

    trigger('component.init', { component, cleanup })
    components[ component.id ] = component

    return component

}

function destroyComponent(id) {

    const component = components[ id ]

    if (!component) { return }

    component.cleanup()
    delete components[ id ]

}

function findComponent(id) {

    const component = components[ id ]

    if (!component) { throw `Component not found: ${ id }` }

    return component

}

function closestComponent(element, strict = true) {

    const closestRoot = Alpine.findClosest(element, index => index.__livewire)

    if (!closestRoot) {

        if (strict) { throw 'Could not find Livewire component in DOM tree' }

        return

    }

    return closestRoot.__livewire

}

function componentsByName(name) {
    return Object.values(components).filter(component => name == component.name)
}

function getByName(name) {
    return componentsByName(name).map(index => index.$wire)
}

function find(id) {

    const component = components[ id ]

    return component && component.$wire

}

function first() {
    return Object.values(components)[ 0 ].$wire
}

function all() {
    return Object.values(components)
}

// Js/events.js
function dispatch2(component, name, parameters) {
    dispatchEvent(component.el, name, parameters)
}

function dispatchGlobal(name, parameters) {
    dispatchEvent(window, name, parameters)
}

function dispatchSelf(component, name, parameters) {
    dispatchEvent(component.el, name, parameters, false)
}

function dispatchTo(componentName, name, parameters) {

    const targets = componentsByName(componentName)

    for (const target of targets) {
        dispatchEvent(target.el, name, parameters, false)
    }

}

function listen2(component, name, callback) {

    component.el.addEventListener(name, e => {
        callback(e.detail)
    })

}

function on2(eventName, callback) {

    const handler = e => {

        if (!e.__livewire) { return }

        callback(e.detail)

    }

    window.addEventListener(eventName, handler)

    return () => {
        window.removeEventListener(eventName, handler)
    }

}

function dispatchEvent(target, name, parameters, bubbles = true) {

    const e = new CustomEvent(name, { bubbles, detail: parameters })

    e.__livewire = { name, params: parameters, receivedBy: [] }
    target.dispatchEvent(e)

}

// Js/directives.js
const customDirectiveNames = /* @__PURE__ */ new Set()

function matchesForLivewireDirective(attributeName) {
    return attributeName.match(new RegExp('wire:'))
}

function extractDirective(element, name) {

    const [ value, ...modifiers ] = name.replace(new RegExp('wire:'), '').split('.')

    return new Directive(value, modifiers, name, element)

}

function directive(name, callback) {

    if (customDirectiveNames.has(name)) { return }

    customDirectiveNames.add(name)

    on('directive.init', ({ el, component, directive: directive2, cleanup }) => {

        if (directive2.value === name) {

            callback({
                el,
                directive: directive2,
                component,
                $wire: component.$wire,
                cleanup,
            })

        }

    })

}

function getDirectives(element) {
    return new DirectiveManager(element)
}

function customDirectiveHasBeenRegistered(name) {
    return customDirectiveNames.has(name)
}

var DirectiveManager = class {

    constructor(element) {

        this.el = element
        this.directives = this.extractTypeModifiersAndValue()

    }

    all() {
        return this.directives
    }

    has(value) {
        return this.directives.map(directive2 => directive2.value).includes(value)
    }

    missing(value) {
        return !this.has(value)
    }

    get(value) {
        return this.directives.find(directive2 => directive2.value === value)
    }

    extractTypeModifiersAndValue() {
        return Array.from(this.el.getAttributeNames().filter(name => matchesForLivewireDirective(name)).map(name => extractDirective(this.el, name)))
    }

}

var Directive = class {

    constructor(value, modifiers, rawName, element) {

        this.rawName = this.raw = rawName
        this.el = element
        this.eventContext
        this.value = value
        this.modifiers = modifiers
        this.expression = this.el.getAttribute(this.rawName)

    }

    get method() {

        const { method } = this.parseOutMethodAndParams(this.expression)

        return method

    }

    get params() {

        const { params } = this.parseOutMethodAndParams(this.expression)

        return params

    }

    parseOutMethodAndParams(rawMethod) {

        let method = rawMethod
        let parameters = []

        const methodAndParameterString = method.match(/(.*?)\((.*)\)/s)

        if (methodAndParameterString) {

            method = methodAndParameterString[ 1 ]

            const function_ = new Function('$event', `return (function () {
                for (var l=arguments.length, p=new Array(l), k=0; k<l; k++) {
                    p[k] = arguments[k];
                }
                return [].concat(p);
            })(${ methodAndParameterString[ 2 ] })`)

            parameters = function_(this.eventContext)

        }

        return { method, params: parameters }

    }

}

// Js/lifecycle.js
const import_collapse = __toESM(require_module_cjs2())
const import_focus = __toESM(require_module_cjs3())
const import_persist2 = __toESM(require_module_cjs4())
const import_intersect = __toESM(require_module_cjs5())
const import_resize = __toESM(require_module_cjs6())
const import_anchor = __toESM(require_module_cjs7())

// Js/plugins/navigate/history.js
const Snapshot = class {

    constructor(url, html) {

        this.url = url
        this.html = html

    }

}

const snapshotCache = {
    currentKey: null,
    currentUrl: null,
    keys: [],
    lookup: {},
    limit: 10,
    has(location) {
        return this.lookup[ location ] !== void 0
    },
    retrieve(location) {

        const snapshot = this.lookup[ location ]

        if (snapshot === void 0) { throw `No back button cache found for current location: ${ location }` }

        return snapshot

    },
    replace(key, snapshot) {

        if (this.has(key)) {
            this.lookup[ key ] = snapshot
        } else {
            this.push(key, snapshot)
        }

    },
    push(key, snapshot) {

        this.lookup[ key ] = snapshot

        const index = this.keys.indexOf(key)

        if (index > -1) { this.keys.splice(index, 1) }

        this.keys.unshift(key)
        this.trim()

    },
    trim() {

        for (const key of this.keys.splice(this.limit)) {
            delete this.lookup[ key ]
        }

    },
}

function updateCurrentPageHtmlInHistoryStateForLaterBackButtonClicks() {

    const url = new URL(window.location.href, document.baseURI)

    replaceUrl(url, document.documentElement.outerHTML)

}

function updateCurrentPageHtmlInSnapshotCacheForLaterBackButtonClicks(key, url) {

    const html = document.documentElement.outerHTML

    snapshotCache.replace(key, new Snapshot(url, html))

}

function whenTheBackOrForwardButtonIsClicked(registerFallback, handleHtml) {

    let fallback2

    registerFallback(index => fallback2 = index)

    window.addEventListener('popstate', e => {

        const state = e.state || {}
        const alpine = state.alpine || {}

        if (Object.keys(state).length === 0) { return }

        if (!alpine.snapshotIdx) { return }

        if (snapshotCache.has(alpine.snapshotIdx)) {

            const snapshot = snapshotCache.retrieve(alpine.snapshotIdx)

            handleHtml(snapshot.html, snapshot.url, snapshotCache.currentUrl, snapshotCache.currentKey)

        } else {
            fallback2(alpine.url)
        }

    })

}

function updateUrlAndStoreLatestHtmlForFutureBackButtons(html, destination) {
    pushUrl(destination, html)
}

function pushUrl(url, html) {
    updateUrl('pushState', url, html)
}

function replaceUrl(url, html) {
    updateUrl('replaceState', url, html)
}

function updateUrl(method, url, html) {

    let key = `${ url.toString() }-${ Math.random() }`

    method === 'pushState' ? snapshotCache.push(key, new Snapshot(url, html)) : snapshotCache.replace(key = snapshotCache.currentKey ?? key, new Snapshot(url, html))

    const state = history.state || {}

    if (!state.alpine) { state.alpine = {} }

    state.alpine.snapshotIdx = key
    state.alpine.url = url.toString()

    try {

        history[ method ](state, JSON.stringify(document.title), url)
        snapshotCache.currentKey = key
        snapshotCache.currentUrl = url

    } catch (error2) {

        if (error2 instanceof DOMException && error2.name === 'SecurityError') {
            console.error(`Livewire: You can't use wire:navigate with a link to a different root domain: ${ url }`)
        }

        console.error(error2)

    }

}

// Js/plugins/navigate/links.js
function whenThisLinkIsPressed(element, callback) {

    const isProgrammaticClick = e => !e.isTrusted
    const isNotPlainLeftClick = e => e.which > 1 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey
    const isNotPlainEnterKey = e => e.which !== 13 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey

    element.addEventListener('click', e => {

        if (isProgrammaticClick(e)) {

            e.preventDefault()
            callback(whenReleased => whenReleased())

            return

        }

        if (isNotPlainLeftClick(e)) { return }

        e.preventDefault()

    })

    element.addEventListener('mousedown', e => {

        if (isNotPlainLeftClick(e)) { return }

        e.preventDefault()

        callback(whenReleased => {

            const handler = e2 => {

                e2.preventDefault()
                whenReleased()
                element.removeEventListener('mouseup', handler)

            }

            element.addEventListener('mouseup', handler)

        })

    })

    element.addEventListener('keydown', e => {

        if (isNotPlainEnterKey(e)) { return }

        e.preventDefault()
        callback(whenReleased => whenReleased())

    })

}

function whenThisLinkIsHoveredFor(element, ms = 60, callback) {

    element.addEventListener('mouseenter', e => {

        const timeout = setTimeout(() => {
            callback(e)
        }, ms)

        const handler = () => {

            clearTimeout(timeout)
            element.removeEventListener('mouseleave', handler)

        }

        element.addEventListener('mouseleave', handler)

    })

}

function extractDestinationFromLink(linkElement) {
    return createUrlObjectFromString(linkElement.getAttribute('href'))
}

function createUrlObjectFromString(urlString) {
    return urlString !== null && new URL(urlString, document.baseURI)
}

function getUriStringFromUrlObject(urlObject) {
    return urlObject.pathname + urlObject.search + urlObject.hash
}

// Js/plugins/navigate/fetch.js
function fetchHtml(destination, callback) {

    const uri = getUriStringFromUrlObject(destination)

    performFetch(uri, (html, finalDestination) => {
        callback(html, finalDestination)
    })

}

function performFetch(uri, callback) {

    const options = {
        headers: {
            'X-Livewire-Navigate': '',
        },
    }

    trigger('navigate.request', {
        url: uri,
        options,
    })

    let finalDestination

    fetch(uri, options).then(response => {

        const destination = createUrlObjectFromString(uri)

        finalDestination = createUrlObjectFromString(response.url)

        if (destination.pathname + destination.search === finalDestination.pathname + finalDestination.search) {
            finalDestination.hash = destination.hash
        }

        return response.text()

    }).then(html => {
        callback(html, finalDestination)
    })

}

// Js/plugins/navigate/prefetch.js
const prefetches = {}

function prefetchHtml(destination, callback) {

    const uri = getUriStringFromUrlObject(destination)

    if (prefetches[ uri ]) { return }

    prefetches[ uri ] = { finished: false, html: null, whenFinished: () => {
    } }

    performFetch(uri, (html, routedUri) => {
        callback(html, routedUri)
    })

}

function storeThePrefetchedHtmlForWhenALinkIsClicked(html, destination, finalDestination) {

    const state = prefetches[ getUriStringFromUrlObject(destination) ]

    state.html = html
    state.finished = true
    state.finalDestination = finalDestination
    state.whenFinished()

}

function getPretchedHtmlOr(destination, receive, ifNoPrefetchExists) {

    const uri = getUriStringFromUrlObject(destination)

    if (!prefetches[ uri ]) { return ifNoPrefetchExists() }

    if (prefetches[ uri ].finished) {

        const html = prefetches[ uri ].html
        const finalDestination = prefetches[ uri ].finalDestination

        delete prefetches[ uri ]

        return receive(html, finalDestination)

    }

    prefetches[ uri ].whenFinished = () => {

        const html = prefetches[ uri ].html
        const finalDestination = prefetches[ uri ].finalDestination

        delete prefetches[ uri ]
        receive(html, finalDestination)

    }

}

// Js/plugins/navigate/teleport.js
const import_alpinejs3 = __toESM(require_module_cjs())

function packUpPersistedTeleports(persistedElement) {

    import_alpinejs3.default.mutateDom(() => {
        for (const index of persistedElement.querySelectorAll('[data-teleport-template]')) { index._x_teleport.remove() }
    })

}

function removeAnyLeftOverStaleTeleportTargets(body) {

    import_alpinejs3.default.mutateDom(() => {
        for (const index of body.querySelectorAll('[data-teleport-target]')) { index.remove() }
    })

}

function unPackPersistedTeleports(persistedElement) {

    import_alpinejs3.default.walk(persistedElement, (element, skip) => {

        if (!element._x_teleport) { return }

        element._x_teleportPutBack()
        skip()

    })

}

function isTeleportTarget(element) {
    return element.hasAttribute('data-teleport-target')
}

// Js/plugins/navigate/scroll.js
function storeScrollInformationInHtmlBeforeNavigatingAway() {

    document.body.setAttribute('data-scroll-x', document.body.scrollLeft)
    document.body.setAttribute('data-scroll-y', document.body.scrollTop)

    for (const element of document.querySelectorAll([ '[x-navigate\\:scroll]', '[wire\\:scroll]' ])) {

        element.setAttribute('data-scroll-x', element.scrollLeft)
        element.setAttribute('data-scroll-y', element.scrollTop)

    }

}

function restoreScrollPositionOrScrollToTop() {

    const scroll = element => {

        if (!element.hasAttribute('data-scroll-x')) {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
        } else {

            element.scrollTo({
                top: Number(element.getAttribute('data-scroll-y')),
                left: Number(element.getAttribute('data-scroll-x')),
                behavior: 'instant',
            })

            element.removeAttribute('data-scroll-x')
            element.removeAttribute('data-scroll-y')

        }

    }

    queueMicrotask(() => {

        scroll(document.body)
        document.querySelectorAll([ '[x-navigate\\:scroll]', '[wire\\:scroll]' ]).forEach(scroll)

    })

}

// Js/plugins/navigate/persist.js
const import_alpinejs4 = __toESM(require_module_cjs())

let els = {}

function storePersistantElementsForLater(callback) {

    els = {}

    for (const index of document.querySelectorAll('[x-persist]')) {

        els[ index.getAttribute('x-persist') ] = index
        callback(index)

        import_alpinejs4.default.mutateDom(() => {
            index.remove()
        })

    }

}

function putPersistantElementsBack(callback) {

    const usedPersists = []

    for (const index of document.querySelectorAll('[x-persist]')) {

        const old = els[ index.getAttribute('x-persist') ]

        if (!old) { continue }

        usedPersists.push(index.getAttribute('x-persist'))
        old._x_wasPersisted = true
        callback(old, index)

        import_alpinejs4.default.mutateDom(() => {
            index.replaceWith(old)
        })

    }

    for (const [ key, element ] of Object.entries(els)) {

        if (usedPersists.includes(key)) { continue }

        import_alpinejs4.default.destroyTree(element)

    }

    els = {}

}

function isPersistedElement(element) {
    return element.hasAttribute('x-persist')
}

// Js/plugins/navigate/bar.js
const import_nprogress = __toESM(require_nprogress())

import_nprogress.default.configure({
    minimum: 0.1,
    trickleSpeed: 200,
    showSpinner: false,
    parent: 'body',
})

injectStyles()

let inProgress = false

function showAndStartProgressBar() {

    inProgress = true

    setTimeout(() => {

        if (!inProgress) { return }

        import_nprogress.default.start()

    }, 150)

}

function finishAndHideProgressBar() {

    inProgress = false
    import_nprogress.default.done()

}

function removeAnyLeftOverStaleProgressBars() {
    import_nprogress.default.remove()
}

function injectStyles() {

    const style = document.createElement('style')

    style.innerHTML = `/* Make clicks pass-through */

    #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: var(--livewire-progress-bar-color, #29d);

      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;

      width: 100%;
      height: 2px;
    }

    /* Fancy blur effect */
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px var(--livewire-progress-bar-color, #29d), 0 0 5px var(--livewire-progress-bar-color, #29d);
      opacity: 1.0;

      -webkit-transform: rotate(3deg) translate(0px, -4px);
          -ms-transform: rotate(3deg) translate(0px, -4px);
              transform: rotate(3deg) translate(0px, -4px);
    }

    /* Remove these to get rid of the spinner */
    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;

      border: solid 2px transparent;
      border-top-color: var(--livewire-progress-bar-color, #29d);
      border-left-color: var(--livewire-progress-bar-color, #29d);
      border-radius: 50%;

      -webkit-animation: nprogress-spinner 400ms linear infinite;
              animation: nprogress-spinner 400ms linear infinite;
    }

    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }

    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }

    @-webkit-keyframes nprogress-spinner {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes nprogress-spinner {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    `

    const nonce2 = getNonce()

    if (nonce2) { style.nonce = nonce2 }

    document.head.appendChild(style)

}

// Js/plugins/navigate/page.js
let oldBodyScriptTagHashes = []

const attributesExemptFromScriptTagHashing = [
    'data-csrf',
    'aria-hidden',
]

function swapCurrentPageWithNewHtml(html, andThen) {

    const newDocument = new DOMParser().parseFromString(html, 'text/html')
    const newBody = document.adoptNode(newDocument.body)
    const newHead = document.adoptNode(newDocument.head)

    oldBodyScriptTagHashes = oldBodyScriptTagHashes.concat(Array.from(document.body.querySelectorAll('script')).map(index => simpleHash(ignoreAttributes(index.outerHTML, attributesExemptFromScriptTagHashing))))

    let afterRemoteScriptsHaveLoaded = () => {
    }

    mergeNewHead(newHead).finally(() => {
        afterRemoteScriptsHaveLoaded()
    })

    prepNewBodyScriptTagsToRun(newBody, oldBodyScriptTagHashes)

    const oldBody = document.body

    document.body.replaceWith(newBody)
    Alpine.destroyTree(oldBody)
    andThen(index => afterRemoteScriptsHaveLoaded = index)

}

function prepNewBodyScriptTagsToRun(newBody, oldBodyScriptTagHashes2) {

    for (const index of newBody.querySelectorAll('script')) {

        if (index.hasAttribute('data-navigate-once')) {

            const hash = simpleHash(ignoreAttributes(index.outerHTML, attributesExemptFromScriptTagHashing))

            if (oldBodyScriptTagHashes2.includes(hash)) { continue }

        }

        index.replaceWith(cloneScriptTag(index))

    }

}

function mergeNewHead(newHead) {

    const children = Array.from(document.head.children)
    const headChildrenHtmlLookup = children.map(index => index.outerHTML)
    const garbageCollector = document.createDocumentFragment()
    const touchedHeadElements = []
    const remoteScriptsPromises = []

    for (const child of Array.from(newHead.children)) {

        if (isAsset(child)) {

            if (!headChildrenHtmlLookup.includes(child.outerHTML)) {

                if (isTracked(child)) {

                    if (ifTheQueryStringChangedSinceLastRequest(child, children)) {
                        setTimeout(() => window.location.reload())
                    }

                }

                if (isScript(child)) {

                    try {
                        remoteScriptsPromises.push(injectScriptTagAndWaitForItToFullyLoad(cloneScriptTag(child)))
                    } catch (error2) {
                    }

                } else {
                    document.head.appendChild(child)
                }

            } else {
                garbageCollector.appendChild(child)
            }

            touchedHeadElements.push(child)

        }

    }

    for (const child of Array.from(document.head.children)) {
        if (!isAsset(child)) { child.remove() }
    }

    for (const child of Array.from(newHead.children)) {
        document.head.appendChild(child)
    }

    return Promise.all(remoteScriptsPromises)

}

async function injectScriptTagAndWaitForItToFullyLoad(script) {

    return new Promise((resolve, reject) => {

        if (script.src) {

            script.onload = () => resolve()
            script.onerror = () => reject()

        } else {
            resolve()
        }

        document.head.appendChild(script)

    })

}

function cloneScriptTag(element) {

    const script = document.createElement('script')

    script.textContent = element.textContent
    script.async = element.async

    for (const attribute of element.attributes) {
        script.setAttribute(attribute.name, attribute.value)
    }

    return script

}

function isTracked(element) {
    return element.hasAttribute('data-navigate-track')
}

function ifTheQueryStringChangedSinceLastRequest(element, currentHeadChildren) {

    const [ uri, queryString ] = extractUriAndQueryString(element)

    return currentHeadChildren.some(child => {

        if (!isTracked(child)) { return false }

        const [ currentUri, currentQueryString ] = extractUriAndQueryString(child)

        if (currentUri === uri && queryString !== currentQueryString) { return true }

    })

}

function extractUriAndQueryString(element) {

    const url = isScript(element) ? element.src : element.href

    return url.split('?')

}

function isAsset(element) {
    return element.tagName.toLowerCase() === 'link' && element.getAttribute('rel').toLowerCase() === 'stylesheet' || element.tagName.toLowerCase() === 'style' || element.tagName.toLowerCase() === 'script'
}

function isScript(element) {
    return element.tagName.toLowerCase() === 'script'
}

function simpleHash(string_) {

    return string_.split('').reduce((a, b) => {

        a = (a << 5) - a + b.charCodeAt(0)

        return a & a

    }, 0)

}

function ignoreAttributes(subject, attributesToRemove) {

    let result = subject

    for (const attribute of attributesToRemove) {

        const regex = new RegExp(`${ attribute }="[^"]*"|${ attribute }='[^']*'`, 'g')

        result = result.replace(regex, '')

    }

    result = result.replaceAll(' ', '')

    return result.trim()

}

// Js/plugins/navigate/index.js
const enablePersist = true

let showProgressBar = true

const restoreScroll = true
const autofocus = false

function navigate_default(Alpine19) {

    Alpine19.navigate = url => {

        const destination = createUrlObjectFromString(url)

        const prevented = fireEventForOtherLibariesToHookInto('alpine:navigate', {
            url: destination,
            history: false,
            cached: false,
        })

        if (prevented) { return }

        navigateTo(destination)

    }

    Alpine19.navigate.disableProgressBar = () => {
        showProgressBar = false
    }

    Alpine19.addInitSelector(() => `[${ Alpine19.prefixed('navigate') }]`)

    Alpine19.directive('navigate', (element, { modifiers }) => {

        const shouldPrefetchOnHover = modifiers.includes('hover')

        shouldPrefetchOnHover && whenThisLinkIsHoveredFor(element, 60, () => {

            const destination = extractDestinationFromLink(element)

            if (!destination) { return }

            prefetchHtml(destination, (html, finalDestination) => {
                storeThePrefetchedHtmlForWhenALinkIsClicked(html, destination, finalDestination)
            })

        })

        whenThisLinkIsPressed(element, whenItIsReleased => {

            const destination = extractDestinationFromLink(element)

            if (!destination) { return }

            prefetchHtml(destination, (html, finalDestination) => {
                storeThePrefetchedHtmlForWhenALinkIsClicked(html, destination, finalDestination)
            })

            whenItIsReleased(() => {

                const prevented = fireEventForOtherLibariesToHookInto('alpine:navigate', {
                    url: destination,
                    history: false,
                    cached: false,
                })

                if (prevented) { return }

                navigateTo(destination)

            })

        })

    })

    function navigateTo(destination, shouldPushToHistoryState = true) {

        showProgressBar && showAndStartProgressBar()

        fetchHtmlOrUsePrefetchedHtml(destination, (html, finalDestination) => {

            fireEventForOtherLibariesToHookInto('alpine:navigating')
            restoreScroll && storeScrollInformationInHtmlBeforeNavigatingAway()
            showProgressBar && finishAndHideProgressBar()
            cleanupAlpineElementsOnThePageThatArentInsideAPersistedElement()
            updateCurrentPageHtmlInHistoryStateForLaterBackButtonClicks()

            preventAlpineFromPickingUpDomChanges(Alpine19, andAfterAllThis => {

                enablePersist && storePersistantElementsForLater(persistedElement => {
                    packUpPersistedTeleports(persistedElement)
                })

                if (shouldPushToHistoryState) {
                    updateUrlAndStoreLatestHtmlForFutureBackButtons(html, finalDestination)
                } else {
                    replaceUrl(finalDestination, html)
                }

                swapCurrentPageWithNewHtml(html, afterNewScriptsAreDoneLoading => {

                    removeAnyLeftOverStaleTeleportTargets(document.body)

                    enablePersist && putPersistantElementsBack((persistedElement, newStub) => {
                        unPackPersistedTeleports(persistedElement)
                    })

                    restoreScrollPositionOrScrollToTop()

                    afterNewScriptsAreDoneLoading(() => {

                        andAfterAllThis(() => {

                            setTimeout(() => {
                                autofocus && autofocusElementsWithTheAutofocusAttribute()
                            })

                            nowInitializeAlpineOnTheNewPage(Alpine19)
                            fireEventForOtherLibariesToHookInto('alpine:navigated')

                        })

                    })

                })

            })

        })

    }

    whenTheBackOrForwardButtonIsClicked(ifThePageBeingVisitedHasntBeenCached => {

        ifThePageBeingVisitedHasntBeenCached(url => {

            const destination = createUrlObjectFromString(url)

            const prevented = fireEventForOtherLibariesToHookInto('alpine:navigate', {
                url: destination,
                history: true,
                cached: false,
            })

            if (prevented) { return }

            const shouldPushToHistoryState = false

            navigateTo(destination, shouldPushToHistoryState)

        })

    }, (html, url, currentPageUrl, currentPageKey) => {

        const destination = createUrlObjectFromString(url)

        const prevented = fireEventForOtherLibariesToHookInto('alpine:navigate', {
            url: destination,
            history: true,
            cached: true,
        })

        if (prevented) { return }

        storeScrollInformationInHtmlBeforeNavigatingAway()
        fireEventForOtherLibariesToHookInto('alpine:navigating')
        updateCurrentPageHtmlInSnapshotCacheForLaterBackButtonClicks(currentPageUrl, currentPageKey)

        preventAlpineFromPickingUpDomChanges(Alpine19, andAfterAllThis => {

            enablePersist && storePersistantElementsForLater(persistedElement => {
                packUpPersistedTeleports(persistedElement)
            })

            swapCurrentPageWithNewHtml(html, () => {

                removeAnyLeftOverStaleProgressBars()
                removeAnyLeftOverStaleTeleportTargets(document.body)

                enablePersist && putPersistantElementsBack((persistedElement, newStub) => {
                    unPackPersistedTeleports(persistedElement)
                })

                restoreScrollPositionOrScrollToTop()

                andAfterAllThis(() => {

                    autofocus && autofocusElementsWithTheAutofocusAttribute()
                    nowInitializeAlpineOnTheNewPage(Alpine19)
                    fireEventForOtherLibariesToHookInto('alpine:navigated')

                })

            })

        })

    })

    setTimeout(() => {
        fireEventForOtherLibariesToHookInto('alpine:navigated')
    })

}

function fetchHtmlOrUsePrefetchedHtml(fromDestination, callback) {

    getPretchedHtmlOr(fromDestination, callback, () => {
        fetchHtml(fromDestination, callback)
    })

}

function preventAlpineFromPickingUpDomChanges(Alpine19, callback) {

    Alpine19.stopObservingMutations()

    callback(afterAllThis => {

        Alpine19.startObservingMutations()

        queueMicrotask(() => {
            afterAllThis()
        })

    })

}

function fireEventForOtherLibariesToHookInto(name, detail) {

    const event = new CustomEvent(name, {
        cancelable: true,
        bubbles: true,
        detail,
    })

    document.dispatchEvent(event)

    return event.defaultPrevented

}

function nowInitializeAlpineOnTheNewPage(Alpine19) {

    Alpine19.initTree(document.body, void 0, (element, skip) => {
        if (element._x_wasPersisted) { skip() }
    })

}

function autofocusElementsWithTheAutofocusAttribute() {
    document.querySelector('[autofocus]') && document.querySelector('[autofocus]').focus()
}

function cleanupAlpineElementsOnThePageThatArentInsideAPersistedElement() {

    const walker = function(root, callback) {

        Alpine.walk(root, (element, skip) => {

            if (isPersistedElement(element)) { skip() }

            if (isTeleportTarget(element)) { skip() } else { callback(element, skip) }

        })

    }

    Alpine.destroyTree(document.body, walker)

}

// Js/plugins/history/index.js
function history2(Alpine19) {

    Alpine19.magic('queryString', (element, { interceptor }) => {

        let alias
        let alwaysShow = false
        let usePush = false

        return interceptor((initialSeedValue, getter, setter, path, key) => {

            const queryKey = alias || path
            const { initial, replace: replace2, push: push2, pop } = track(queryKey, initialSeedValue, alwaysShow)

            setter(initial)

            if (!usePush) {
                Alpine19.effect(() => replace2(getter()))
            } else {

                Alpine19.effect(() => push2(getter()))

                pop(async newValue => {

                    setter(newValue)

                    const tillTheEndOfTheMicrotaskQueue = () => Promise.resolve()

                    await tillTheEndOfTheMicrotaskQueue()

                })

            }

            return initial

        }, function_ => {

            function_.alwaysShow = () => {

                alwaysShow = true

                return function_

            }

            function_.usePush = () => {

                usePush = true

                return function_

            }

            function_.as = key => {

                alias = key

                return function_

            }

        })

    })

    Alpine19.history = { track }

}

function track(name, initialSeedValue, alwaysShow = false, except = null) {

    const { has, get, set, remove } = queryStringUtils()

    let url = new URL(window.location.href)

    const isInitiallyPresentInUrl = has(url, name)
    const initialValue = isInitiallyPresentInUrl ? get(url, name) : initialSeedValue
    const initialValueMemo = JSON.stringify(initialValue)
    const exceptValueMemo = [ false, null, void 0 ].includes(except) ? initialSeedValue : JSON.stringify(except)
    const hasReturnedToInitialValue = newValue => JSON.stringify(newValue) === initialValueMemo
    const hasReturnedToExceptValue = newValue => JSON.stringify(newValue) === exceptValueMemo

    if (alwaysShow) { url = set(url, name, initialValue) }

    replace(url, name, { value: initialValue })

    let lock = false

    const update = (strategy, newValue) => {

        if (lock) { return }

        let url2 = new URL(window.location.href)

        if (!alwaysShow && !isInitiallyPresentInUrl && hasReturnedToInitialValue(newValue)) {
            url2 = remove(url2, name)
        } else if (newValue === void 0) {
            url2 = remove(url2, name)
        } else if (!alwaysShow && hasReturnedToExceptValue(newValue)) {
            url2 = remove(url2, name)
        } else {
            url2 = set(url2, name, newValue)
        }

        strategy(url2, name, { value: newValue })

    }

    return {
        initial: initialValue,
        replace(newValue) {
            update(replace, newValue)
        },
        push(newValue) {
            update(push, newValue)
        },
        pop(receiver) {

            const handler = e => {

                if (!e.state || !e.state.alpine) { return }

                for (const [ indexName, { value: newValue } ] of Object.entries(e.state.alpine)) {

                    if (indexName !== name) { continue }

                    lock = true

                    const result = receiver(newValue)

                    if (result instanceof Promise) {
                        result.finally(() => lock = false)
                    } else {
                        lock = false
                    }

                }

            }

            window.addEventListener('popstate', handler)

            return () => window.removeEventListener('popstate', handler)

        },
    }

}

function replace(url, key, object) {

    const state = window.history.state || {}

    if (!state.alpine) { state.alpine = {} }

    state.alpine[ key ] = unwrap(object)
    window.history.replaceState(state, '', url.toString())

}

function push(url, key, object) {

    let state = window.history.state || {}

    if (!state.alpine) { state.alpine = {} }

    state = { alpine: { ...state.alpine, ...{ [ key ]: unwrap(object) } } }
    window.history.pushState(state, '', url.toString())

}

function unwrap(object) {

    if (object === void 0) { return void 0 }

    return JSON.parse(JSON.stringify(object))

}

function queryStringUtils() {

    return {
        has(url, key) {

            const search = url.search

            if (!search) { return false }

            const data = fromQueryString(search)

            return Object.keys(data).includes(key)

        },
        get(url, key) {

            const search = url.search

            if (!search) { return false }

            const data = fromQueryString(search)

            return data[ key ]

        },
        set(url, key, value) {

            const data = fromQueryString(url.search)

            data[ key ] = stripNulls(unwrap(value))
            url.search = toQueryString(data)

            return url

        },
        remove(url, key) {

            const data = fromQueryString(url.search)

            delete data[ key ]
            url.search = toQueryString(data)

            return url

        },
    }

}

function stripNulls(value) {

    if (!isObjecty(value)) { return value }

    for (const key in value) {
        if (value[ key ] === null) { delete value[ key ] } else { value[ key ] = stripNulls(value[ key ]) }
    }

    return value

}

function toQueryString(data) {

    const isObjecty2 = subject => typeof subject === 'object' && subject !== null

    const buildQueryStringEntries = (data2, entries2 = {}, baseKey = '') => {

        for (const [ indexKey, indexValue ] of Object.entries(data2)) {

            const key = baseKey === '' ? indexKey : `${ baseKey }[${ indexKey }]`

            if (indexValue === null) {
                entries2[ key ] = ''
            } else if (!isObjecty2(indexValue)) {
                entries2[ key ] = encodeURIComponent(indexValue).replaceAll('%20', '+').replaceAll('%2C', ',')
            } else {
                entries2 = { ...entries2, ...buildQueryStringEntries(indexValue, entries2, key) }
            }

        }

        return entries2

    }

    const entries = buildQueryStringEntries(data)

    return Object.entries(entries).map(([ key, value ]) => `${ key }=${ value }`).join('&')

}

function fromQueryString(search) {

    search = search.replace('?', '')

    if (search === '') { return {} }

    const insertDotNotatedValueIntoData = (key, value, data2) => {

        const [ first2, second, ...rest ] = key.split('.')

        if (!second) { return data2[ key ] = value }

        if (data2[ first2 ] === void 0) {
            data2[ first2 ] = isNaN(second) ? {} : []
        }

        insertDotNotatedValueIntoData([ second, ...rest ].join('.'), value, data2[ first2 ])

    }

    const entries = search.split('&').map(index => index.split('='))
    const data = /* @__PURE__ */ Object.create(null)

    for (let [ key, value ] of entries) {

        if (typeof value === 'undefined') { continue }

        value = decodeURIComponent(value.replaceAll('+', '%20'))

        if (!key.includes('[')) {
            data[ key ] = value
        } else {

            const dotNotatedKey = key.replaceAll('[', '.').replaceAll(']', '')

            insertDotNotatedValueIntoData(dotNotatedKey, value, data)

        }

    }

    return data

}

// Js/lifecycle.js
const import_morph = __toESM(require_module_cjs8())
const import_mask = __toESM(require_module_cjs9())
const import_alpinejs5 = __toESM(require_module_cjs())

function start() {

    setTimeout(() => ensureLivewireScriptIsntMisplaced())
    dispatch(document, 'livewire:init')
    dispatch(document, 'livewire:initializing')
    import_alpinejs5.default.plugin(import_morph.default)
    import_alpinejs5.default.plugin(history2)
    import_alpinejs5.default.plugin(import_intersect.default)
    import_alpinejs5.default.plugin(import_resize.default)
    import_alpinejs5.default.plugin(import_collapse.default)
    import_alpinejs5.default.plugin(import_anchor.default)
    import_alpinejs5.default.plugin(import_focus.default)
    import_alpinejs5.default.plugin(import_persist2.default)
    import_alpinejs5.default.plugin(navigate_default)
    import_alpinejs5.default.plugin(import_mask.default)
    import_alpinejs5.default.addRootSelector(() => '[wire\\:id]')

    import_alpinejs5.default.onAttributesAdded((element, attributes) => {

        if (!Array.from(attributes).some(attribute => matchesForLivewireDirective(attribute.name))) { return }

        const component = closestComponent(element, false)

        if (!component) { return }

        for (const attribute of attributes) {

            if (!matchesForLivewireDirective(attribute.name)) { continue }

            const directive2 = extractDirective(element, attribute.name)

            trigger('directive.init', { el: element, component, directive: directive2, cleanup: callback => {
                import_alpinejs5.default.onAttributeRemoved(element, directive2.raw, callback)
            } })

        }

    })

    import_alpinejs5.default.interceptInit(import_alpinejs5.default.skipDuringClone(element => {

        if (!Array.from(element.attributes).some(attribute => matchesForLivewireDirective(attribute.name))) { return }

        if (element.hasAttribute('wire:id')) {

            const component2 = initComponent(element)

            import_alpinejs5.default.onAttributeRemoved(element, 'wire:id', () => {
                destroyComponent(component2.id)
            })

        }

        const component = closestComponent(element, false)

        if (component) {

            trigger('element.init', { el: element, component })

            const directives = Array.from(element.getAttributeNames()).filter(name => matchesForLivewireDirective(name)).map(name => extractDirective(element, name))

            for (const directive2 of directives) {

                trigger('directive.init', { el: element, component, directive: directive2, cleanup: callback => {
                    import_alpinejs5.default.onAttributeRemoved(element, directive2.raw, callback)
                } })

            }

        }

    }))

    import_alpinejs5.default.start()
    setTimeout(() => window.Livewire.initialRenderIsFinished = true)
    dispatch(document, 'livewire:initialized')

}

function ensureLivewireScriptIsntMisplaced() {

    const element = document.querySelector('script[data-update-uri][data-csrf]')

    if (!element) { return }

    const livewireElement = element.closest('[wire\\:id]')

    if (livewireElement) {
        console.warn('Livewire: missing closing tags found. Ensure your template elements contain matching closing tags.', livewireElement)
    }

}

// Js/index.js
const import_alpinejs17 = __toESM(require_module_cjs())

// Js/features/supportListeners.js
on('effect', ({ component, effects }) => {
    registerListeners(component, effects.listeners || [])
})

function registerListeners(component, listeners2) {

    for (const name of listeners2) {

        const handler = e => {

            if (e.__livewire) { e.__livewire.receivedBy.push(component) }

            component.$wire.call('__dispatch', name, e.detail || {})

        }

        window.addEventListener(name, handler)
        component.addCleanup(() => window.removeEventListener(name, handler))

        component.el.addEventListener(name, e => {

            if (!e.__livewire) { return }

            if (e.bubbles) { return }

            if (e.__livewire) { e.__livewire.receivedBy.push(component.id) }

            component.$wire.call('__dispatch', name, e.detail || {})

        })

    }

}

// Js/features/supportScriptsAndAssets.js
const import_alpinejs6 = __toESM(require_module_cjs())
const executedScripts = /* @__PURE__ */ new WeakMap()
const executedAssets = /* @__PURE__ */ new Set()

on('payload.intercept', async({ assets }) => {

    if (!assets) { return }

    for (const [ key, asset ] of Object.entries(assets)) {

        await onlyIfAssetsHaventBeenLoadedAlreadyOnThisPage(key, async() => {
            await addAssetsToHeadTagOfPage(asset)
        })

    }

})

on('component.init', ({ component }) => {

    const assets = component.snapshot.memo.assets

    if (assets) {

        for (const key of assets) {

            if (executedAssets.has(key)) { continue }

            executedAssets.add(key)

        }

    }

})

on('effect', ({ component, effects }) => {

    const scripts = effects.scripts

    if (scripts) {

        for (const [ key, content ] of Object.entries(scripts)) {

            onlyIfScriptHasntBeenRunAlreadyForThisComponent(component, key, () => {

                const scriptContent = extractScriptTagContent(content)

                import_alpinejs6.default.dontAutoEvaluateFunctions(() => {
                    import_alpinejs6.default.evaluate(component.el, scriptContent, { $wire: component.$wire })
                })

            })

        }

    }

})

function onlyIfScriptHasntBeenRunAlreadyForThisComponent(component, key, callback) {

    if (executedScripts.has(component)) {

        const alreadyRunKeys2 = executedScripts.get(component)

        if (alreadyRunKeys2.includes(key)) { return }

    }

    callback()

    if (!executedScripts.has(component)) { executedScripts.set(component, []) }

    const alreadyRunKeys = executedScripts.get(component)

    alreadyRunKeys.push(key)
    executedScripts.set(component, alreadyRunKeys)

}

function extractScriptTagContent(rawHtml) {

    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gm
    const matches = scriptRegex.exec(rawHtml)
    const innards = matches && matches[ 1 ] ? matches[ 1 ].trim() : ''

    return innards

}

async function onlyIfAssetsHaventBeenLoadedAlreadyOnThisPage(key, callback) {

    if (executedAssets.has(key)) { return }

    await callback()
    executedAssets.add(key)

}

async function addAssetsToHeadTagOfPage(rawHtml) {

    const newDocument = new DOMParser().parseFromString(rawHtml, 'text/html')
    const newHead = document.adoptNode(newDocument.head)

    for (const child of newHead.children) {

        try {
            await runAssetSynchronously(child)
        } catch (error2) {
        }

    }

}

async function runAssetSynchronously(child) {

    return new Promise((resolve, reject) => {

        if (isScript2(child)) {

            const script = cloneScriptTag2(child)

            if (script.src) {

                script.onload = () => resolve()
                script.onerror = () => reject()

            } else {
                resolve()
            }

            document.head.appendChild(script)

        } else {

            document.head.appendChild(child)
            resolve()

        }

    })

}

function isScript2(element) {
    return element.tagName.toLowerCase() === 'script'
}

function cloneScriptTag2(element) {

    const script = document.createElement('script')

    script.textContent = element.textContent
    script.async = element.async

    for (const attribute of element.attributes) {
        script.setAttribute(attribute.name, attribute.value)
    }

    return script

}

// Js/features/supportJsEvaluation.js
const import_alpinejs7 = __toESM(require_module_cjs())

on('effect', ({ component, effects }) => {

    const js = effects.js
    const xjs = effects.xjs

    if (js) {

        for (const [ method, body ] of Object.entries(js)) {

            overrideMethod(component, method, () => {
                import_alpinejs7.default.evaluate(component.el, body)
            })

        }

    }

    if (xjs) {

        for (const expression of xjs) {
            import_alpinejs7.default.evaluate(component.el, expression)
        }

    }

})

// Js/morph.js
const import_alpinejs8 = __toESM(require_module_cjs())

function morph2(component, element, html) {

    const wrapperTag = element.parentElement ? element.parentElement.tagName.toLowerCase() : 'div'
    const wrapper = document.createElement(wrapperTag)

    wrapper.innerHTML = html

    let parentComponent

    try {
        parentComponent = closestComponent(element.parentElement)
    } catch (e) {
    }

    parentComponent && (wrapper.__livewire = parentComponent)

    const to = wrapper.firstElementChild

    to.__livewire = component
    trigger('morph', { el: element, toEl: to, component })

    import_alpinejs8.default.morph(element, to, {
        updating: (element2, toElement, childrenOnly, skip) => {

            if (isntElement(element2)) { return }

            trigger('morph.updating', { el: element2, toEl: toElement, component, skip, childrenOnly })

            if (element2.__livewire_replace === true) { element2.innerHTML = toElement.innerHTML }

            if (element2.__livewire_replace_self === true) {

                element2.outerHTML = toElement.outerHTML

                return skip()

            }

            if (element2.__livewire_ignore === true) { return skip() }

            if (element2.__livewire_ignore_self === true) { childrenOnly() }

            if (isComponentRootElement(element2) && element2.getAttribute('wire:id') !== component.id) { return skip() }

            if (isComponentRootElement(element2)) { toElement.__livewire = component }

        },
        updated: element2 => {

            if (isntElement(element2)) { return }

            trigger('morph.updated', { el: element2, component })

        },
        removing: (element2, skip) => {

            if (isntElement(element2)) { return }

            trigger('morph.removing', { el: element2, component, skip })

        },
        removed: element2 => {

            if (isntElement(element2)) { return }

            trigger('morph.removed', { el: element2, component })

        },
        adding: element2 => {
            trigger('morph.adding', { el: element2, component })
        },
        added: element2 => {

            if (isntElement(element2)) { return }

            const closestComponentId = closestComponent(element2).id

            trigger('morph.added', { el: element2 })

        },
        key: element2 => {

            if (isntElement(element2)) { return }

            return element2.hasAttribute('wire:key') ? element2.getAttribute('wire:key') : (element2.hasAttribute('wire:id') ? element2.getAttribute('wire:id') : element2.id)

        },
        lookahead: false,
    })

}

function isntElement(element) {
    return typeof element.hasAttribute !== 'function'
}

function isComponentRootElement(element) {
    return element.hasAttribute('wire:id')
}

// Js/features/supportMorphDom.js
on('effect', ({ component, effects }) => {

    const html = effects.html

    if (!html) { return }

    queueMicrotask(() => {

        queueMicrotask(() => {
            morph2(component, component.el, html)
        })

    })

})

// Js/features/supportDispatches.js
on('effect', ({ component, effects }) => {
    dispatchEvents(component, effects.dispatches || [])
})

function dispatchEvents(component, dispatches) {

    for (const { name, params: parameters = {}, self: self2 = false, to } of dispatches) {
        if (self2) { dispatchSelf(component, name, parameters) } else if (to) { dispatchTo(to, name, parameters) } else { dispatch2(component, name, parameters) }
    }

}

// Js/features/supportDisablingFormsDuringRequest.js
const import_alpinejs9 = __toESM(require_module_cjs())
const cleanups = new Bag()

on('directive.init', ({ el, directive: directive2, cleanup, component }) => setTimeout(() => {

    if (directive2.value !== 'submit') { return }

    el.addEventListener('submit', () => {

        const componentId = directive2.expression.startsWith('$parent') ? component.parent.id : component.id
        const cleanup2 = disableForm(el)

        cleanups.add(componentId, cleanup2)

    })

}))

on('commit', ({ component, respond }) => {

    respond(() => {

        cleanups.each(component.id, index => index())
        cleanups.remove(component.id)

    })

})

function disableForm(formElement) {

    const undos = []

    import_alpinejs9.default.walk(formElement, (element, skip) => {

        if (!formElement.contains(element)) { return }

        if (element.hasAttribute('wire:ignore')) { return skip() }

        if (shouldMarkDisabled(element)) {
            undos.push(markDisabled(element))
        } else if (shouldMarkReadOnly(element)) {
            undos.push(markReadOnly(element))
        }

    })

    return () => {
        while (undos.length > 0) { undos.shift()() }
    }

}

function shouldMarkDisabled(element) {

    const tag = element.tagName.toLowerCase()

    if (tag === 'select') { return true }

    if (tag === 'button' && element.type === 'submit') { return true }

    if (tag === 'input' && (element.type === 'checkbox' || element.type === 'radio')) { return true }

    return false

}

function shouldMarkReadOnly(element) {
    return [ 'input', 'textarea' ].includes(element.tagName.toLowerCase())
}

function markDisabled(element) {

    const undo = element.disabled
        ? () => {
        }
        : () => element.disabled = false

    element.disabled = true

    return undo

}

function markReadOnly(element) {

    const undo = element.readOnly
        ? () => {
        }
        : () => element.readOnly = false

    element.readOnly = true

    return undo

}

// Js/features/supportPropsAndModelables.js
on('commit.pooling', ({ commits }) => {

    for (const commit of commits) {

        const component = commit.component

        getDeepChildrenWithBindings(component, child => {
            child.$wire.$commit()
        })

    }

})

on('commit.pooled', ({ pools }) => {

    const commits = getPooledCommits(pools)

    for (const commit of commits) {

        const component = commit.component

        getDeepChildrenWithBindings(component, child => {
            colocateCommitsByComponent(pools, component, child)
        })

    }

})

function getPooledCommits(pools) {

    const commits = []

    for (const pool of pools) {

        for (const commit of pool.commits) {
            commits.push(commit)
        }

    }

    return commits

}

function colocateCommitsByComponent(pools, component, foreignComponent) {

    const pool = findPoolWithComponent(pools, component)
    const foreignPool = findPoolWithComponent(pools, foreignComponent)
    const foreignCommit = foreignPool.findCommitByComponent(foreignComponent)

    foreignPool.delete(foreignCommit)
    pool.add(foreignCommit)

    for (const pool2 of pools) {
        if (pool2.empty()) { pools.delete(pool2) }
    }

}

function findPoolWithComponent(pools, component) {

    for (const [ index, pool ] of pools.entries()) {
        if (pool.hasCommitFor(component)) { return pool }
    }

}

function getDeepChildrenWithBindings(component, callback) {

    getDeepChildren(component, child => {

        if (hasReactiveProperties(child) || hasWireModelableBindings(child)) {
            callback(child)
        }

    })

}

function hasReactiveProperties(component) {

    const meta = component.snapshot.memo
    const props = meta.props

    return !!props

}

function hasWireModelableBindings(component) {

    const meta = component.snapshot.memo
    const bindings = meta.bindings

    return !!bindings

}

function getDeepChildren(component, callback) {

    for (const child of component.children) {

        callback(child)
        getDeepChildren(child, callback)

    }

}

// Js/features/supportFileDownloads.js
on('commit', ({ succeed }) => {

    succeed(({ effects }) => {

        const download = effects.download

        if (!download) { return }

        const urlObject = window.webkitURL || window.URL
        const url = urlObject.createObjectURL(base64toBlob(download.content, download.contentType))
        const invisibleLink = document.createElement('a')

        invisibleLink.style.display = 'none'
        invisibleLink.href = url
        invisibleLink.download = download.name
        document.body.appendChild(invisibleLink)
        invisibleLink.click()

        setTimeout(function() {
            urlObject.revokeObjectURL(url)
        }, 0)

    })

})

function base64toBlob(b64Data, contentType = '', sliceSize = 512) {

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    if (contentType === null) { contentType = '' }

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)

        for (let index = 0; index < slice.length; index++) {
            byteNumbers[ index ] = slice.charCodeAt(index)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)

    }

    return new Blob(byteArrays, { type: contentType })

}

// Js/features/supportLazyLoading.js
const componentsThatWantToBeBundled = /* @__PURE__ */ new WeakSet()
const componentsThatAreLazy = /* @__PURE__ */ new WeakSet()

on('component.init', ({ component }) => {

    const memo = component.snapshot.memo

    if (memo.lazyLoaded === void 0) { return }

    componentsThatAreLazy.add(component)

    if (memo.lazyIsolated !== void 0 && memo.lazyIsolated === false) {
        componentsThatWantToBeBundled.add(component)
    }

})

on('commit.pooling', ({ commits }) => {

    for (const commit of commits) {

        if (!componentsThatAreLazy.has(commit.component)) { continue }

        if (componentsThatWantToBeBundled.has(commit.component)) {

            commit.isolate = false
            componentsThatWantToBeBundled.delete(commit.component)

        } else {
            commit.isolate = true
        }

        componentsThatAreLazy.delete(commit.component)

    }

})

// Js/features/supportQueryString.js
const import_alpinejs10 = __toESM(require_module_cjs())

on('effect', ({ component, effects, cleanup }) => {

    const queryString = effects.url

    if (!queryString) { return }

    for (const [ key, value ] of Object.entries(queryString)) {

        let { name, as, use, alwaysShow, except } = normalizeQueryStringEntry(key, value)

        if (!as) { as = name }

        const initialValue = [ false, null, void 0 ].includes(except) ? dataGet(component.ephemeral, name) : except
        const { replace: replace2, push: push2, pop } = track(as, initialValue, alwaysShow, except)

        if (use === 'replace') {

            const effectReference = import_alpinejs10.default.effect(() => {
                replace2(dataGet(component.reactive, name))
            })

            cleanup(() => import_alpinejs10.default.release(effectReference))

        } else if (use === 'push') {

            const forgetCommitHandler = on('commit', ({ component: commitComponent, succeed }) => {

                if (component !== commitComponent) { return }

                const beforeValue = dataGet(component.canonical, name)

                succeed(() => {

                    const afterValue = dataGet(component.canonical, name)

                    if (JSON.stringify(beforeValue) === JSON.stringify(afterValue)) { return }

                    push2(afterValue)

                })

            })

            const forgetPopHandler = pop(async newValue => {

                await component.$wire.set(name, newValue)

                for (const element of document.querySelectorAll('input')) {
                    element._x_forceModelUpdate && element._x_forceModelUpdate(element._x_model.get())
                }

            })

            cleanup(() => {

                forgetCommitHandler()
                forgetPopHandler()

            })

        }

    }

})

function normalizeQueryStringEntry(key, value) {

    const defaults = { use: 'replace', alwaysShow: false }

    if (typeof value === 'string') {
        return { ...defaults, name: value, as: value }
    }

    const fullerDefaults = { ...defaults, name: key, as: key }

    return { ...fullerDefaults, ...value }

}

// Js/features/supportLaravelEcho.js
on('request', ({ options }) => {

    if (window.Echo) {
        options.headers[ 'X-Socket-ID' ] = window.Echo.socketId()
    }

})

on('effect', ({ component, effects }) => {

    const listeners2 = effects.listeners || []

    for (const event of listeners2) {

        if (event.startsWith('echo')) {

            if (typeof window.Echo === 'undefined') {

                console.warn('Laravel Echo cannot be found')

                continue

            }

            const event_parts = event.split(/(echo:|echo-)|:|,/)

            if (event_parts[ 1 ] == 'echo:') {
                event_parts.splice(2, 0, 'channel', void 0)
            }

            if (event_parts[ 2 ] == 'notification') {
                event_parts.push(void 0, void 0)
            }

            const [
                s1,
                signature,
                channel_type,
                s2,
                channel,
                s3,
                event_name,
            ] = event_parts

            if ([ 'channel', 'private', 'encryptedPrivate' ].includes(channel_type)) {

                const handler = e => dispatchSelf(component, event, [ e ])

                window.Echo[ channel_type ](channel).listen(event_name, handler)

                component.addCleanup(() => {
                    window.Echo[ channel_type ](channel).stopListening(event_name, handler)
                })

            } else if (channel_type == 'presence') {

                if ([ 'here', 'joining', 'leaving' ].includes(event_name)) {

                    window.Echo.join(channel)[ event_name ](e => {
                        dispatchSelf(component, event, [ e ])
                    })

                } else {

                    const handler = e => dispatchSelf(component, event, [ e ])

                    window.Echo.join(channel).listen(event_name, handler)

                    component.addCleanup(() => {
                        window.Echo.leaveChannel(channel)
                    })

                }

            } else if (channel_type == 'notification') {

                window.Echo.private(channel).notification(notification => {
                    dispatchSelf(component, event, [ notification ])
                })

            } else {
                console.warn('Echo channel type not yet supported')
            }

        }

    }

})

// Js/features/supportIsolating.js
const componentsThatAreIsolated = /* @__PURE__ */ new WeakSet()

on('component.init', ({ component }) => {

    const memo = component.snapshot.memo

    if (memo.isolate !== true) { return }

    componentsThatAreIsolated.add(component)

})

on('commit.pooling', ({ commits }) => {

    for (const commit of commits) {

        if (!componentsThatAreIsolated.has(commit.component)) { continue }

        commit.isolate = true

    }

})

// Js/features/supportNavigate.js
shouldHideProgressBar() && Alpine.navigate.disableProgressBar()
document.addEventListener('alpine:navigate', e => forwardEvent('livewire:navigate', e))
document.addEventListener('alpine:navigating', e => forwardEvent('livewire:navigating', e))
document.addEventListener('alpine:navigated', e => forwardEvent('livewire:navigated', e))

function forwardEvent(name, original) {

    const event = new CustomEvent(name, { cancelable: true, bubbles: true, detail: original.detail })

    document.dispatchEvent(event)

    if (event.defaultPrevented) {
        original.preventDefault()
    }

}

function shouldRedirectUsingNavigateOr(effects, url, or) {

    const forceNavigate = effects.redirectUsingNavigate

    if (forceNavigate) {
        Alpine.navigate(url)
    } else {
        or()
    }

}

function shouldHideProgressBar() {

    if (document.querySelector('[data-no-progress-bar]')) { return true }

    if (window.livewireScriptConfig && window.livewireScriptConfig.progressBar === false) { return true }

    return false

}

// Js/features/supportRedirects.js
on('effect', ({ effects }) => {

    if (!effects.redirect) { return }

    const url = effects.redirect

    shouldRedirectUsingNavigateOr(effects, url, () => {
        window.location.href = url
    })

})

// Js/directives/wire-transition.js
const import_alpinejs11 = __toESM(require_module_cjs())

on('morph.added', ({ el }) => {
    el.__addedByMorph = true
})

directive('transition', ({ el, directive: directive2, component, cleanup }) => {

    const visibility = import_alpinejs11.default.reactive({ state: !el.__addedByMorph })

    import_alpinejs11.default.bind(el, {
        [ directive2.rawName.replace('wire:', 'x-') ]: '',
        'x-show'() {
            return visibility.state
        },
    })

    el.__addedByMorph && setTimeout(() => visibility.state = true)

    const cleanups2 = []

    cleanups2.push(on('morph.removing', ({ el: element2, skip }) => {

        skip()

        element2.addEventListener('transitionend', () => {
            element2.remove()
        })

        visibility.state = false

        cleanups2.push(on('morph', ({ component: morphComponent }) => {

            if (morphComponent !== component) { return }

            element2.remove()

            for (const index of cleanups2) { index() }

        }))

    }))

    cleanup(() => cleanups2.forEach(index => index()))

})

// Js/debounce.js
const callbacksByComponent = new WeakBag()

function callAndClearComponentDebounces(component, callback) {

    callbacksByComponent.each(component, callbackRegister => {

        callbackRegister.callback()

        callbackRegister.callback = () => {
        }

    })

    callback()

}

// Js/directives/wire-wildcard.js
const import_alpinejs12 = __toESM(require_module_cjs())

on('directive.init', ({ el, directive: directive2, cleanup, component }) => {

    if ([ 'snapshot', 'effects', 'model', 'init', 'loading', 'poll', 'ignore', 'id', 'data', 'key', 'target', 'dirty' ].includes(directive2.value)) { return }

    if (customDirectiveHasBeenRegistered(directive2.value)) { return }

    let attribute = directive2.rawName.replace('wire:', 'x-on:')

    if (directive2.value === 'submit' && !directive2.modifiers.includes('prevent')) {
        attribute = `${ attribute }.prevent`
    }

    const cleanupBinding = import_alpinejs12.default.bind(el, {
        [ attribute ](e) {

            const execute = () => {

                callAndClearComponentDebounces(component, () => {
                    import_alpinejs12.default.evaluate(el, `$wire.${ directive2.expression }`, { scope: { $event: e } })
                })

            }

            if (el.__livewire_confirm) {

                el.__livewire_confirm(() => {
                    execute()
                }, () => {
                    e.stopImmediatePropagation()
                })

            } else {
                execute()
            }

        },
    })

    cleanup(cleanupBinding)

})

// Js/directives/wire-navigate.js
const import_alpinejs13 = __toESM(require_module_cjs())

import_alpinejs13.default.addInitSelector(() => '[wire\\:navigate]')
import_alpinejs13.default.addInitSelector(() => '[wire\\:navigate\\.hover]')

import_alpinejs13.default.interceptInit(import_alpinejs13.default.skipDuringClone(element => {

    if (element.hasAttribute('wire:navigate')) {
        import_alpinejs13.default.bind(element, { [ 'x-navigate' ]: true })
    } else if (element.hasAttribute('wire:navigate.hover')) {
        import_alpinejs13.default.bind(element, { [ 'x-navigate.hover' ]: true })
    }

}))

document.addEventListener('alpine:navigating', () => {

    for (const component of Livewire.all()) {
        component.inscribeSnapshotAndEffectsOnElement()
    }

})

// Js/directives/wire-confirm.js
directive('confirm', ({ el, directive: directive2 }) => {

    let message = directive2.expression

    const shouldPrompt = directive2.modifiers.includes('prompt')

    message = message.replaceAll('\\n', '\n')

    if (message === '') { message = 'Are you sure?' }

    el.__livewire_confirm = (action, instead) => {

        if (shouldPrompt) {

            const [ question, expected ] = message.split('|')

            if (!expected) {
                console.warn('Livewire: Must provide expectation with wire:confirm.prompt')
            } else {

                const input = prompt(question)

                if (input === expected) {
                    action()
                } else {
                    instead()
                }

            }

        } else {
            if (confirm(message)) { action() } else { instead() }
        }

    }

})

// Js/directives/shared.js
function toggleBooleanStateDirective(element, directive2, isTruthy, cachedDisplay = null) {

    isTruthy = directive2.modifiers.includes('remove') ? !isTruthy : isTruthy

    if (directive2.modifiers.includes('class')) {

        const classes = directive2.expression.split(' ').filter(String)

        if (isTruthy) {
            element.classList.add(...classes)
        } else {
            element.classList.remove(...classes)
        }

    } else if (directive2.modifiers.includes('attr')) {

        if (isTruthy) {
            element.setAttribute(directive2.expression, true)
        } else {
            element.removeAttribute(directive2.expression)
        }

    } else {

        const cache = cachedDisplay ?? window.getComputedStyle(element, null).getPropertyValue('display')

        let display = [ 'inline', 'block', 'table', 'flex', 'grid', 'inline-flex' ].filter(index => directive2.modifiers.includes(index))[ 0 ] || 'inline-block'

        display = directive2.modifiers.includes('remove') && !isTruthy ? cache : display
        element.style.display = isTruthy ? display : 'none'

    }

}

// Js/directives/wire-offline.js
const offlineHandlers = /* @__PURE__ */ new Set()
const onlineHandlers = /* @__PURE__ */ new Set()

window.addEventListener('offline', () => offlineHandlers.forEach(index => index()))
window.addEventListener('online', () => onlineHandlers.forEach(index => index()))

directive('offline', ({ el, directive: directive2, cleanup }) => {

    const setOffline = () => toggleBooleanStateDirective(el, directive2, true)
    const setOnline = () => toggleBooleanStateDirective(el, directive2, false)

    offlineHandlers.add(setOffline)
    onlineHandlers.add(setOnline)

    cleanup(() => {

        offlineHandlers.delete(setOffline)
        onlineHandlers.delete(setOnline)

    })

})

// Js/directives/wire-loading.js
directive('loading', ({ el, directive: directive2, component, cleanup }) => {

    const { targets, inverted } = getTargets(el)
    const [ delay, abortDelay ] = applyDelay(directive2)

    const cleanupA = whenTargetsArePartOfRequest(component, targets, inverted, [
        () => delay(() => toggleBooleanStateDirective(el, directive2, true)),
        () => abortDelay(() => toggleBooleanStateDirective(el, directive2, false)),
    ])

    const cleanupB = whenTargetsArePartOfFileUpload(component, targets, [
        () => delay(() => toggleBooleanStateDirective(el, directive2, true)),
        () => abortDelay(() => toggleBooleanStateDirective(el, directive2, false)),
    ])

    cleanup(() => {

        cleanupA()
        cleanupB()

    })

})

function applyDelay(directive2) {

    if (!directive2.modifiers.includes('delay') || directive2.modifiers.includes('none')) { return [ index => index(), index => index() ] }

    let duration = 200

    const delayModifiers = {
        shortest: 50,
        shorter: 100,
        short: 150,
        default: 200,
        long: 300,
        longer: 500,
        longest: 1e3,
    }

    Object.keys(delayModifiers).some(key => {

        if (directive2.modifiers.includes(key)) {

            duration = delayModifiers[ key ]

            return true

        }

    })

    let timeout
    let started = false

    return [
        callback => {

            timeout = setTimeout(() => {

                callback()
                started = true

            }, duration)

        },
        async callback => {

            if (started) {

                await callback()
                started = false

            } else {
                clearTimeout(timeout)
            }

        },
    ]

}

function whenTargetsArePartOfRequest(component, targets, inverted, [ startLoading, endLoading ]) {

    return on('commit', ({ component: indexComponent, commit: payload, respond }) => {

        if (indexComponent !== component) { return }

        if (targets.length > 0 && containsTargets(payload, targets) === inverted) { return }

        startLoading()

        respond(() => {
            endLoading()
        })

    })

}

function whenTargetsArePartOfFileUpload(component, targets, [ startLoading, endLoading ]) {

    const eventMismatch = e => {

        const { id, property } = e.detail

        if (id !== component.id) { return true }

        if (targets.length > 0 && !targets.map(index => index.target).includes(property)) { return true }

        return false

    }

    const cleanupA = listen(window, 'livewire-upload-start', e => {

        if (eventMismatch(e)) { return }

        startLoading()

    })

    const cleanupB = listen(window, 'livewire-upload-finish', e => {

        if (eventMismatch(e)) { return }

        endLoading()

    })

    const cleanupC = listen(window, 'livewire-upload-error', e => {

        if (eventMismatch(e)) { return }

        endLoading()

    })

    return () => {

        cleanupA()
        cleanupB()
        cleanupC()

    }

}

function containsTargets(payload, targets) {

    const { updates, calls } = payload

    return targets.some(({ target, params }) => {

        if (params) {
            return calls.some(({ method, params: methodParameters }) => target === method && params === quickHash(JSON.stringify(methodParameters)))
        }

        const hasMatchingUpdate = Object.keys(updates).some(property => {

            if (property.includes('.')) {

                const propertyRoot = property.split('.')[ 0 ]

                if (propertyRoot === target) { return true }

            }

            return property === target

        })

        if (hasMatchingUpdate) { return true }

        if (calls.map(index => index.method).includes(target)) { return true }

    })

}

function getTargets(element) {

    const directives = getDirectives(element)
    const targets = []

    let inverted = false

    if (directives.has('target')) {

        const directive2 = directives.get('target')
        const raw = directive2.expression

        if (directive2.modifiers.includes('except')) { inverted = true }

        if (raw.includes('(') && raw.includes(')')) {
            targets.push({ target: directive2.method, params: quickHash(JSON.stringify(directive2.params)) })
        } else if (raw.includes(',')) {

            for (const target of raw.split(',').map(index => index.trim())) {
                targets.push({ target })
            }

        } else {
            targets.push({ target: raw })
        }

    } else {

        const nonActionOrModelLivewireDirectives = [ 'init', 'dirty', 'offline', 'target', 'loading', 'poll', 'ignore', 'key', 'id' ]

        for (const target of directives.all().filter(index => !nonActionOrModelLivewireDirectives.includes(index.value)).map(index => index.expression.split('(')[ 0 ])) { targets.push({ target }) }

    }

    return { targets, inverted }

}

function quickHash(subject) {
    return btoa(encodeURIComponent(subject))
}

// Js/directives/wire-stream.js
directive('stream', ({ el, directive: directive2, cleanup }) => {

    const { expression, modifiers } = directive2

    const off = on('stream', ({ name, content, replace: replace2 }) => {

        if (name !== expression) { return }

        if (modifiers.includes('replace') || replace2) {
            el.innerHTML = content
        } else {
            el.innerHTML = el.innerHTML + content
        }

    })

    cleanup(off)

})

on('request', ({ respond }) => {

    respond(mutableObject => {

        const response = mutableObject.response

        if (!response.headers.has('X-Livewire-Stream')) { return }

        mutableObject.response = {
            ok: true,
            redirected: false,
            status: 200,
            async text() {

                const finalResponse = await interceptStreamAndReturnFinalResponse(response, streamed => {
                    trigger('stream', streamed)
                })

                if (contentIsFromDump(finalResponse)) {
                    this.ok = false
                }

                return finalResponse

            },
        }

    })

})

async function interceptStreamAndReturnFinalResponse(response, callback) {

    const reader = response.body.getReader()

    let remainingResponse = ''

    while (true) {

        const { done, value: chunk } = await reader.read()
        const decoder = new TextDecoder()
        const output = decoder.decode(chunk)
        const [ streams, remaining ] = extractStreamObjects(remainingResponse + output)

        for (const stream of streams) {
            callback(stream)
        }

        remainingResponse = remaining

        if (done) { return remainingResponse }

    }

}

function extractStreamObjects(raw) {

    const regex = /({"stream":true.*?"endStream":true})/g
    const matches = raw.match(regex)
    const parsed = []

    if (matches) {

        for (let index = 0; index < matches.length; index++) {
            parsed.push(JSON.parse(matches[ index ]).body)
        }

    }

    const remaining = raw.replace(regex, '')

    return [ parsed, remaining ]

}

// Js/directives/wire-replace.js
directive('replace', ({ el, directive: directive2 }) => {

    if (directive2.modifiers.includes('self')) {
        el.__livewire_replace_self = true
    } else {
        el.__livewire_replace = true
    }

})

// Js/directives/wire-ignore.js
directive('ignore', ({ el, directive: directive2 }) => {

    if (directive2.modifiers.includes('self')) {
        el.__livewire_ignore_self = true
    } else {
        el.__livewire_ignore = true
    }

})

// Js/directives/wire-dirty.js
const refreshDirtyStatesByComponent = new WeakBag()

on('commit', ({ component, respond }) => {

    respond(() => {

        setTimeout(() => {
            refreshDirtyStatesByComponent.each(component, index => index(false))
        })

    })

})

directive('dirty', ({ el, directive: directive2, component }) => {

    const targets = dirtyTargets(el)
    const dirty = Alpine.reactive({ state: false })

    let oldIsDirty = false

    const initialDisplay = el.style.display

    const refreshDirtyState = isDirty => {

        toggleBooleanStateDirective(el, directive2, isDirty, initialDisplay)
        oldIsDirty = isDirty

    }

    refreshDirtyStatesByComponent.add(component, refreshDirtyState)

    Alpine.effect(() => {

        let isDirty = false

        if (targets.length === 0) {
            isDirty = JSON.stringify(component.canonical) !== JSON.stringify(component.reactive)
        } else {

            for (let index = 0; index < targets.length; index++) {

                if (isDirty) { break }

                const target = targets[ index ]

                isDirty = JSON.stringify(dataGet(component.canonical, target)) !== JSON.stringify(dataGet(component.reactive, target))

            }

        }

        if (oldIsDirty !== isDirty) {
            refreshDirtyState(isDirty)
        }

        oldIsDirty = isDirty

    })

})

function dirtyTargets(element) {

    const directives = getDirectives(element)

    let targets = []

    if (directives.has('model')) {
        targets.push(directives.get('model').expression)
    }

    if (directives.has('target')) {
        targets = targets.concat(directives.get('target').expression.split(',').map(s => s.trim()))
    }

    return targets

}

// Js/directives/wire-model.js
const import_alpinejs14 = __toESM(require_module_cjs())

directive('model', ({ el, directive: directive2, component, cleanup }) => {

    const { expression, modifiers } = directive2

    if (!expression) {
        return console.warn('Livewire: [wire:model] is missing a value.', el)
    }

    if (componentIsMissingProperty(component, expression)) {
        return console.warn(`Livewire: [wire:model="${ expression }"] property does not exist on component: [${ component.name }]`, el)
    }

    if (el.type && el.type.toLowerCase() === 'file') {
        return handleFileUpload(el, expression, component, cleanup)
    }

    const isLive = modifiers.includes('live')
    const isLazy = modifiers.includes('lazy') || modifiers.includes('change')
    const onBlur = modifiers.includes('blur')
    const isDebounced = modifiers.includes('debounce')
    const update = expression.startsWith('$parent') ? () => component.$wire.$parent.$commit() : () => component.$wire.$commit()
    const debouncedUpdate = isTextInput(el) && !isDebounced && isLive ? debounce(update, 150) : update

    import_alpinejs14.default.bind(el, {
        [ '@change' ]() {
            isLazy && update()
        },
        [ '@blur' ]() {
            onBlur && update()
        },
        [ `x-model${ getModifierTail(modifiers) }` ]() {

            return {
                get() {
                    return dataGet(component.$wire, expression)
                },
                set(value) {

                    dataSet(component.$wire, expression, value)
                    isLive && !isLazy && !onBlur && debouncedUpdate()

                },
            }

        },
    })

})

function getModifierTail(modifiers) {

    modifiers = modifiers.filter(index => ![
        'lazy',
        'defer',
    ].includes(index))

    if (modifiers.length === 0) { return '' }

    return `.${ modifiers.join('.') }`

}

function isTextInput(element) {
    return [ 'INPUT', 'TEXTAREA' ].includes(element.tagName.toUpperCase()) && ![ 'checkbox', 'radio' ].includes(element.type)
}

function componentIsMissingProperty(component, property) {

    if (property.startsWith('$parent')) {

        const parent = closestComponent(component.el.parentElement, false)

        if (!parent) { return true }

        return componentIsMissingProperty(parent, property.split('$parent.')[ 1 ])

    }

    const baseProperty = property.split('.')[ 0 ]

    return !Object.keys(component.canonical).includes(baseProperty)

}

function debounce(function_, wait) {

    let timeout

    return function() {

        const context = this, arguments_ = arguments

        const later = function() {

            timeout = null
            function_.apply(context, arguments_)

        }

        clearTimeout(timeout)
        timeout = setTimeout(later, wait)

    }

}

// Js/directives/wire-init.js
const import_alpinejs15 = __toESM(require_module_cjs())

directive('init', ({ el, directive: directive2 }) => {

    const fullMethod = directive2.expression ?? '$refresh'

    import_alpinejs15.default.evaluate(el, `$wire.${ fullMethod }`)

})

// Js/directives/wire-poll.js
const import_alpinejs16 = __toESM(require_module_cjs())

directive('poll', ({ el, directive: directive2 }) => {

    const interval = extractDurationFrom(directive2.modifiers, 2e3)

    const { start: start2, pauseWhile, throttleWhile, stopWhen } = poll(() => {
        triggerComponentRequest(el, directive2)
    }, interval)

    start2()
    throttleWhile(() => theTabIsInTheBackground() && theDirectiveIsMissingKeepAlive(directive2))
    pauseWhile(() => theDirectiveHasVisible(directive2) && theElementIsNotInTheViewport(el))
    pauseWhile(() => theDirectiveIsOffTheElement(el))
    pauseWhile(() => livewireIsOffline())
    stopWhen(() => theElementIsDisconnected(el))

})

function triggerComponentRequest(element, directive2) {
    import_alpinejs16.default.evaluate(element, directive2.expression ? `$wire.${ directive2.expression }` : '$wire.$commit()')
}

function poll(callback, interval = 2e3) {

    const pauseConditions = []
    const throttleConditions = []
    const stopConditions = []

    return {
        start() {

            const clear = syncronizedInterval(interval, () => {

                if (stopConditions.some(index => index())) { return clear() }

                if (pauseConditions.some(index => index())) { return }

                if (throttleConditions.some(index => index()) && Math.random() < 0.95) { return }

                callback()

            })

        },
        pauseWhile(condition) {
            pauseConditions.push(condition)
        },
        throttleWhile(condition) {
            throttleConditions.push(condition)
        },
        stopWhen(condition) {
            stopConditions.push(condition)
        },
    }

}

const clocks = []

function syncronizedInterval(ms, callback) {

    if (!clocks[ ms ]) {

        const clock = {
            timer: setInterval(() => clock.callbacks.forEach(index => index()), ms),
            callbacks: /* @__PURE__ */ new Set(),
        }

        clocks[ ms ] = clock

    }

    clocks[ ms ].callbacks.add(callback)

    return () => {

        clocks[ ms ].callbacks.delete(callback)

        if (clocks[ ms ].callbacks.size === 0) {

            clearInterval(clocks[ ms ].timer)
            delete clocks[ ms ]

        }

    }

}

let isOffline = false

window.addEventListener('offline', () => isOffline = true)
window.addEventListener('online', () => isOffline = false)

function livewireIsOffline() {
    return isOffline
}

let inBackground = false

document.addEventListener('visibilitychange', () => {
    inBackground = document.hidden
}, false)

function theTabIsInTheBackground() {
    return inBackground
}

function theDirectiveIsOffTheElement(element) {
    return !getDirectives(element).has('poll')
}

function theDirectiveIsMissingKeepAlive(directive2) {
    return !directive2.modifiers.includes('keep-alive')
}

function theDirectiveHasVisible(directive2) {
    return directive2.modifiers.includes('visible')
}

function theElementIsNotInTheViewport(element) {

    const bounding = element.getBoundingClientRect()

    return !(bounding.top < (window.innerHeight || document.documentElement.clientHeight) && bounding.left < (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom > 0 && bounding.right > 0)

}

function theElementIsDisconnected(element) {
    return element.isConnected === false
}

function extractDurationFrom(modifiers, defaultDuration) {

    let durationInMilliSeconds

    const durationInMilliSecondsString = modifiers.find(module_ => module_.match(/([0-9]+)ms/))
    const durationInSecondsString = modifiers.find(module_ => module_.match(/([0-9]+)s/))

    if (durationInMilliSecondsString) {
        durationInMilliSeconds = Number(durationInMilliSecondsString.replace('ms', ''))
    } else if (durationInSecondsString) {
        durationInMilliSeconds = Number(durationInSecondsString.replace('s', '')) * 1e3
    }

    return durationInMilliSeconds || defaultDuration

}

// Js/index.js
const Livewire2 = {
    directive,
    dispatchTo,
    start,
    first,
    find,
    getByName,
    all,
    hook: on,
    trigger,
    triggerAsync,
    dispatch: dispatchGlobal,
    on: on2,
    get navigate() {
        return import_alpinejs17.default.navigate
    },
}

const warnAboutMultipleInstancesOf = entity => console.warn(`Detected multiple instances of ${ entity } running`)

if (window.Livewire) { warnAboutMultipleInstancesOf('Livewire') }

if (window.Alpine) { warnAboutMultipleInstancesOf('Alpine') }

window.Livewire = Livewire2
window.Alpine = import_alpinejs17.default

if (window.livewireScriptConfig === void 0) {

    window.Alpine.__fromLivewire = true

    document.addEventListener('DOMContentLoaded', () => {

        if (window.Alpine.__fromLivewire === void 0) {
            warnAboutMultipleInstancesOf('Alpine')
        }

        Livewire2.start()

    })

}

const export_Alpine = import_alpinejs17.default

export {
    export_Alpine as Alpine,
    Livewire2 as Livewire,
}

/*
 * NProgress, (c) 2013, 2014 Rico Sta. Cruz - http://ricostacruz.com/nprogress
 * @license MIT
 */
/*
 *! Bundled license information:
 *
 *tabbable/dist/index.js:
 *(*!
 * tabbable 5.3.3
 * @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
 *)
 *
 *focus-trap/dist/focus-trap.js:
 *(*!
 * focus-trap 6.9.4
 * @license MIT, https://github.com/focus-trap/focus-trap/blob/master/LICENSE
 *)
 */
// # sourceMappingURL=livewire.esm.js.map
