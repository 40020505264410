import { mask } from 'maska'

const phoneNumber = window.__DEFAULT_PHONE_NUMBER
// eslint-disable-next-line camelcase
const rgbaTags = (window._rgba_tags = (window._rgba_tags || []))

export class Ringba {

    defaultPhone = phoneNumber
    phoneNumber = this.formatPhone(phoneNumber)
    clickListeners = []

    formatPhone(phone) {

        return {
            number: parseInt(phone),
            dashed: mask(phone, '1-###-###-####', { '#': { pattern: /[0-9]/ } }),
            link: `tel:${ phone }`,
            tty: 711,
            onClick: () => {

                for (const listener of this.clickListeners) {
                    listener()
                }

            },
        }

    }

    getPhoneNumber() {

        this.refreshPhone()
        setInterval(() => this.refreshPhone(), 1000)

        return this.phoneNumber

    }

    refreshPhone() {

        const storageKey = `ringbaNumber_${ window.RINGBA_TAG }`

        let ringba

        // We wrap this into a try / catch to avoid triggering security errors when cookies are blocked
        try {

            const key = Object.keys(window.localStorage || {}).find(key => key.startsWith(storageKey))

            if (key) {
                ringba = JSON.parse(window.localStorage[ key ])
            }

        } catch {
            // Nothing to do here
        }

        const phone = ringba?.displayNumber ?? this.defaultPhone

        Object.assign(this.phoneNumber, this.formatPhone(phone))
        this.postMessage('updateRingbaPhoneNumber', JSON.parse(JSON.stringify(this.phoneNumber)))

    }

    onClick(listener) {

        this.clickListeners.push(listener)

        return () => {

            const index = this.clickListeners.indexOf(listener)

            if (index) {
                this.clickListeners.splice(index, 1)
            }

        }

    }

    push(data) {
        rgbaTags.push(data)
    }

    pushFormData(data) {

        if (!data) {
            return
        }

        for (const _reference of Object.entries(data)) {

            const key = _reference[ 0 ]

            let value = _reference[ 1 ]

            if (Array.isArray(value)) {
                value = value.join(',')
            } else if (typeof value === 'object') {

                this.pushFormData(value)

                continue

            }

            this.push({
                [ key ]: value,
            })

        }

    }

    postMessage(event, data) {
        window.parent.postMessage({ event, data }, '*')
    }

    getDefaultTag(metadata) {

        let defaultTag = 'JS3e46ffb5e2574cd4803f54c9db0b0efa'

        for (const key in metadata) {

            const source = this.getParametersByName('source') || this.getParametersByName('src')

            if (source === key) {

                defaultTag = metadata[ key ]

                break

            }

        }

        return defaultTag

    }

    createRingbaTagScript(metadata) {

        const defaultTag = this.getDefaultTag(metadata)

        window.RINGBA_TAG = defaultTag

        const scriptElements = document.getElementsByTagName('script'),
            lastScriptElement = scriptElements[ scriptElements.length - 1 ]

        window._rgba = window._rgba || {
            q: [],
        }

        window._rgba.q.push({
            tag: defaultTag,
            script: lastScriptElement,
            numberClass: null,
            numberDisplayTag: 'bob',
        })

        if (!(window._rgba.loading = !!window._rgba.loading)) {

            const newScriptElement = document.createElement('script')

            newScriptElement.type = 'text/javascript'
            newScriptElement.async = true
            newScriptElement.src = '//js.callcdn.com/js_v3/min/ringba.com.js'

            const firstScriptElement = document.getElementsByTagName('script')[ 0 ]

            if (firstScriptElement.parentNode) {
                firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement)
            } else {

                // Fallback to appending to the body
                document.body.appendChild(newScriptElement)

            }

            window._rgba.loading = true

        }

    }

    getParametersByName(name, url = window.location.href) {

        // The unnecessary escape characters are removed.
        name = name.replace(/[[\]]/g, '\\$&')

        const regex = new RegExp(`[?&]${ name }(=([^&#]*)|&|#|$)`),
            results = regex.exec(url)

        if (!results) {
            return null
        }

        if (!results[ 2 ]) {
            return ''
        }

        return decodeURIComponent(results[ 2 ].replace(/\+/g, ' '))

    }

}
