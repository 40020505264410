export class MediaAlpha {

    loadScript(property, callback) {

        if (property in window) {
            return callback(window[ property ])
        }

        const script = document.createElement('script')

        script.async = true
        script.defer = true
        script.src = '//insurance.mediaalpha.com/js/serve.js'
        script.onload = () => callback(window[ property ])
        document.body.appendChild(script)

    }

}
