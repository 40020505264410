export class Helpers {

    replaceTokens(string, tokens) {

        for (const token in tokens) {
            string = string.replace(new RegExp(`{${ token }}`, 'g'), String(tokens[ token ]))
        }

        return string

    }

    toDecimalAge(date) {

        /*
         * In order to better approximate the decimal age we use an average of 365.25 days to account for leap years.
         * (3 * 365 + 366) / 4 = 365.25 (31557600000 milliseconds)
         */
        const averageYearlyMilliseconds = 31557600000
        const age = (Date.now() - date.getTime()) / averageYearlyMilliseconds

        /*
         * Since we are rounding to two decimal places, we will lose a bit of precision.
         * The resulting age may be off by +/- 2 days. This is fine because the value calculated here is
         * not used to calculate the exact Date of Birth.
         */
        return parseFloat(age.toFixed(2))

    }

    isBlank(value) {

        if (value === undefined || value === null) {
            return true
        }

        if (Number.isNaN(value)) {
            return true
        }

        if (Array.isArray(value)) {
            return value.length === 0
        }

        if (typeof value === 'object') {
            return this.isBlank(Object.keys(value))
        }

        return value === '' || value === 'NaN' || value === '0'

    }

    isFilled(value) {
        return !this.isBlank(value)
    }

}
