export class TrustedForm {

    fieldName = 'xxTrustedFormCertUrl'
    trustedFormResolved = false

    createScript() {

        const existingScripts = document.getElementsByTagName('script')[ 0 ]
        const trustedFormScript = document.createElement('script')

        trustedFormScript.type = 'text/javascript'
        trustedFormScript.async = true
        trustedFormScript.src = this.buildScriptSource()

        if (existingScripts.parentNode) {
            existingScripts.parentNode.insertBefore(trustedFormScript, existingScripts)
        } else {

            // Fallback to appending to the body
            document.body.appendChild(trustedFormScript)

        }

    }

    getTrustedFormUrl() {

        if (!this.trustedFormResolved && window.trustedForm?.cert_id) {

            this.trustedFormResolved = true

            return `https://cert.trustedform.com/${ window.trustedForm.cert_id }`

        }

        return null

    }

    buildScriptSource() {

        const timestamp = new Date().getTime()
        const randomNumber = Math.random()

        return `//api.trustedform.com/trustedform.js?provide_referrer=false&field=${ this.fieldName }&l=${ timestamp }${ randomNumber }`

    }

}
